import { useEffect, useState } from 'react';
import { Button, Container, Group, Loader, Table } from '@mantine/core';
import { useSelector } from 'react-redux';
import {
  EquipmentDeclarationResponse,
  EquipmentReasonsResponse,
  fetchEquipmentDeclarations,
  fetchEquipmentReasons
} from '../../../libraries/equipment';
import PaginationComponent from '../../components/pagination';
import { LoginState } from '../../client-redux/login/reducer';
import { AppState } from '../../client-redux/reducers';
import FilterBar from '../../components/filterbar';
import Header from '../../components/util/header';
import { getLanguageName } from '../../languages/languageUtils';
import { Language } from '../../languages/languageHandler';
import EquipmentReasonDeclaration from './equipment-reason-declaration';
import DeleteEquipmentReasonDeclaration from './remove-equipment-reasons-declaration';
import * as Icon from '@tabler/icons-react';
import './styles.scss';

const ReasonsDeclarations = () => {
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const loginInfo = useSelector<AppState, LoginState>(state => state.login);
  const controller = new AbortController();

  const [reasons, setReasons] = useState<EquipmentReasonsResponse>();
  const [declarations, setDeclarations] = useState<EquipmentDeclarationResponse>();

  const [noReasons, setNoReasons] = useState<boolean>(true);
  const [noDeclarations, setNoDeclarations] = useState<boolean>(true);
  const [searchTrigger, setSearchTrigger] = useState<boolean>(true);
  const [statusOperCode, setStatusOperCode] = useState<number | undefined>();
  const [entid, setEntid] = useState<number>(loginInfo.user?.entid);
  const [loading, setLoading] = useState(false);

  const [limit, setLimit] = useState<number>(10);
  const [, setCurrentPage] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  const [modalData, setModalData] = useState({
    code: 0,
    language: 0,
    text: '',
    type: ''
  });
  const [, setReloadPage] = useState(false);

  useEffect(() => {
    if (searchTrigger) {
      setSearchTrigger(false);
      setLoading(true);
    }
  }, [searchTrigger]);

  useEffect(() => {
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchTrigger) {
      GetLists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTrigger, offset, limit]);

  const GetLists = () => {
    if (!loginInfo.token) {
      return;
    }

    if (loading) {
      controller.abort();
    }

    setLoading(true);

    const fetchReasons = fetchEquipmentReasons(entid, controller);
    const fetchDeclarations = fetchEquipmentDeclarations(entid, controller);

    Promise.all([fetchReasons, fetchDeclarations])
      .then(([reasonsResponse, declarationsResponse]) => {
        if (reasonsResponse.statusOper.code === 0) {
          setReasons(reasonsResponse);
          setNoReasons(false);
          setStatusOperCode(reasonsResponse.statusOper.code);
        } else {
          setNoReasons(true);
        }

        if (declarationsResponse.statusOper.code === 0) {
          setDeclarations(declarationsResponse);
          setNoDeclarations(false);
          setStatusOperCode(declarationsResponse.statusOper.code);
        } else {
          setNoDeclarations(true);
        }
      })
      .catch(error => {
        if (error.name !== 'AbortError') {
          console.error('Error fetching data:', error);
          setNoReasons(true);
          setNoDeclarations(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSearch = () => {
    setSearchTrigger(true);
  };

  const updateEntid = (newEntid: number, callback?: () => void) => {
    setEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  const updateTableEntid = (newEntid: number, callback?: () => void) => {
    updateEntid(newEntid, callback);
  };

  const changePage = (n: number) => {
    const newOffset = (n - 1) * limit;
    setCurrentPage(n);
    setOffset(newOffset);
    setSearchTrigger(true);
  };

  const resultsSelect = (newValue: number) => {
    const newLimit = Number(newValue);
    setLimit(newLimit);
    setCurrentPage(0);
    setOffset(0);
  };

  const buttonClicked = (type: string) => {
    setModalData({
      code: 0,
      language: 0,
      text: '',
      type: type
    });
    setEditMode(false);
    setOpenModal(true);
  };

  const rowClicked = (code: number, language: number, text: string, type: string) => {
    setModalData({
      code: code,
      language: language, 
      text: text,
      type: type
    });
    setEditMode(true);
    setOpenModal(true);
  };

  const handlecloseModal = () => {
    setOpenModal(false);
    setEditMode(false);
    setModalData({ code: 0, language: 0, text: '', type: '' });
  };

  const handleReload = () => {
    setReloadPage(true);
    setTimeout(() => {
     setOpenModal(false);
     GetLists();
    }, 2000);
  };

  const getButtonText = (type: string) => {
    return type === 'reason' ? lang?.EQUIPMENT_ADD_NEW_REASON : lang?.EQUIPMENT_ADD_NEW_DECLARATION;
  };

  const getModalTitle = () => {
    if (editMode) {
      return modalData.type === 'reason' ? lang?.EQUIPMENT_UPDATE_REASON : lang?.EQUIPMENT_UPDATE_DECLARATION;
    }
    return modalData.type === 'reason' ? lang?.EQUIPMENT_ADD_NEW_REASON : lang?.EQUIPMENT_ADD_NEW_DECLARATION;
  };

  return (
    <Container fluid className="reasons-declarations">
      <Header pageTitle={lang?.EQUIPMENT_REASONS_DECLARATIONS} />
      <FilterBar onSearch={onSearch} filterByEntid={updateTableEntid} tableLoaded={GetLists} />

      {openModal && (
        <EquipmentReasonDeclaration
          show={openModal}
          onClose={handlecloseModal}
          title={getModalTitle()}
          codeReasonDeclaration={modalData.code}
          language={modalData.language}
          text={modalData.text}
          isEditing={editMode}
          type={modalData.type === 'reason' ? 'reason' : 'declaration'}
          onReload={handleReload}
        />
      )}

      <Group className="tables">
        {/* Reasons Table */}
        <Group>
          <Button leftSection={<Icon.IconPlus stroke={2} />} onClick={() => buttonClicked('reason')}>
            {getButtonText('reason')}
          </Button>

          <Table.ScrollContainer minWidth={500}>
            <Table striped highlightOnHover verticalSpacing="sm">
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>{lang?.GLOBAL_CODE}</Table.Th>
                  <Table.Th>{lang?.GLOBAL_LANGUAGES}</Table.Th>
                  <Table.Th>{lang?.EQUIPMENT_DESCRIPTION}</Table.Th>
                  <Table.Th></Table.Th>
                </Table.Tr>
              </Table.Thead>

              <Table.Tbody>
                {loading && (!reasons || searchTrigger) ? (
                  <Table.Tr>
                    <Table.Td colSpan={4}>
                      <Loader color="#016273" />
                    </Table.Td>
                  </Table.Tr>
                ) : noReasons ? (
                  <Table.Tr>
                    <Table.Td colSpan={4} style={{ textAlign: 'center' }}>
                      {lang?.GLOBAL_NO_RESULTS}
                    </Table.Td>
                  </Table.Tr>
                ) : (
                  reasons?.reasons?.map((reason, index) => (
                    <Table.Tr key={index} style={{ cursor: 'pointer' }}>
                      <Table.Td onClick={() => rowClicked(reason.code, reason.langid, reason.reason, 'reason')}>
                        {reason.code}
                      </Table.Td>
                      <Table.Td onClick={() => rowClicked(reason.code, reason.langid, reason.reason, 'reason')}>
                        {getLanguageName(reason.langid)}
                      </Table.Td>
                      <Table.Td onClick={() => rowClicked(reason.code, reason.langid, reason.reason, 'reason')}>
                        {reason.reason}
                      </Table.Td>
                      <Table.Td>
                        <DeleteEquipmentReasonDeclaration
                          language={reason.langid}
                          codeReasonDeclaration={reason.code}
                          text={reason.reason}
                          onReload={handleReload}
                          type="reason"
                        />
                      </Table.Td>
                    </Table.Tr>
                  ))
                )}
              </Table.Tbody>
            </Table>
          </Table.ScrollContainer>

          <Group>
            <PaginationComponent
              total={reasons?.reasons ? Math.ceil(reasons.reasons.length / limit) : 0}
              statusOperCode={statusOperCode}
              itemsPerPage={limit}
              onPageChange={changePage}
              onResultsChange={resultsSelect}
            />
          </Group>
        </Group>

        {/* Declarations Table */}
        <Group>
          <Button leftSection={<Icon.IconPlus stroke={2} />} onClick={() => buttonClicked('declaration')}>
            {getButtonText('declaration')}
          </Button>

          <Table.ScrollContainer minWidth={500}>
            <Table striped highlightOnHover verticalSpacing="sm">
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>{lang?.GLOBAL_CODE}</Table.Th>
                  <Table.Th>{lang?.GLOBAL_LANGUAGES}</Table.Th>
                  <Table.Th>{lang?.EQUIPMENT_DESCRIPTION}</Table.Th>
                  <Table.Th></Table.Th>
                </Table.Tr>
              </Table.Thead>

              <Table.Tbody>
                {loading && (!declarations || searchTrigger) ? (
                  <Table.Tr>
                    <Table.Td colSpan={4}>
                      <Loader color="#016273" />
                    </Table.Td>
                  </Table.Tr>
                ) : noDeclarations ? (
                  <Table.Tr>
                    <Table.Td colSpan={4} style={{ textAlign: 'center' }}>
                      {lang?.GLOBAL_NO_RESULTS}
                    </Table.Td>
                  </Table.Tr>
                ) : (
                  declarations?.declarations?.map((declaration, index) => (
                    <Table.Tr key={index} style={{ cursor: 'pointer' }}>
                      <Table.Td
                        onClick={() =>
                          rowClicked(declaration.code, declaration.langid, declaration.declaration, 'declaration')
                        }
                      >
                        {declaration.code}
                      </Table.Td>
                      <Table.Td
                        onClick={() =>
                          rowClicked(declaration.code, declaration.langid, declaration.declaration, 'declaration')
                        }
                      >
                        {getLanguageName(declaration.langid)}
                      </Table.Td>
                      <Table.Td
                        onClick={() =>
                          rowClicked(declaration.code, declaration.langid, declaration.declaration, 'declaration')
                        }
                      >
                        {declaration.declaration}
                      </Table.Td>
                      <Table.Td>
                        <DeleteEquipmentReasonDeclaration
                          language={declaration.langid}
                          codeReasonDeclaration={declaration.code}
                          text={declaration.declaration}
                          onReload={handleReload}
                          type="declaration"
                        />
                      </Table.Td>
                    </Table.Tr>
                  ))
                )}
              </Table.Tbody>
            </Table>
          </Table.ScrollContainer>

          <Group>
            <PaginationComponent
              total={declarations?.declarations ? Math.ceil(declarations.declarations.length / limit) : 0}
              statusOperCode={statusOperCode}
              itemsPerPage={limit}
              onPageChange={changePage}
              onResultsChange={resultsSelect}
            />
          </Group>
        </Group>
      </Group>
    </Container>
  );
};

export default ReasonsDeclarations;
