import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Language } from '../../languages/languageHandler';
import { createUser, PermissionOption, PermissionOptionResponse } from '../../../libraries/users/index';
import ModalComponent from '../../components/modal';
import UserForm from './user-form';
import CustomModal from '../../components/custom-modal';
import { permissionsToUser } from './permissions';
import './styles.scss';

interface ModalProps {
  isNew: boolean;
  hideBtn: () => void;
  lang: Language;
  permissionOptionData: PermissionOptionResponse;
  className?: string
}

const NewUser = ({ isNew, hideBtn, lang, permissionOptionData }: ModalProps) => {
  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedPermissions, setSelectedPermissions] = useState<PermissionOption[]>([]);

  const userCreator = useMutation(createUser, {
    onSuccess: data => {
      const { statusOper } = data;
      if (statusOper.code === 0) {
        setShowSuccessDialog(true);
        setSuccessMessage(lang?.ADD_USER_ALERT_UPDATE_SUCCESS);
      } else {
        setShowErrorDialog(true);
        setErrorMessage(lang?.ADD_USER_ALERT_UPDATE_ERROR + '\n' + statusOper.message);
      }
    },
    onError: error => {
      setShowErrorDialog(true);
      setErrorMessage(lang?.ADD_USER_ALERT_UPDATE_ERROR + error);
    }
  });

  useEffect(() => {
    setSelectedPermissions(
      permissionOptionData.list.map(permission => ({
        ...permission,
        selected: false
      }))
    );
  }, [permissionOptionData.list]);

  const handleSubmit = async (formData: any) => {
    permissionsToUser(selectedPermissions, formData);
    userCreator.mutateAsync(formData);
  };

  const toggleSelection = (permission: PermissionOption) => {
    setSelectedPermissions(prevPermissions =>
      prevPermissions.map(prevPermission =>
        prevPermission.appId === permission.appId && prevPermission.mask === permission.mask
          ? { ...prevPermission, selected: !prevPermission.selected }
          : prevPermission
      )
    );
  };

  return (
    <>
      <ModalComponent
        show={isNew}
        handleClick={hideBtn}
        animation={true}
        title={lang?.USER_NEW}

             content={
          <>
            <UserForm
              lang={lang}
              isNew={true}
              user={{ entid: 0, uid: 0, lang: 0 }}
              submitForm={handleSubmit}
              cancelBtn={hideBtn}
              permissionOptions={selectedPermissions}
              togglePermissions={toggleSelection}
              className='new-user-form'  
              />
          </>
        }
        size={'xl'}
        className='new-user-modal'  
      />
      <CustomModal
        opened={showSuccessDialog}
        onClose={() => setShowSuccessDialog(false)}
        message={successMessage}
        backgroundColor="#3BDF70"
        textColor='black'
      />

      <CustomModal
        opened={showErrorDialog}
        onClose={() => setShowErrorDialog(false)}
        message={errorMessage}
        backgroundColor="#E3353F"
        textColor='white'
      />
    </>
  );
};

export default NewUser;
