import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader, Table, Container, Group, Menu } from '@mantine/core';
import Header from '../../../components/util/header';
import ShowEquipment from '../../../components/show-equipment';
import { useSelector } from 'react-redux';
import { fetchHelpdeskEntitiesList, IHelpdeskEntityResponse } from '../../../../libraries/helpdesk';
import { AppState } from '../../../client-redux/reducers';
import { Language } from '../../../languages/languageHandler';
import PaginationComponent from '../../../components/pagination';
import HelpdeskFilterBar from '../../../components/helpdesk-filterbar';
import CustomModal from '../../../components/custom-modal';
import './styles.scss';

const HelpdeskEntities: React.FC = () => {
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const controller = new AbortController();
  const navigate = useNavigate();
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [activeRow] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [entid, setEntid] = useState<number>(0);
  const [type, setType] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [nif, setNif] = useState<number>(0);
  const [totalRows] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [message, setMessage] = useState<IHelpdeskEntityResponse>();
  const [noResults, setNoResults] = useState<boolean>(true);
  const [searchTrigger, setSearchTrigger] = useState<boolean>(true);
  const [statusOperCode, setStatusOperCode] = useState<number | undefined>();
  const [menuOffset, setMenuOffset] = useState({ x: 0, y: 0 });
  const [newEnt, setNewEnt] = useState<boolean>(false);
  const [, setSelectedEid] = useState<number>(0);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
  
  useEffect(() => {
    setOffset(0)
    getEntitiesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, searchTrigger]);

  const onSearch = () => {
    setCurrentPage(1)
    setOffset(0);
    setSearchTrigger(true); 
  };

  const getEntitiesList = () => {
    if (loading) {
      setLoading(false); 
      controller.abort(); 
    }
    setLoading(true); 

    fetchHelpdeskEntitiesList(offset, limit, totalRows, controller, type, entid, name, nif)
    .then(response => {
      if (response.statusOper.code === 0) {
        setMessage(response);
        setNoResults(false);
        setStatusOperCode(response.statusOper.code);
        setLoading(false);
      } else {
        setNoResults(true);
        setLoading(false);
        setStatusOperCode(response.statusOper.code);

        if (response.statusOper?.message) {
          setErrorMessage(response.statusOper.message);
        } else {
          setErrorMessage('An error occurred, but no specific error message is available.');
        }

        setShowErrorDialog(true);
      }
    })
    .catch(error => {
      if (error.name === 'AbortError') {
      } else {
        // Handle other errors
      }
    })
    .finally(() => setLoading(false));
};

  const updateEntid = (newEntid: number, callback?: () => void) => {
    setEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  const updateTableEntid = (newEntid: number, callback?: () => void) => {
    updateEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  const updateName = (newName: string, callback?: () => void) => {
    setName(newName);
    if (callback) {
      callback();
    }
  };

  const updateTableName = (newName: string, callback?: () => void) => {
    updateName(newName);
    if (callback) {
      callback();
    }
  };

  const updateNif = (newNif: number, callback?: () => void) => {
    setNif(newNif);
    if (callback) {
      callback();
    }
  };

  const updateTableNif = (newNif: number, callback?: () => void) => {
    updateNif(newNif);
    if (callback) {
      callback();
    }
  };

  const updateType = (newType: string, callback?: () => void) => {
    setType(newType);
    if (callback) {
      callback();
    }
  };

  const updateTableType = (newType: string, callback?: () => void) => {
    updateType(newType);
    if (callback) {
      callback();
    }
  };

  useEffect(() => {
    setOffset(0);
    getEntitiesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, limit]);

  const changePage = (n: number) => {
    const newOffset = (n - 1) * limit;
    setCurrentPage(n);
    setOffset(newOffset);
  };

  const resultsSelect = (newValue: number) => {
    const newLimit = Number(newValue);
    setLimit(newLimit);
    setCurrentPage(0);
    setOffset(0);
  };

  const detectTypeName = (type: number | undefined) => {
    switch (type) {
      case 0:
        return lang?.ENTITY_TYPE_0;
      case 1:
        return lang?.ENTITY_TYPE_1;
      case 2:
        return lang?.ENTITY_TYPE_2;
      case 3:
        return lang?.ENTITY_TYPE_3;
      default:
        return lang?.ENTITY_TYPE_NULL;
    }
  };

  const toggleNewEntity = () => {
    setNewEnt(!newEnt);
  };

  return (
    <Container fluid classNames={{ root: 'helpdesk-entities' }}>
      {openDetails && activeRow && lang && (
        <ShowEquipment eid={activeRow} openDetails={openDetails} hideBtn={() => setOpenDetails(false)} lang={lang} />
      )}

      <Header pageTitle={lang?.GLOBAL_ENTITIES} />

      <HelpdeskFilterBar
        onSearch={onSearch}
        tableLoaded={getEntitiesList}
        filterByEntid={updateTableEntid}
        filterByName={updateTableName}
        filterByNIF={updateTableNif}
        filterByType={updateTableType}
        newEntity={toggleNewEntity}
      />

      <Table.ScrollContainer minWidth={500}>
        <Table striped highlightOnHover verticalSpacing="sm">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>{lang?.GLOBAL_GROUP}</Table.Th>
              <Table.Th>{lang?.GLOBAL_ENTITY}</Table.Th>
              <Table.Th>{lang?.GLOBAL_NAME}</Table.Th>
              <Table.Th>{lang?.EQUIPMENT_DESCRIPTION}</Table.Th>
              <Table.Th>{lang?.GLOBAL_TYPE}</Table.Th>
            </Table.Tr>
          </Table.Thead>

          <Table.Tbody>
            {loading && (!message || searchTrigger) ? (
              <Table.Tr>
                <Table.Td colSpan={16}>
                  <Loader color="#016273" />
                </Table.Td>
              </Table.Tr>
            ) : (
              <>
                {noResults ? (
                  <Table.Tr>
                    <Table.Td colSpan={16} style={{ textAlign: 'center' }}>
                      {lang?.GLOBAL_NO_RESULTS}
                    </Table.Td>
                  </Table.Tr>
                ) : (
                  message?.entities?.map((entitiyDetails, index) => (
                    <Menu
                      key={index}
                      radius={15}
                      offset={{ crossAxis: menuOffset.x, mainAxis: menuOffset.y }}
                      width={200}
                      shadow="md"
                      styles={{
                        dropdown: {
                          left: menuOffset.x,
                          top: menuOffset.y
                        }
                      }}
                    >
                      <Menu.Target key={index}>
                        <Table.Tr
                          key={index}
                          style={{ cursor: 'pointer' }}
                          onClick={e => {
                            const { pageX, pageY } = e;
                            setMenuOffset({ x: pageX, y: pageY });
                            setSelectedEid(entitiyDetails.entid);
                          }}
                        >
                          <Table.Td>{entitiyDetails.gid}</Table.Td>
                          <Table.Td>{entitiyDetails.entid}</Table.Td>
                          <Table.Td>{entitiyDetails.name}</Table.Td>
                          <Table.Td>{entitiyDetails.description} </Table.Td>
                          <Table.Td>{detectTypeName(entitiyDetails.type)} </Table.Td>
                        </Table.Tr>
                      </Menu.Target>
                      <Menu.Dropdown onClick={() => {}}>
                        <React.Fragment>
                          <Menu.Item
                            onClick={() => {
                              navigate(`/helpdesk/entities/read/${entitiyDetails.entid}`);
                            }}
                          >
                            {lang?.EDIT_ENTITY_LABEL_READ}
                          </Menu.Item>
                          <Menu.Item
                            onClick={() => {
                              navigate(`/helpdesk/entities/edit/${entitiyDetails.entid}`);
                            }}
                          >
                            {lang?.EDIT_ENTITY_LABEL_EDIT}
                          </Menu.Item>
                          <Menu.Item
                            onClick={() => {
                              navigate(`/helpdesk/equipments?entid=${entitiyDetails.entid}`);
                            }}
                          >
                            {lang?.EQUIPMENT_CONSULT}
                          </Menu.Item>
                        </React.Fragment>
                      </Menu.Dropdown>
                    </Menu>
                  ))
                )}
              </>
            )}
          </Table.Tbody>
        </Table>
      </Table.ScrollContainer>

      <Group>
        <PaginationComponent
          total={message?.totalRows}
          statusOperCode={statusOperCode}
          itemsPerPage={limit}
          onPageChange={changePage}
          onResultsChange={resultsSelect}
          initialPage={currentPage}
        />
      </Group>

       <CustomModal
        opened={showErrorDialog}
        onClose={() => setShowErrorDialog(false)}
        message={errorMessage}
        backgroundColor="#E3353F"
        textColor='white'
      />
    </Container>
  );
};

export default HelpdeskEntities;
