import equipmentLogo from '../../assets/img/equipment.svg';
import MSEquipmentLogo from '../../assets/img/Icons_MS_Equipment_White_MS_Equipment_White.svg';
import MSEntitiesLogo from '../../assets/img/Icons_MS_Entities_White.svg';
import transactionLogo from '../../assets/img/transaction.svg';
import usersLogo from '../../assets/img/people.svg';
import periodLogo from '../../assets/img/period.svg';
import bankLogo from '../../assets/img/bank.svg';
import APMTransactionLogo from '../../assets/img/Icons_APMTransactions_White.svg';
import APMPeriodsLogo from '../../assets/img/Icons_APMPeriods_White.svg';
import APMPOSLogo from '../../assets/img/Icons_APMEquipments_White.svg';
// import userLogo from '../../assets/img/user_white.png';
import homeLogo from '../../assets/img/home.svg';
export interface ViewMapProps {
  label: string | '';
  link: string;
  icon: string;
}
const _zconfig: any = {
  menus: {
    downtime: true
  }
};
interface ViewMap {
  [key: string]: ViewMapProps;
}
const viewMap: ViewMap = {
  dashboard: {
    icon: homeLogo,
    label: 'DASHBOARD',
    link: '/dashboard'
  },
  equipment: {
    icon: equipmentLogo,
    label: 'GLOBAL_EQUIPMENT',
    link: '/equipment'
  },
  enterpriseTransactions: {
    icon: transactionLogo,
    label: 'TRANSACTIONS',
    link: '/transactions'
  },
  users: {
    icon: usersLogo,
    label: 'GLOBAL_USERS',
    link: '/users'
  },
  enterpriseEquipmentPeriods: {
    icon: periodLogo,
    label: 'ENTERPRISE_EQUIP_PERIODS_SHORT',
    link: '/enterpriseEquiPeriods'
  },
  bankTransactions: {
    icon: bankLogo,
    label: 'BANK_TRANSACTIONS',
    link: '/bankTransactions'
  },
  apmTransactions: {
    icon: APMTransactionLogo,
    label: 'APM_TRANSACTIONS',
    link: '/apm/trasactions'
  },
  apmPeriods: {
    icon: APMPeriodsLogo,
    label: 'APM_PERIODS',
    link: '/apm/periods'
  },
  apmEquipments: {
    icon: APMPOSLogo,
    label: 'APM_POS',
    link: '/apm/equipments'
  },
  msEquipmentList: {
    icon: MSEquipmentLogo,
    label: 'HELPDESK_EQUIPMENTS',
    link: '/helpdesk/equipments'
  },
  msEntitiesList: {
    icon: MSEntitiesLogo,
    label: 'HELPDESK_ENTITIES',
    link: '/helpdesk/entities'
  }
};

/**
 * Verifies if logged user has permissions\
 * Returns true or false
 * @param {*} appId Application ID
 * @param {*} mask permission mask
 * @param {*} userPermissions option
 */
export const hasPermissions = function (appId: number, mask: number, userPermissions: any) {
  // if (!userPermissions) {
  //   userPermissions = JSON.parse(sessionStorage.userPermissions);
  // }
  if (!userPermissions) {
    return false;
  }
  for (let index = 0; index < userPermissions.length; index++) {
    let userPermission = userPermissions[index];
    if (appId === userPermission.appId) {
      return (userPermission.permissionCode & mask) !== 0;
    }
  }
};

let addUnique = function (menus: ViewMapProps[], menu: ViewMapProps) {
  if (!menus.includes(menu)) {
    menus.push(menu);
  }
};
/**
 * Constructs the menu list based on logged user permissions
 * @param {*} userPermissions
 */
export const buildUserMenus = function (userPermissions: any[]) {
  let menus: ViewMapProps[] = [];

  if (!userPermissions) {
    return menus;
  }

  if (hasPermissions(1, 0x01 /* Edit users and permissions */, userPermissions)) {
    addUnique(menus, viewMap.users);
  }
  if (hasPermissions(1, 0x02 /* System Configurations */, userPermissions)) {
    addUnique(menus, viewMap.equipment);
    //addUnique(menus, viewMap.entities);
    //addUnique(menus, viewMap.branches);
    //addUnique(menus, viewMap.reasonsAndDeclarations);
    // if(_zconfig.menus?.eventDistributionList){
    //   addUnique(menus, viewMap.eventDistributionList);
    // }
    // if(_zconfig.menus?.withdrawalSetList){
    //   addUnique(menus, viewMap.withdrawalSetList);
    // }
    // if(_zconfig.menus?.biometricRecords){
    //   addUnique(menus, viewMap.biometricRecords);
    // }
    // if(_zconfig.menus?.nonbankuser){
    //   addUnique(menus, viewMap.nonbankuser);
    // }
    // if(_zconfig.menus?.downtime){
    //   addUnique(menus, viewMap.downtime);
    // }
  }
  if (hasPermissions(2, 0x01 /* Financial data */, userPermissions)) {
    addUnique(menus, viewMap.dashboard);
    addUnique(menus, viewMap.equipment);
    //addUnique(menus, viewMap.userSummary);
    addUnique(menus, viewMap.enterpriseTransactions);
    // addUnique(menus, viewMap.enterpriseShifts);
    addUnique(menus, viewMap.enterpriseEquipmentPeriods);
    //addUnique(menus, viewMap.bankTransactions);
    // addUnique(menus, viewMap.bankEquipmentPeriods);
  }
  if (hasPermissions(1, 0x04 /* Equipment Remote Operations */, userPermissions)) {
    addUnique(menus, viewMap.equipment);
  }
  if (hasPermissions(1, 0x20 /* Equipment Technician */, userPermissions)) {
    addUnique(menus, viewMap.equipment);
  }
  if (hasPermissions(2, 0x02 /* CIT */, userPermissions)) {
    //addUnique(menus, viewMap.bankEquipmentPeriods);
    addUnique(menus, viewMap.enterpriseEquipmentPeriods);
    addUnique(menus, viewMap.equipment);
  }
  if (hasPermissions(2, 0x04 /* Equipment Remote Financial Operations */, userPermissions)) {
    addUnique(menus, viewMap.equipment);
  }
  if (hasPermissions(2, 0x08 /* Operator */, userPermissions)) {
    addUnique(menus, viewMap.enterpriseTransactions);
    //addUnique(menus, viewMap.enterpriseShifts);
  }
  if (hasPermissions(2, 0x10 /* APM */, userPermissions)) {
    addUnique(menus, viewMap.apmTransactions);
    addUnique(menus, viewMap.apmPeriods);
    addUnique(menus, viewMap.apmEquipments);
    // addUnique(menus, viewMap.apmReports);
  }

  if (hasPermissions(4, 0x08 /* Helpdesk */, userPermissions)) {
    addUnique(menus, viewMap.msEquipmentList);
    addUnique(menus, viewMap.msEntitiesList);
    //   addUnique(menus, viewMap.msTicketList);
    //   addUnique(menus, viewMap.msDeviceList);
    //   addUnique(menus, viewMap.msEvents);
    //   addUnique(menus, viewMap.msTlogs);
    //   addUnique(menus, viewMap.msAuditLog);
    //   addUnique(menus, viewMap.msSyncCommands);
    //   addUnique(menus, viewMap.msCounter);
  }

  // if (hasPermissions(4, 0x02 /* Client management */ , userPermissions)) {
  //   addUnique(menus, viewMap.msEquipmentList);
  //   addUnique(menus, viewMap.msEntities);
  //   // addUnique(menus, viewMap.msPosSibs);
  // }
  // if (hasPermissions(4, 0x01 /* Agreement management */ , userPermissions)) {
  //   addUnique(menus, viewMap.msAgreement);
  // }
  // if (hasPermissions(4, 0x20 /* Event & Counter management */ , userPermissions)) {
  //   addUnique(menus, viewMap.msCounterDefs);
  //   addUnique(menus, viewMap.msEventManagement);
  // }
  // if (hasPermissions(5, 0x02 /* Manage Productions */ , userPermissions)) {
  //   addUnique(menus, viewMap.msWarehouse);
  //   addUnique(menus, viewMap.billOfMaterials);
  // }
  // if (hasPermissions(5, 0x04 /* Product Reception */ , userPermissions)) {
  //   addUnique(menus, viewMap.receptionProductions);
  //   addUnique(menus, viewMap.documents); //Limited access
  //   addUnique(menus, viewMap.msWarehouse);
  // }
  // if (hasPermissions(5, 0x01 /* Manage Products */ , userPermissions)) {
  //   addUnique(menus, viewMap.productManagement);
  // }
  // if (hasPermissions(100, 0x02 /* Stocks */ , userPermissions)) {
  //   addUnique(menus, viewMap.stocks);
  //   addUnique(menus, viewMap.stockinout);
  // }
  // if (hasPermissions(100, 0x04 /* Documents */ , userPermissions)) {
  //   addUnique(menus, viewMap.documents);
  // }
  // addUnique(menus, viewMap.userProfile);
  return menus;
};