import React, { useEffect } from 'react';
import { notifications } from '@mantine/notifications';

import './styles.scss';

interface AlertProps {
  variant: string;
  visible: boolean;
  toggleAlert: () => void;
  errorCode: number;
  errorMessage: string;
}

const Alert: React.FC<AlertProps> = ({ variant, visible, toggleAlert, errorCode, errorMessage }) => {
  useEffect(() => {
    if (visible) {
      notifications.show({
        position: 'top-center',
        withCloseButton: true,
        withBorder: false,
        title: `Error: ${errorCode}`,
        message: `Message: ${errorMessage}`,
        color: variant,
        autoClose: 3000,
        onHide: toggleAlert,
      });
    }
  }, [visible, errorCode, errorMessage, variant, toggleAlert]);

  return null;
};

export default Alert;
