import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, Group, Select, Table, TextInput } from '@mantine/core';
import ModalComponent from '../../../../components/modal';
import PaginationComponent from '../../../../components/pagination';
import { LoginState } from '../../../../client-redux/login/reducer';
import { AppState } from '../../../../client-redux/reducers';
import { Language } from '../../../../languages/languageHandler';
import { IHelpdeskEntityResponse, fetchHelpdeskEntitiesList } from '../../../../../libraries/helpdesk';
import './styles.scss';

interface ModalProps {
  isOpen: boolean;
  hideBtn: () => void;
  lang: Language;
  chooseEntid: (entid: number, name: string) => void;
}

const ChooseEntity = ({ isOpen, hideBtn, lang, chooseEntid }: ModalProps) => {
  const entityTypes = [
    { value: '', label: lang?.ENTITY_TYPE_NULL },
    { value: '0', label: lang?.ENTITY_TYPE_0 },
    { value: '1', label: lang?.ENTITY_TYPE_1 },
    { value: '2', label: lang?.ENTITY_TYPE_2 },
    { value: '3', label: lang?.ENTITY_TYPE_3 }
  ];
  const loginInfo = useSelector<AppState, LoginState>(state => state.login);
  const controller = new AbortController();
  const [type, setType] = useState<string>('');
  const [entid, setEntid] = useState<number>(0);
  const [name, setName] = useState<string>('');
  const [nif, setNif] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [totalRows] = useState<boolean>(true);
  const [message, setMessage] = useState<IHelpdeskEntityResponse>();
  const [statusOperCode, setStatusOperCode] = useState<number | undefined>();
  const [noResults, setNoResults] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [lastSelectedPage, setLastSelectedPage] = useState<number>(1);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const getEntityList = () => {
    if (!loginInfo.token) {
      return;
    }
    if (loading) {
      setNoResults(false);
      controller.abort();
    }

    fetchHelpdeskEntitiesList(offset, limit, totalRows, controller, type, entid, name, nif)
      .then(response => {
        if (response.statusOper.code === 0) {
          setMessage(response);
          setNoResults(false);
          setStatusOperCode(response.statusOper.code);
          setLoading(false);
          setCurrentPage(lastSelectedPage);
        } else {
          setNoResults(true);
          setLoading(false);
          setStatusOperCode(response.statusOper.code);
        }
      })
      .catch(error => {
        if (error.name === 'AbortError') {
        } else {
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
    } else {
      getEntityList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, limit, currentPage]);

  const changePage = (page: number) => {
    const newOffset = (page - 1) * limit;
    setCurrentPage(page);
    setOffset(newOffset);
    setLastSelectedPage(page);
  };

  const resultsSelect = (newValue: number) => {
    const newLimit = Number(newValue);
    setLimit(newLimit);
    setCurrentPage(0);
    setOffset(0);
    setCurrentPage(1);
  };

  /* Entity handle*/
  const handleEntity = (value: number) => {
    if (value !== null) {
      const selectedEntid = Number(value);
      setEntid(selectedEntid);
    }
  };

  /* Name handle*/
  const handleName = (value: string) => {
    if (value !== null) {
      const selectedName = String(value);
      setName(selectedName);
    }
  };

  /* NIF handle*/
  const handleNIF = (value: number) => {
    if (value !== null) {
      const selectedNif = Number(value);
      setNif(selectedNif);
    }
  };

  /* Type handle*/
  const handleNewTableByType = (value: string | 0) => {
    if (value !== null) {
      const selectedType = String(value);
      setType(selectedType!);
    }
  };

  const handleEntitySelection = (value: number, name: string) => {
    hideBtn();
    chooseEntid(value, name);
  };

  return (
    <>
      <ModalComponent
        show={isOpen}
        handleClick={hideBtn}
        animation={true}
        title={lang?.HELPDESK_CHOOSE_ENTITY}
        size={'lg'}
        content={
          <Modal.Body style={{ margin: '0', padding: '0' }}>
            <Group classNames={{ root: 'choose-entity' }}>
              {/* Entity */}
              <TextInput label={'Entid'} onChange={event => handleEntity(Number(event.target.value))} />

              {/* Name */}
              <TextInput label={lang?.GLOBAL_NAME} onChange={event => handleName(String(event.target.value))} />

              {/* NIF */}
              <TextInput label={'NIF'} onChange={event => handleNIF(Number(event.target.value))} />

              {/* Company types */}
              <Select
                label={lang?.GLOBAL_TYPE}
                data={entityTypes}
                placeholder={lang?.GLOBAL_ALL}
                onChange={value => handleNewTableByType(String(value))}
              />

              {/* Search */}
              <Button radius="md" onClick={getEntityList}>
                {lang?.GLOBAL_SEARCH}
              </Button>
            </Group>

            {/* Entity List */}
            {message !== undefined && (
              <Group>
                <Table.ScrollContainer minWidth={500}>
                  <Table className={'entity-table'} striped highlightOnHover>
                    <Table.Thead>
                      <Table.Tr>
                        <Table.Th>Entid</Table.Th>
                        <Table.Th>{lang?.GLOBAL_NAME}</Table.Th>
                      </Table.Tr>
                    </Table.Thead>

                    <Table.Tbody>
                      {noResults ? (
                        <Table.Tr>
                          <Table.Td colSpan={16} style={{ textAlign: 'center', width: '1100px' }}>
                            {lang?.GLOBAL_NO_RESULTS}
                          </Table.Td>
                        </Table.Tr>
                      ) : (
                        message?.entities.map((entity, index) => {
                          return (
                            <Table.Tr
                              key={index}
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleEntitySelection(entity.entid, entity.name)}
                            >
                              <Table.Td>{entity.entid}</Table.Td>
                              <Table.Td>{entity.name}</Table.Td>
                            </Table.Tr>
                          );
                        })
                      )}
                    </Table.Tbody>
                  </Table>
                </Table.ScrollContainer>
                <PaginationComponent
                  total={message?.totalRows}
                  statusOperCode={statusOperCode}
                  itemsPerPage={limit}
                  onPageChange={changePage}
                  onResultsChange={resultsSelect}
                  initialPage={currentPage}
                />
              </Group>
            )}
          </Modal.Body>
        }
      />
    </>
  );
};

export default ChooseEntity;