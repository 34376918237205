import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Group, Button, Container, Grid, TextInput, Select } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useSelector } from 'react-redux';
import { AppState } from '../../../client-redux/reducers';
import { Language } from '../../../languages/languageHandler';
import { fetchRegions } from '../../../../libraries/region';
import { ILanguage, fetchLanguages } from '../../../../libraries/language';
import { IEntityActivity } from '../../../../libraries/entity';
import { LoginState } from '../../../client-redux/login/reducer';
import {
  EntityRequests,
  IHelpdeskEntityRequestsResponse,
  fetchEntitiesActivity,
  fetchHelpdeskEntityInfo,
  updateHelpdeskEntity
} from '../../../../libraries/helpdesk';
import CustomModal from '../../../components/custom-modal';
import ChooseEntity from '../equipments/register-equipment/choose-entity-modal';
import * as Icon from '@tabler/icons-react';
import './styles.scss';
import DeleteHeldeskEntity from './delete-entity';

enum SELECTED_FIELD {
  GID,
  ENTITY,
  BILLING
}

enum MODE {
  READ = 'read',
  EDIT = 'edit',
  NEW = 'new'
}

const HelpdeskEntity: React.FC = () => {
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const [regions, setRegions] = useState<string[]>([]);
  const [languages, setLanguages] = useState<ILanguage[]>([]);
  const [activities, setActivities] = useState<IEntityActivity[]>([]);
  const loginInfo = useSelector<AppState, LoginState>(state => state.login);
  const controller = new AbortController();
  const [message, setMessage] = useState<IHelpdeskEntityRequestsResponse>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedField, setSelectedField] = useState<SELECTED_FIELD>(SELECTED_FIELD.GID);

  const { entid } = useParams<{ entid: string }>();
  const location = useLocation();
  const [, setReloadPage] = useState(false);

  const entityTypes = [
    { value: '0', label: lang?.ENTITY_TYPE_0 },
    { value: '1', label: lang?.ENTITY_TYPE_1 },
    { value: '2', label: lang?.ENTITY_TYPE_2 },
    { value: '3', label: lang?.ENTITY_TYPE_3 }
  ];

  useEffect(() => {
    getEquipmentList();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchRegions().then(response => {
      setRegions(response.regions);
    });
    fetchLanguages().then(response => {
      setLanguages(response.languages);
    });
    fetchEntitiesActivity().then(response => {
      setActivities(response.list);
    });
  }, []);

  const getEquipmentList = () => {
    if (!loginInfo.token) {
      return;
    }
    fetchHelpdeskEntityInfo(Number(entid), controller)
      .then(response => {
        if (response.statusOper.code === 0) {
          setMessage(response);
        } else {
        }
      })
      .catch(error => {
        if (error.name === 'AbortError') {
        } else {
        }
      });
  };

  const form = useForm({
    initialValues: {
      activity: 0,
      address: '',
      billingEntity: 0,
      billingVolume: 0,
      contact: '',
      description: '',
      email: '',
      entid: Number(entid) || 0,
      gid: 0,
      lang: 0,
      logo: '',
      mobile: '',
      name: '',
      nif: 0,
      ownerid: 0,
      postalCode: '',
      printType: 0,
      region: '',
      reportType: 0,
      sibsMerchantCode: '',
      type: 0,
      usePos: 0,
      useShifts: 0
    }
  });

  useEffect(() => {
    if (message) {
      const entity = message?.entities?.[0];
      form.setValues({
        ...form.values,
        activity: entity.activity || 0,
        address: entity.address || '',
        billingEntity: entity.billingEntity || 0,
        billingVolume: entity.billingVolume || 0,
        contact: entity.contact || '',
        description: entity.description || '',
        email: entity.email || '',
        entid: Number(entid),
        gid: entity.gid || 0,
        lang: entity.lang || 0,
        logo: entity.logo || '',
        mobile: entity.mobile || '',
        name: entity.name || '',
        nif: entity.nif || 0,
        ownerid: entity.ownerid || 0,
        postalCode: entity.postalCode || '',
        printType: entity.printType || 0,
        region: entity.region || '',
        reportType: entity.reportType || 0,
        sibsMerchantCode: entity.sibsMerchantCode || '',
        type: entity.type || 0,
        usePos: entity.usePos || 0,
        useShifts: entity.useShifts || 0
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const submitForm = async (form: any) => {
    const request: EntityRequests = {
      activity: form.activity,
      address: form.address,
      billingEntity: form.billingEntity,
      billingVolume: form.billingVolume,
      contact: form.contact,
      description: form.description,
      email: form.email,
      entid: Number(form.entid),
      gid: form.gid,
      lang: form.lang,
      logo: form.logo,
      mobile: form.mobile,
      name: form.name,
      nif: form.nif,
      ownerid: form.ownerid,
      postalCode: form.postalCode,
      printType: form.printType,
      region: form.region,
      reportType: form.reportType,
      sibsMerchantCode: form.sibsMerchantCode,
      type: form.type,
      usePos: form.usePos,
      useShifts: form.useShifts
    };

    const formController = new AbortController();

    try {
      const response = await updateHelpdeskEntity(request, formController);

      if (response.statusOper && response.statusOper.code !== 0) {
        const errorMessage = response.statusOper.message || 'An unknown error occurred.';
        setErrorMessage(
          `${lang?.EDIT_EQUIPMENTS_ALERT_UPDATED_ERROR} (Code: ${response.statusOper.code}) ${errorMessage}`
        );
        setShowErrorDialog(true);
      } else {
        setSuccessMessage(lang?.EDIT_EQUIPMENTS_ALERT_UPDATED_SUCCESS || '');
        setShowSuccessDialog(true);
        setTimeout(() => {
          window.location.href = `/helpdesk/entities/edit/${entid}`;
        }, 2000);
      }
    } catch (error) {
      console.error('Error during the API call:', error);
      setErrorMessage(`${lang?.EDIT_EQUIPMENTS_ALERT_UPDATED_ERROR} (Status: ${errorMessage}`);
      setShowErrorDialog(true);
    }
  };

  const chooseEntid = (entid: number) => {
    switch (selectedField) {
      case SELECTED_FIELD.GID:
        form.setValues({ gid: entid });
        break;
      case SELECTED_FIELD.BILLING:
        form.setValues({ billingEntity: entid });
        break;
      case SELECTED_FIELD.ENTITY:
        form.setValues({ entid });
        break;
      default:
        break;
    }
  };

  const toggleChooseEntityModal = () => {
    setOpenModal(!openModal);
    setSelectedField(SELECTED_FIELD.GID);
  };

  const toggleChooseBillingModal = () => {
    setOpenModal(!openModal);
    setSelectedField(SELECTED_FIELD.BILLING);
  };

  const determineMode = (path: any) => {
    switch (path) {
      case '/helpdesk/entities/new/0':
        return 'new';
      case (path.match(/\/helpdesk\/entities\/read\/\d+/) || {}).input:
        return 'read';
      case (path.match(/\/helpdesk\/entities\/edit\/\d+/) || {}).input:
        return 'edit';
      default:
        return 'read';
    }
  };

  const mode = determineMode(location.pathname);

  const handleReload = () => {
    setReloadPage(true);
    setTimeout(() => {
      setOpenModal(false);
      window.location.href = `/helpdesk/entities/`;
    }, 2000);
  };

  return (
    <Container fluid classNames={{ root: mode === MODE.NEW ? 'helpdesk-entity-new' : 'helpdesk-entity-read' }}>
      {lang && (
        <ChooseEntity
          lang={lang}
          isOpen={openModal}
          hideBtn={() => toggleChooseEntityModal()}
          chooseEntid={chooseEntid}
        />
      )}

      <form
        onSubmit={form.onSubmit(values => {
          submitForm(values);
        })}
      >
        <Grid gutter="lg">
          {/* Group (GID) */}
          <Grid.Col span={12}>
            <Group>
              <TextInput
                className="wide-input"
                label={lang?.EDIT_ENTITY_FORM_LABEL_GID}
                placeholder={mode === MODE.NEW ? `${lang?.GLOBAL_ENTITY}` : ''}
                {...form.getInputProps('gid')}
                value={form.values.gid}
                disabled={mode !== MODE.NEW}
              />
              {mode === MODE.NEW && (
                <Icon.IconSearch stroke={3} onClick={toggleChooseEntityModal} style={{ marginTop: '1vh' }} />
              )}
            </Group>
          </Grid.Col>

          {/* Entid */}
          <Grid.Col span={12}>
            <TextInput
              className="wide-input"
              label="Entid"
              placeholder={mode === MODE.NEW ? 'Entid' : ''}
              {...form.getInputProps('entid')}
              value={form.values.entid}
              disabled={mode !== MODE.NEW}
            />
          </Grid.Col>

          {/* Type */}
          <Grid.Col span={12}>
            <Select
              className="wide-input"
              label={lang?.GLOBAL_TYPE}
              placeholder={mode === MODE.NEW ? lang?.GLOBAL_TYPE : ''}
              {...form.getInputProps('type')}
              data={[
                ...(entityTypes?.map(entityType => ({
                  value: entityType.value.toString(),
                  label: entityType.label
                })) || [])
              ]}
              value={form.values.type?.toString()}
              disabled={mode === MODE.READ}
            />
          </Grid.Col>

          {/* Name */}
          <Grid.Col span={12}>
            <TextInput
              className="wide-input"
              label={lang?.GLOBAL_NAME}
              placeholder={mode === MODE.NEW ? lang?.GLOBAL_NAME : ''}
              {...form.getInputProps('name')}
              disabled={mode === MODE.READ}
            />
          </Grid.Col>

          {/* Description */}
          <Grid.Col span={12}>
            <TextInput
              className="wide-input"
              label={lang?.EQUIPMENT_DESCRIPTION}
              placeholder={mode === MODE.NEW ? lang?.EQUIPMENT_DESCRIPTION : ''}
              {...form.getInputProps('description')}
              disabled={mode === MODE.READ}
            />
          </Grid.Col>

          {/* Mobile */}
          <Grid.Col span={5.5}>
            <TextInput
              label={lang?.USER_MOBILE}
              placeholder={mode === MODE.NEW ? lang?.USER_MOBILE : ''}
              {...form.getInputProps('mobile')}
              disabled={mode === MODE.READ}
            />
          </Grid.Col>

          {/* Cell phone */}
          <Grid.Col span={5.5}>
            <TextInput
              label={lang?.USER_PHONE}
              placeholder={mode === MODE.NEW ? lang?.USER_PHONE : ''}
              {...form.getInputProps('contact')}
              disabled={mode === MODE.READ}
            />
          </Grid.Col>

          {/* Email */}
          <Grid.Col span={12}>
            <TextInput
              className="wide-input"
              label="Email"
              placeholder={mode === MODE.NEW ? 'Email' : ''}
              {...form.getInputProps('email')}
              disabled={mode === MODE.READ}
            />
          </Grid.Col>

          {/* Address */}
          <Grid.Col span={12}>
            <TextInput
              className="wide-input"
              label={lang?.GLOBAL_ADDRESS}
              placeholder={mode === MODE.NEW ? lang?.GLOBAL_ADDRESS : ''}
              {...form.getInputProps('address')}
              disabled={mode === MODE.READ}
            />
          </Grid.Col>

          {/* Zip code */}
          <Grid.Col span={12}>
            <TextInput
              className="wide-input"
              label={lang?.EVENT_ZIP_CODE}
              placeholder={mode === MODE.NEW ? lang?.EVENT_ZIP_CODE : ''}
              {...form.getInputProps('postalCode')}
              disabled={mode === MODE.READ}
            />
          </Grid.Col>

          <Group>
            {/* Language */}
            <Grid.Col span={5.5}>
              <Select
                label={lang?.USER_LANG}
                placeholder={mode === MODE.NEW ? lang?.USER_LANG : ''}
                {...form.getInputProps('lang')}
                value={form.values.lang?.toString()}
                defaultValue=""
                data={[
                  ...(languages?.map(language => ({
                    value: language.langid.toString(),
                    label: language.nativeName
                  })) || [])
                ]}
                searchable
                disabled={mode === MODE.READ}
              />
            </Grid.Col>

            {/* Region */}
            <Grid.Col span={5.5}>
              <Select
                label={lang?.USER_REGION}
                placeholder={mode === MODE.NEW ? lang?.USER_REGION : ''}
                {...form.getInputProps('region')}
                defaultValue=""
                data={[
                  ...(regions?.map(region => ({
                    value: region,
                    label: region
                  })) || [])
                ]}
                searchable
                disabled={mode === MODE.READ}
              />
            </Grid.Col>
          </Group>

          {/* Sibs Mech. Code */}
          <Grid.Col span={12}>
            <TextInput
              className="wide-input"
              label={lang?.EDIT_ENTITY_FORM_LABEL_SIBSMERCHANTCODE}
              placeholder={mode === MODE.NEW ? lang?.EDIT_ENTITY_FORM_LABEL_SIBSMERCHANTCODE : ''}
              {...form.getInputProps('sibsMerchantCode')}
              disabled={mode === MODE.READ}
            />
          </Grid.Col>

          {/* Billing entity */}
          <Grid.Col span={12}>
            <Group>
              <TextInput
                className="wide-input"
                label={lang?.EDIT_ENTITY_FORM_LABEL_BILLINGENTITY}
                placeholder={mode === MODE.NEW ? lang?.EDIT_ENTITY_FORM_LABEL_BILLINGENTITY : ''}
                {...form.getInputProps('billingEntity')}
                disabled={mode === MODE.READ}
              />
              {mode !== MODE.READ && (
                <Icon.IconSearch stroke={3} onClick={toggleChooseBillingModal} style={{ marginTop: '1vh' }} />
              )}
            </Group>
          </Grid.Col>

          {/* NIF */}
          <Grid.Col span={12}>
            <TextInput
              className="wide-input"
              label="NIF"
              placeholder={mode === MODE.NEW ? 'NIF' : ''}
              {...form.getInputProps('nif')}
              disabled={mode === MODE.READ}
            />
          </Grid.Col>

          <Group>
            {/* Owner ID */}
            <Grid.Col span={5.5}>
              <TextInput
                label={lang?.EDIT_ENTITY_FORM_LABEL_OWNERID}
                placeholder={mode === MODE.NEW ? lang?.EDIT_ENTITY_FORM_LABEL_OWNERID : ''}
                {...form.getInputProps('ownerid')}
                disabled={mode === MODE.READ}
              />
            </Grid.Col>

            {/* Activity branch */}
            <Grid.Col span={5.5}>
              <Select
                label={lang?.EDIT_ENTITY_FORM_LABEL_ACTIVITY}
                placeholder={mode === MODE.NEW ? lang?.EDIT_ENTITY_FORM_LABEL_ACTIVITY : ''}
                {...form.getInputProps('activity')}
                value={form.values.activity?.toString() || ''}
                data={[
                  { value: '', label: lang?.EDIT_ENTITY_FORM_LABEL_ACTIVITY_NOT_DEFINED },
                  ...(activities?.map(activity => ({
                    value: activity.id.toString(),
                    label: activity.description
                  })) || [])
                ]}
                searchable
                disabled={mode === MODE.READ}
              />
            </Grid.Col>
          </Group>

          {/* Billing volume */}
          <Grid.Col span={12}>
            <TextInput
              className="wide-input"
              label={lang?.EDIT_ENTITY_FORM_LABEL_BILLVOLUME}
              placeholder={mode === MODE.NEW ? lang?.EDIT_ENTITY_FORM_LABEL_BILLVOLUME : ''}
              {...form.getInputProps('billingVolume')}
              disabled={mode === MODE.READ}
            />
          </Grid.Col>

          {/* Shifts */}
          <Grid.Col span={12}>
            <TextInput
              className="wide-input"
              label={lang?.EDIT_ENTITY_FORM_LABEL_USESHIFTS}
              placeholder={mode === MODE.NEW ? lang?.EDIT_ENTITY_FORM_LABEL_USESHIFTS : ''}
              {...form.getInputProps('useShifts')}
              disabled={mode === MODE.READ}
            />
          </Grid.Col>

          {/* POS */}
          <Grid.Col span={12}>
            <TextInput
              className="wide-input"
              label="POS"
              placeholder={mode === MODE.NEW ? 'POS' : ''}
              {...form.getInputProps('usePos')}
              disabled={mode === MODE.READ}
            />
          </Grid.Col>

          {/* Print type */}
          <Grid.Col span={12}>
            <TextInput
              className="wide-input"
              label={lang?.EDIT_ENTITY_FORM_LABEL_PRINTTYPE}
              placeholder={mode === MODE.NEW ? lang?.EDIT_ENTITY_FORM_LABEL_PRINTTYPE : ''}
              {...form.getInputProps('printType')}
              disabled={mode === MODE.READ}
            />
          </Grid.Col>

          {/* Report type */}
          <Grid.Col span={12}>
            <TextInput
              className="wide-input"
              label={lang?.EDIT_ENTITY_FORM_LABEL_REPORTTYPE}
              placeholder={mode === MODE.NEW ? lang?.EDIT_ENTITY_FORM_LABEL_REPORTTYPE : ''}
              {...form.getInputProps('reportType')}
              disabled={mode === MODE.READ}
            />
          </Grid.Col>

          {/* Logo */}
          <Grid.Col span={12}>
            <TextInput
              className="wide-input"
              label={lang?.EDIT_ENTITY_FORM_LABEL_LOGO}
              placeholder={mode === MODE.NEW ? lang?.EDIT_ENTITY_FORM_LABEL_LOGO : ''}
              {...form.getInputProps('logo')}
              disabled={mode === MODE.READ}
            />
          </Grid.Col>

          {/* Buttons */}
          <Group className="submit-save-buttons" style={{ marginTop: '2vh' }}>
            {mode !== MODE.READ && (
              <Button type="submit" color="green">
                {mode === MODE.NEW ? `${lang?.GLOBAL_SAVE_BUTTON}` : `${lang?.GLOBAL_UPDATE}`}
              </Button>
            )}

            {mode === MODE.EDIT && <DeleteHeldeskEntity entity={Number(entid)} onReload={handleReload} />}
          </Group>
        </Grid>
      </form>

      <CustomModal
        opened={showSuccessDialog}
        onClose={() => setShowSuccessDialog(false)}
        message={successMessage}
        backgroundColor="#3BDF70"
        textColor="black"
      />

      <CustomModal
        opened={showErrorDialog}
        onClose={() => setShowErrorDialog(false)}
        message={errorMessage}
        backgroundColor="#E3353F"
        textColor="white"
      />
    </Container>
  );
};

export default HelpdeskEntity;
