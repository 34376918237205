import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Group, Button, Container, Table, Grid, GridCol, TextInput, Select, Checkbox, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import Header from '../../components/util/header';
import { useSelector } from 'react-redux';
import { fetchLanguages, ILanguage } from '../../../libraries/language';
import { fetchRegions } from '../../../libraries/region';
import { fetchPermissions, fetchUser, IUser, PermissionOption, updateUser } from '../../../libraries/users';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import { preparePermissions } from '../users/permissions';
import { LoginState } from '../../client-redux/login/reducer';
import UpdateUserConfirmation from './update-user-confirmation';
import * as Icon from '@tabler/icons-react';
import './styles.scss';

const Profile = () => {
  const loginInfo = useSelector<AppState, IUser>(state => state.login.user);
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const login = useSelector<AppState, LoginState>(state => state.login);
  const [openPassword, setOpenPassword] = useState<boolean>(false);
  const [openPin, setOpenPin] = useState<boolean>(false);
  const [, setIsPasswordValid] = useState(true);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<IUser>();
  const [, setCurrentMobile] = useState<number>();
  const [, setCurrentEmail] = useState<number>();
  const [, setCurrentLang] = useState<number>();
  const [, setCurrentRegion] = useState<string>();
  const [regions, setRegions] = useState<string[]>([]);
  const [permissions, setPermissions] = useState<PermissionOption[]>([]);
  const [languages, setLanguages] = useState<ILanguage[]>([]);
  const [, setShowSuccessDialog] = useState<boolean>(false);
  const [, setShowErrorDialog] = useState<boolean>(false);
  const [, setSuccessMessage] = useState<string>('');
  const [, setErrorMessage] = useState<string>('');

  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      entid: loginInfo?.entid,
      uid: loginInfo?.uid,
      name: loginInfo?.name,
      mobile: loginInfo?.mobile,
      email: loginInfo?.email,
      lang: loginInfo?.lang,
      region: loginInfo?.region,
      password: loginInfo?.password,
      passwordConfirm: '',
      pin: loginInfo?.pin,
      pinConfirm: ''
    },
    validate: {
      passwordConfirm: (value, values) => (value !== values.password ? `${lang?.USER_NEW_PASSWORD_ERROR}` : null),
      pinConfirm: (value, values) => (value !== values.pin ? `${lang?.USER_NEW_PIN_ERROR}` : null)
    }
  });

  const openModal = () => {
    setOpenConfirmation(true);
  };

  const closeModal = () => {
    setOpenConfirmation(false);
  };

  const handlePasswordSubmit = async () => {
    const isValid = true;

    if (isValid) {
      await updater.mutateAsync({ user: form.values });
      setOpenConfirmation(true);
      closeModal();
    } else {
      setIsPasswordValid(false);
    }
  };

  const submitEditForm = () => {
    openModal();
  };

  const updater = useMutation(updateUser, {
    onSuccess: data => {
      const { statusOper } = data;
      if (statusOper.code === 0) {
        setShowSuccessDialog(true);
        setSuccessMessage(lang?.EDIT_USER_ALERT_UPDATE_SUCCESS);
      } else {
        setShowErrorDialog(true);
        setErrorMessage(lang?.EDIT_USER_ALERT_UPDATE_ERROR + '\n' + statusOper.message);
      }
    },
    onError: error => {
      setShowErrorDialog(true);
      setErrorMessage(lang?.EDIT_USER_ALERT_UPDATE_ERROR + error);
    }
  });

  useEffect(() => {
    fetchUser(loginInfo.entid, loginInfo.uid).then(userResponse => {
      setCurrentUser(userResponse.users[0]);
      setCurrentLang(userResponse.users[0].lang);
      setCurrentRegion(userResponse.users[0].region);
      setCurrentMobile(userResponse.users[0].mobile);
      setCurrentEmail(userResponse.users[0].email);
      fetchPermissions().then(permissionsResponse => {
        preparePermissions(permissionsResponse.list, userResponse.users[0]);
        setPermissions(permissionsResponse.list);
      });
    });

    fetchRegions().then(response => {
      setRegions(response.regions);
    });
    fetchLanguages().then(response => {
      setLanguages(response.languages);
    });
  }, [loginInfo?.entid, loginInfo?.uid, openConfirmation]);

  const togglePassword = () => {
    setOpenPassword(!openPassword);
  };

  const togglePin = () => {
    setOpenPin(!openPin);
  };

  return (
    <Container fluid classNames={{ root: 'profile' }}>
      {openConfirmation && login && currentUser && (
        <>
          <UpdateUserConfirmation
            lang={lang}
            userData={form.values}
            toggleModal={closeModal}
            openModal={openConfirmation}
            onSubmit={handlePasswordSubmit}
          />
        </>
      )}

      <Header pageTitle={lang?.USER_MY_PROFILE} />
      <form
        onSubmit={event => {
          event.preventDefault();
          submitEditForm();
        }}
      >
        <Grid justify="center" align="flex-start" gutter="lg">
          <GridCol span={{ base: 12, md: 6, lg: 6 }}>
            <Group>
              <Title order={3}>{lang?.USER_INFO}</Title>
            </Group>

            {/* Entity */}
            <Group>
              <Grid>
                <GridCol span={{ base: 6, md: 6, lg: 6 }}>
                  <TextInput label={lang?.USER_ENTITY} type="text" {...form.getInputProps('entid')}></TextInput>
                </GridCol>

                {/* ID */}
                <GridCol span={{ base: 6, md: 6, lg: 6 }}>
                  <TextInput label="ID" type="text" {...form.getInputProps('uid')}></TextInput>
                </GridCol>
              </Grid>
            </Group>

            {/* Name */}
            <Group  className='name'>
              <Grid>
                <GridCol span={{ base: 12, md: 12, lg: 12 }}>
                  <TextInput label={lang?.GLOBAL_NAME} {...form.getInputProps('name')} type="text"></TextInput>
                </GridCol>
              </Grid>
            </Group>

            {/* Mobile */}
            <Group>
              <Grid>
                <GridCol span={{ base: 6, md: 6, lg: 6 }}>
                  <TextInput label={lang?.USER_MOBILE} {...form.getInputProps('mobile')} type="text"></TextInput>
                </GridCol>

                {/* Email */}
                <GridCol span={{ base: 6, md: 6, lg: 6 }}>
                  <TextInput label={lang?.USER_EMAIL} {...form.getInputProps('email')} type="email"></TextInput>
                </GridCol>
              </Grid>
            </Group>

            <Group>
              <Grid>
                <GridCol span={{ base: 6, md: 6, lg: 6 }}>
                  {/* Language */}
                  <Select
                    label={lang?.USER_LANG}
                    data={[
                      ...(languages?.map(language => ({
                        value: language.langid.toString(),
                        label: language.nativeName
                      })) || [])
                    ]}
                    {...form.getInputProps('lang')}
                    value={form.values.lang?.toString()}
                  />
                </GridCol>

                {/* Time zone */}
                <GridCol span={{ base: 6, md: 6, lg: 6 }}>
                  <Select
                    label={lang?.USER_REGION}
                    data={[
                      ...(regions?.map(region => ({
                        value: region,
                        label: region
                      })) || [])
                    ]}
                    value={String(form.values.region)}
                    searchable={true}
                    {...form.getInputProps('region')}
                  />
                </GridCol>
              </Grid>
            </Group>

            <Group>
              <Grid>
                {/* Password */}
                <GridCol span={{ base: 6, md: 6, lg: 6 }}>
                  <GridCol>
                    <h4>Password</h4>
                  </GridCol>

                  <GridCol>
                    {!openPassword && (
                      <Button color="#016273" radius="md" onClick={togglePassword}>
                        {lang?.USER_CHANGE_PASSWORD}
                      </Button>
                    )}
                    {openPassword && (
                      <>
                        <GridCol span={{ base: 6, md: 6, lg: 12 }}>
                          <TextInput
                            type="password"
                            placeholder={lang?.USER_NEW_PASSWORD}
                            {...form.getInputProps('password')}
                          />
                          <TextInput
                            type="password"
                            placeholder={lang?.USER_CONFIRM_PASSWORD}
                            {...form.getInputProps('passwordConfirm')}
                            error={form.errors.passwordConfirm}
                          />
                        </GridCol>
                        <GridCol>
                          <Button color="#016273" radius="md" onClick={togglePassword}>
                            <Icon.IconArrowUpBar />
                          </Button>
                        </GridCol>
                      </>
                    )}
                  </GridCol>
                </GridCol>

                {/* Pin */}
                <GridCol span={{ base: 6, md: 6, lg: 6 }}>
                  <GridCol>
                    <h4>PIN</h4>
                  </GridCol>

                  <GridCol>
                    {!openPin && (
                      <Button color="#016273" radius="md" onClick={togglePin}>
                        {lang?.USER_CHANGE_PIN}
                      </Button>
                    )}
                    {openPin && (
                      <>
                        <GridCol span={{ base: 6, md: 6, lg: 12 }}>
                          <TextInput type="password" placeholder={lang?.USER_NEW_PIN} {...form.getInputProps('pin')} />
                          <TextInput
                            type="password"
                            placeholder={lang?.USER_CONFIRM_PIN}
                            {...form.getInputProps('pinConfirm')}
                            error={form.errors.pinConfirm}
                          />
                        </GridCol>

                        <GridCol>
                          <Button color="#016273" radius="md" onClick={togglePin}>
                            <Icon.IconArrowUpBar />
                          </Button>
                        </GridCol>
                      </>
                    )}
                  </GridCol>
                </GridCol>
              </Grid>
            </Group>

            {/* Permissions */}
            <Group>
              <Grid>
                <GridCol span={{ base: 11, md: 12, lg: 12 }}>
                  <h3>{lang?.USER_PERMISSIONS}</h3>
                  {permissions &&
                    lang &&
                    permissions
                      .filter(option => option.selected)
                      .map(po => {
                        return (
                          <Checkbox key={po.langKey} checked={po.selected} label={lang[po.langKey]} disabled={true} />
                        );
                      })}
                </GridCol>
              </Grid>
            </Group>

            <div hidden>
              <div>
                <div>
                  <h5>{lang?.USER_DELEGATIONS}</h5>
                </div>

                <Table>
                  <Table.Thead>
                    <Table.Tr>
                      <Table.Th>SI</Table.Th>
                      <Table.Th>{lang?.GLOBAL_NAME}</Table.Th>
                    </Table.Tr>
                  </Table.Thead>

                  <Table.Tbody>
                    <Table.Tr>
                      <Table.Td style={{ textAlign: 'center' }}>{lang?.GLOBAL_ALL}</Table.Td>
                    </Table.Tr>
                  </Table.Tbody>
                </Table>
              </div>
            </div>

            <Group className='buttons'>
              <Grid>
                <GridCol span={{ base: 6, md: 6, lg: 3 }}>
                  <Button type="submit" color="green">
                    {lang?.GLOBAL_SAVE_BUTTON}
                  </Button>
                </GridCol>
                <GridCol span={{ base: 6, md: 6, lg: 3 }}>
                  <Button color="red">{lang?.GLOBAL_DELETE}</Button>
                </GridCol>
              </Grid>
            </Group>
          </GridCol>
        </Grid>
      </form>
    </Container>
  );
};

export default Profile;
