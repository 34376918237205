import { Table } from '@mantine/core';
import { Product } from '../../../libraries/transactions';
import { Language } from '../../languages/languageHandler';
import './styles.scss';

interface ProductTableProps {
  lang: Language;
  products: Product[];
}

const ProductsTable = ({ lang, products }: ProductTableProps) => {
  return (
    <Table.ScrollContainer className="product-table" minWidth={500}>
      <Table  withTableBorder withColumnBorders>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>{lang.PRODUCT_ID}</Table.Th>
            <Table.Th>{lang.GLOBAL_NAME}</Table.Th>
            <Table.Th>{lang.PRODUCT_PRICE}</Table.Th>
            <Table.Th>{lang.EQUIPMENT_STATE_LABEL_QUANTITY}</Table.Th>
          </Table.Tr>
        </Table.Thead>

        <Table.Tbody>
          {products.map((product, index) => (
            <Table.Tr key={index}>
              <Table.Td>{product.productid}</Table.Td>
              <Table.Td>{product.name}</Table.Td>
              <Table.Td>{`${product.price} €`}</Table.Td>
              <Table.Td>{product.quantity}</Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
};

export default ProductsTable;
