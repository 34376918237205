import * as Sentry from "@sentry/react";

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    stateTransformer: state => {
        const transformedState = {
            ...state,
            login: {
                ...state.login,
                token: state.login.token ? "<undisclosed>" : undefined,
                user: {
                    ...state.login.user,
                    password: state.login.user?.password ? "<undisclosed>" : undefined
                }
            }
        }
        return transformedState;
    }
});