import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Loader, Table, Container, Group, Menu } from '@mantine/core';
import Header from '../../../components/util/header';
import ShowEquipment from '../../../components/show-equipment';
import { useSelector } from 'react-redux';
import {
  fetchExistencesMSReport,
  fetchHelpdeskEquipmentList,
  IHelpdeskEquipmentResponse
} from '../../../../libraries/helpdesk';
import { AppState } from '../../../client-redux/reducers';
import { Language } from '../../../languages/languageHandler';
import moment from 'moment';
import PaginationComponent from '../../../components/pagination';
import { LoginState } from '../../../client-redux/login/reducer';
import HelpdeskFilterBar from '../../../components/helpdesk-filterbar';
import CustomModal from '../../../components/custom-modal';
import './styles.scss';

const HelpdeskEquipment: React.FC = () => {
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const loginInfo = useSelector<AppState, LoginState>(state => state.login);
  const controller = new AbortController();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const [activeRow, setActiveRow] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [entid, setEntid] = useState<number>(0);
  const [etid, setEtid] = useState<number>(0);
  const [sn, setSN] = useState<string>('');
  const [eid, setEid] = useState<number>(0);
  const [totalRows] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [message, setMessage] = useState<IHelpdeskEquipmentResponse>();
  const [noResults, setNoResults] = useState<boolean>(true);
  const [searchTrigger, setSearchTrigger] = useState<boolean>(true);
  const [statusOperCode, setStatusOperCode] = useState<number | undefined>();
  const [menuOffset, setMenuOffset] = useState({ x: 0, y: 0 });
  const [selectedEid, setSelectedEid] = useState<number>(0);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);

  useEffect(() => {
    setSearchTrigger(true);
    setLoading(true);
    setOffset(0);
    getEquipmentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = () => {
    setSearchTrigger(true);
    setSearchParams(searchParams);
  };

  const getEquipmentList = () => {
    if (!loginInfo.token) {
      return;
    }

    if (loading) {
      setNoResults(false);
      controller.abort();
    }
    setLoading(true);

    fetchHelpdeskEquipmentList(offset, limit, totalRows, controller, entid, etid, sn, eid)
      .then(response => {
        if (response.statusOper.code === 0) {
          setMessage(response);
          setNoResults(false);
          setStatusOperCode(response.statusOper.code);
          setLoading(false);
        } else {
          setNoResults(true);
          setLoading(false);
          setStatusOperCode(response.statusOper.code);

          if (response.statusOper?.message) {
            setErrorMessage(response.statusOper.message);
          } else {
            setErrorMessage('An error occurred, but no specific error message is available.');
          }

          setShowErrorDialog(true);
        }
      })
      .catch(error => {
        if (error.name === 'AbortError') {
        } else {
          // Handle other errors
        }
      })
      .finally(() => setLoading(false));
  };

  const showEquipment = (selectedEid: number) => {
    setActiveRow(selectedEid);
    setOpenDetails(true);
  };

  const updateEntid = (newEntid: number, callback?: () => void) => {
    setEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  const updateTableEntid = (newEntid: number, callback?: () => void) => {
    updateEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  const updateEtid = (newEtid: number, callback?: () => void) => {
    setEtid(newEtid);
    if (callback) {
      callback();
    }
  };

  const updateTableEtid = (newEtid: number, callback?: () => void) => {
    updateEtid(newEtid);
    if (callback) {
      callback();
    }
  };

  const updateSN = (newSN: string, callback?: () => void) => {
    setSN(newSN);
    if (callback) {
      callback();
    }
  };

  const updateTableSN = (newSN: string, callback?: () => void) => {
    updateSN(newSN);
    if (callback) {
      callback();
    }
  };

  const updateEid = (newEid: number, callback?: () => void) => {
    setEid(newEid);
    if (callback) {
      callback();
    }
  };

  const updateTableEid = (newEid: number, callback?: () => void) => {
    updateEid(newEid);
    if (callback) {
      callback();
    }
  };

  useEffect(() => {
    setOffset(0);
    getEquipmentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, limit]);

  const changePage = (n: number) => {
    const newOffset = (n - 1) * limit;
    setCurrentPage(n);
    setOffset(newOffset);
    setSearchTrigger(true);
  };

  const resultsSelect = (newValue: number) => {
    const newLimit = Number(newValue);
    setLimit(newLimit);
    setCurrentPage(1);
    setOffset(0);
    setSearchTrigger(true);
  };

  const detectContractState = (agreeState: number | undefined) => {
    switch (agreeState) {
      case 0:
        return lang?.AGREE_STATE_0;
      case 1:
        return lang?.AGREE_STATE_1;
      case 2:
        return lang?.AGREE_STATE_2;
      case 3:
        return lang?.AGREE_STATE_3;
      case 4:
        return lang?.AGREE_STATE_4;
      default:
        return '';
    }
  };

  const getXLSReport = async () => {
    try {
      const response = await fetchExistencesMSReport(controller, entid, etid, sn, eid);
      if (response?.data.statusOper.code === 0) {
        setStatusOperCode(response?.data.statusOper.code);
      } else {
        setNoResults(true);
        setErrorMessage(response?.data.statusOper.message || 'An error occurred, but no specific error message is available.');
        setShowErrorDialog(true);
      }
    } catch (error) {
      setShowErrorDialog(true);
    } 
  };

  return (
    <Container fluid classNames={{ root: 'helpdesk-equipment' }}>
      {openDetails && activeRow && lang && (
        <ShowEquipment eid={activeRow} openDetails={openDetails} hideBtn={() => setOpenDetails(false)} lang={lang} />
      )}

      <Header pageTitle={lang?.GLOBAL_EQUIPMENT} />

      <HelpdeskFilterBar
        onSearch={onSearch}
        tableLoaded={getEquipmentList}
        filterByEntid={updateTableEntid}
        filterByEtid={updateTableEtid}
        filterBySN={updateTableSN}
        filterByEid={updateTableEid}
        XLSReport={getXLSReport}
      />

      <Table.ScrollContainer minWidth={500}>
        <Table striped highlightOnHover verticalSpacing="sm">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>{lang?.EQUIPMENT_ID}</Table.Th>
              <Table.Th>{lang?.GLOBAL_TYPE}</Table.Th>
              <Table.Th>SN</Table.Th>
              <Table.Th>{lang?.GLOBAL_MAINTENANCE}</Table.Th>
              <Table.Th>{lang?.GLOBAL_AGENT}</Table.Th>
              <Table.Th>{lang?.GLOBAL_ENTITY}</Table.Th>
              <Table.Th>{lang?.USER_DELEGATION}</Table.Th>
              <Table.Th>{lang?.EQUIPMENT_COM_STATE}</Table.Th>
              <Table.Th>{lang?.EQUIPMENT_SOFTWARE_VERSION_MIN}</Table.Th>
              <Table.Th>{lang?.GLOBAL_CONTRACT}</Table.Th>
              <Table.Th>{lang?.GLOBAL_LICENSE}</Table.Th>
            </Table.Tr>
          </Table.Thead>

          <Table.Tbody>
            {loading && (!message || searchTrigger) ? (
              <Table.Tr>
                <Table.Td colSpan={16}>
                  <Loader color="#016273" />
                </Table.Td>
              </Table.Tr>
            ) : (
              <>
                {noResults ? (
                  <Table.Tr>
                    <Table.Td colSpan={16} style={{ textAlign: 'center' }}>
                      {lang?.GLOBAL_NO_RESULTS}
                    </Table.Td>
                  </Table.Tr>
                ) : (
                  message?.list?.map((equipmentDetails, index) => (
                    <Menu
                      key={index}
                      radius={15}
                      offset={{ crossAxis: menuOffset.x, mainAxis: menuOffset.y }}
                      width={200}
                      shadow="md"
                      styles={{
                        dropdown: {
                          left: menuOffset.x,
                          top: menuOffset.y
                        }
                      }}
                    >
                      <Menu.Target key={index}>
                        <Table.Tr
                          key={index}
                          style={{ cursor: 'pointer' }}
                          onClick={e => {
                            const { pageX, pageY } = e;
                            setMenuOffset({ x: pageX, y: pageY });
                            setSelectedEid(equipmentDetails.eid);
                          }}
                        >
                          <Table.Td>{equipmentDetails.eid}</Table.Td>
                          <Table.Td>
                            {''}
                            {equipmentDetails.type && equipmentDetails.productCode
                              ? `${equipmentDetails.type} / ${equipmentDetails.productCode}`
                              : equipmentDetails.type || ''}
                          </Table.Td>
                          <Table.Td>{equipmentDetails.sn}</Table.Td>
                          <Table.Td>
                            {equipmentDetails.maintEntity
                              ? `${equipmentDetails.maintEntity.name ?? ''} #${equipmentDetails.maintEntity.entid ?? ''}`
                              : ''}
                          </Table.Td>
                          <Table.Td>
                            {equipmentDetails.maintEntity2
                              ? `${equipmentDetails.maintEntity2.name ?? ''} #${equipmentDetails.maintEntity2.entid ?? ''}`
                              : ''}
                          </Table.Td>
                          <Table.Td>
                            {equipmentDetails.entity
                              ? `${equipmentDetails.entity.name ?? ''} #${equipmentDetails.entity.entid ?? ''}`
                              : ''}
                          </Table.Td>
                          <Table.Td>
                            {''}
                            {equipmentDetails.sid && equipmentDetails.sidDescription
                              ? `${equipmentDetails.sid} - ${equipmentDetails.sidDescription}`
                              : equipmentDetails.sidDescription || ''}
                          </Table.Td>{' '}
                          <Table.Td
                            className={
                              equipmentDetails.status === 'OFFLINE' || equipmentDetails.status === 'DEFINED'
                                ? 'offline-text'
                                : 'online-text'
                            }
                          >
                            {equipmentDetails.status}
                          </Table.Td>
                          <Table.Td>{equipmentDetails.softwareVersion}</Table.Td>
                          <Table.Td
                            className={equipmentDetails.agreeState === 1 ? 'valid-agree-state' : 'invalid-agree-state'}
                          >
                            {detectContractState(equipmentDetails.agreeState)}
                          </Table.Td>
                          <Table.Td
                            className={
                              moment(equipmentDetails?.licenceExp).isBefore(moment())
                                ? 'expired-license'
                                : 'valid-license'
                            }
                          >
                            {equipmentDetails?.licenceExp
                              ? moment(equipmentDetails?.licenceExp).format('YYYY/MM/DD')
                              : ''}
                          </Table.Td>
                        </Table.Tr>
                      </Menu.Target>
                      <Menu.Dropdown onClick={() => {}}>
                        <React.Fragment>
                          <Menu.Item
                            onClick={() => {
                              showEquipment(selectedEid);
                            }}
                          >
                            {lang?.EQUIPMENT_DETAILS_TITLE}
                          </Menu.Item>
                          <Menu.Item disabled>{lang?.GLOBAL_EVENT}</Menu.Item>
                          <Menu.Item
                            onClick={() => {
                              navigate(`/helpdesk/equipment/registation/${selectedEid}`);
                            }}
                          >
                            {lang?.EQUIPMENT_REGISTER}
                          </Menu.Item>

                          <Menu.Item
                            onClick={() => {
                              navigate(`/helpdesk/entities/read/${equipmentDetails.entity?.entid}`);
                            }}
                          >
                            {lang?.GLOBAL_ENTITY}
                          </Menu.Item>
                          <Menu.Item disabled>{lang?.GLOBAL_EQUIPMENT}</Menu.Item>
                          <Menu.Item disabled>{lang?.GLOBAL_STATISTICS}</Menu.Item>
                          <Menu.Item disabled>{lang?.EQUIPMENT_CONTRACT}</Menu.Item>
                          <Menu.Item disabled>{lang?.EQUIPMENT_CREATE_TICKET}</Menu.Item>
                        </React.Fragment>
                      </Menu.Dropdown>
                    </Menu>
                  ))
                )}
              </>
            )}
          </Table.Tbody>
        </Table>
      </Table.ScrollContainer>

      <Group>
        <PaginationComponent
          total={message?.totalRows}
          statusOperCode={statusOperCode}
          itemsPerPage={limit}
          onPageChange={changePage}
          onResultsChange={resultsSelect}
          initialPage={currentPage}
        />
      </Group>

       <CustomModal
        opened={showErrorDialog}
        onClose={() => setShowErrorDialog(false)}
        message={errorMessage}
        backgroundColor="#E3353F"
        textColor='white'
      />
    </Container>
  );
};

export default HelpdeskEquipment;
