import { ApiResponse, zps_ea_war } from '../zps';
import axios from 'axios';

export interface PermissionOption {
  appId: number;
  mask: number;
  langKey: string; 
  selected?: boolean; 
}

export interface PermissionOptionResponse extends ApiResponse {
  list: PermissionOption[];
}

export interface Permission {
  appId: number;
  permissionCode: number;
}

export interface IUser {
  gid?: number;
  entid: number;
  uid: number;
  extentid?: number;
  type?: number;
  name?: string;
  permissionCode?: number;
  printType?: number;
  reportType?: number;
  inUse?: number;
  region?: string;
  lang: number;
  permissions?: Permission[];
  email?: string;
  mobile?: string;
  card?: string;
  password?: string;
  pin?: string;
}

export interface IUserUpdate {
  user: IUser;
  currentPassword?: string;
  deletePin?: boolean;
}

export interface IUserResponse {
  statusOper: ApiResponse;
  totalRows: number;
  type: string;
  users: IUser[];
}

export const fetchUser = async (entid: number, uid: number) => {
  const { data } = await axios.get(`${zps_ea_war}/user/?entid=${entid}&uid=${uid}`);
  return data;
};

export const fetchUsers = async (
  controller: AbortController,
  entid: number,
  limit: number,
  offset: number,
  totalRows: boolean,
  name?: string,
  uid?: number,
  sid?: number
) => {
  const { data } = await axios.get(
    `${zps_ea_war}/user/?entid=${entid}&offset=${offset}&limit=${limit}&totalRows=${totalRows}
    ${sid ? '&sid=' + sid : ''}
    ${name ? '&name=' + name : ''}
    ${uid ? '&uid=' + uid : ''}`,
    {
      signal: controller.signal
    }
  );
  return data;
};

export const createUser = async (user: IUser) => {
  const response = await axios.post(`${zps_ea_war}/user/`, user);
  return response.data;
};

export const deleteUser = async (uid: number) => {
  const response = await axios.delete(`${zps_ea_war}/user/?entid=9999&uid=${uid}`);
  return response.data;
};

export const updateUser = async (user: IUserUpdate) => {
  const response = await axios.put(`${zps_ea_war}/user`, user);
  return response.data;
};

export const fetchPermissions = async () => {
  const response = await axios.get(`${zps_ea_war}/user/permission/options`);
  return response.data;
};
