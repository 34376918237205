import { ActionTypes } from './actionTypes';
import { AppDispatch } from '../store';
import { createAction } from '../actions';
import { ICurrency } from '../../../libraries/currencies';

export const saveCurrencyInfo = (currencyInfo: ICurrency[]) => (dispatch: AppDispatch) => {
  dispatch(createAction(ActionTypes.SAVE_CURRENCIES, currencyInfo));
};


export const getCurrency = (currencies: ICurrency[], cid: number) : ICurrency | undefined => {
  if(!currencies){
    return undefined;
  }
  return currencies.find( elem => elem.cid === cid);
};
