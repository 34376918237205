import { AppAction } from '../actions';
import { ActionTypes } from './actionTypes';
import { getLanguageHandler, Language } from '../../languages/languageHandler';


export interface LangState {
  lang: Language;
  langSettings?: any[];
}

const initialState: LangState = { lang: getLanguageHandler() };

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action: AppAction<ActionTypes>): LangState => {
  switch (action.type) {
    case ActionTypes.LANG_UPDATE: {
      const lang = action?.payload;
      return {
        ...state,
        lang: getLanguageHandler(lang)
      };
    }
    case ActionTypes.LANG_SETTINGS:
      return { 
        ...state, 
        langSettings: action?.payload 
      };
    case ActionTypes.LANG_CLEAR:
      return { 
        ...state, 
        lang:getLanguageHandler() 
    };
    default:
      return state;
  }
};
