import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Login } from './app/pages/login';
import Equipment from './app/pages/equipment';
import Transactions from './app/pages/transactions';
import Users from './app/pages/users';
import Profile from './app/pages/profile';
import { useSelector } from 'react-redux';
import { AppState } from './app/client-redux/reducers';
import { WithNavBar } from './app/components/nav-bar/with-nav-bar';
import EnterpriseEquipPeriods from './app/pages/enterprise-equip-periods';
import BankTransactions from './app/pages/bank-transactions';
import Dashboard from './app/pages/dashboard';
import APMTransactions from './app/pages/apm/transactions';
import APMPeriods from './app/pages/apm/periods';
import APMEquipments from './app/pages/apm/equipments';
import HelpdeskEquipment from './app/pages/helpdesk/equipments';
import RegisterEquipmentHelpdesk from './app/pages/helpdesk/equipments/register-equipment';
import HelpdeskEntities from './app/pages/helpdesk/entities';
import ReasonsDeclarations from './app/pages/edit-reasons-declarations';
import EquipmentConfigForm from './app/pages/equipment-config';
import HelpdeskEntity from './app/pages/helpdesk/entity';
import './app.scss';

const App = () => {
  const token = useSelector((state: AppState) => state.login.token);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  });

  const protectedRoute = (element: JSX.Element) => {
    return token ? element : <Navigate to="/" />;
  };

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/dashboard"
            element={protectedRoute(
              <WithNavBar>
                <Dashboard />
              </WithNavBar>
            )}
          />
          <Route
            path="/equipment"
            element={protectedRoute(
              <WithNavBar>
                <Equipment />
              </WithNavBar>
            )}
          />
          <Route
            path="/transactions"
            element={protectedRoute(
              <WithNavBar>
                <Transactions />
              </WithNavBar>
            )}
          />
          <Route
            path="/users"
            element={protectedRoute(
              <WithNavBar>
                <Users />
              </WithNavBar>
            )}
          />
          <Route
            path="/enterpriseEquiPeriods"
            element={protectedRoute(
              <WithNavBar>
                <EnterpriseEquipPeriods />
              </WithNavBar>
            )}
          />
          <Route
            path="/bankTransactions"
            element={protectedRoute(
              <WithNavBar>
                <BankTransactions />
              </WithNavBar>
            )}
          />

          <Route
            path="/apm/trasactions"
            element={protectedRoute(
              <WithNavBar>
                <APMTransactions />
              </WithNavBar>
            )}
          />

          <Route
            path="/apm/periods"
            element={protectedRoute(
              <WithNavBar>
                <APMPeriods />
              </WithNavBar>
            )}
          />

          <Route
            path="/apm/equipments"
            element={protectedRoute(
              <WithNavBar>
                <APMEquipments />
              </WithNavBar>
            )}
          />

          <Route
            path="/helpdesk/equipments"
            element={protectedRoute(
              <WithNavBar>
                <HelpdeskEquipment />
              </WithNavBar>
            )}
          />

          <Route
            path="/helpdesk/equipment/registation/:eid"
            element={protectedRoute(
              <WithNavBar>
                <RegisterEquipmentHelpdesk />
              </WithNavBar>
            )}
          />

          <Route
            path="/helpdesk/entities"
            element={protectedRoute(
              <WithNavBar>
                <HelpdeskEntities />
              </WithNavBar>
            )}
          />

          <Route
            path="/helpdesk/entities/new/0"
            element={protectedRoute(
              <WithNavBar>
                <HelpdeskEntity />
              </WithNavBar>
            )}
          />

          <Route
            path="/helpdesk/entities/read/:entid"
            element={protectedRoute(
              <WithNavBar>
                <HelpdeskEntity />
              </WithNavBar>
            )}
          />

          <Route
            path="/helpdesk/entities/edit/:entid"
            element={protectedRoute(
              <WithNavBar>
                <HelpdeskEntity />
              </WithNavBar>
            )}
          />

          <Route
            path="/equipmentConfig/:eid"
            element={protectedRoute(
              <WithNavBar>
                <EquipmentConfigForm />
              </WithNavBar>
            )}
          />

          <Route
            path="reasonsDeclarations"
            element={protectedRoute(
              <WithNavBar>
                <ReasonsDeclarations />
              </WithNavBar>
            )}
          />

          <Route
            path="/profile"
            element={protectedRoute(
              <WithNavBar>
                <Profile />
              </WithNavBar>
            )}
          />

          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Login />} />
        </Routes>
      </BrowserRouter>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
};

export default App;
