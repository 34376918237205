import { useState } from 'react';
import { Button, Group, Modal, Text } from '@mantine/core';
import { Language } from '../../languages/languageHandler';
import { deleteEquipmentDeclaration, deleteEquipmentReason } from '../../../libraries/equipment';
import { useSelector } from 'react-redux';
import { LoginState } from '../../client-redux/login/reducer';
import { AppState } from '../../client-redux/reducers';
import CustomModal from '../../components/custom-modal';
import * as Icon from '@tabler/icons-react';

interface DeleteEquipmentReasonDeclarationProps {
  language: number;
  codeReasonDeclaration: number;
  text: string;
  type: 'reason' | 'declaration';
  onReload: () => void;
}

const DeleteEquipmentReasonDeclaration = ({
  language,
  codeReasonDeclaration,
  type,
  onReload
}: DeleteEquipmentReasonDeclarationProps) => {
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const loginInfo = useSelector<AppState, LoginState>(state => state.login);
  const controller = new AbortController();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const [entid] = useState<number>(loginInfo.user?.entid);
  const [code] = useState<number>(codeReasonDeclaration);
  const [langid] = useState<number>(language);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    controller.abort();
  };

  const deleteConfirmationText = type === 'reason' ? lang?.REMOVE_REASON : lang?.REMOVE_DECLARATION;

  const handleDelete = async () => {
    setLoading(true);
    try {
      if (type === 'reason') {
        await deleteEquipmentReason(entid, code, langid);
        setSuccessMessage(lang?.REMOVE_REASON_SUCCESS || '');
      } else if (type === 'declaration') {
        await deleteEquipmentDeclaration(entid, code, langid);
        setSuccessMessage(lang?.REMOVE_DECLARATION_SUCCESS || '');
      }
      setShowSuccessDialog(true);
    } catch (error) {
      if (type === 'reason') {
        setErrorMessage(lang?.REMOVE_REASON_ERROR || '');
      } else if (type === 'declaration') {
        setErrorMessage(lang?.REMOVE_DECLARATION_ERROR || '');
      }
      setShowErrorDialog(true);
    }
    onReload();
  };

  return (
    <>
      <Icon.IconX stroke={2} onClick={openModal} />
      <Modal opened={showModal} onClose={closeModal} centered withCloseButton={false}>
        <Text>{deleteConfirmationText}</Text>
        <Group style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
          <Button onClick={handleDelete} loading={loading}>
            {lang?.YES}
          </Button>
          <Button onClick={() => setShowModal(false)} style={{ marginRight: '0.5rem' }}>
            {lang?.NO}
          </Button>
        </Group>
      </Modal>

      <CustomModal
        opened={showSuccessDialog}
        onClose={() => setShowSuccessDialog(false)}
        message={successMessage}
        backgroundColor="#3BDF70"
        textColor="black"
      />

      <CustomModal
        opened={showErrorDialog}
        onClose={() => setShowErrorDialog(false)}
        message={errorMessage}
        backgroundColor="#E3353F"
        textColor="white"
      />
    </>
  );
};

export default DeleteEquipmentReasonDeclaration;
