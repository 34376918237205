import { useNavigate } from 'react-router-dom';
import { Group, Button, TextInput, Select } from '@mantine/core';
import { useSelector } from 'react-redux';
import { AppState } from '../../client-redux/reducers';
import { useState } from 'react';
import { Language } from '../../languages/languageHandler';
import * as Icon from '@tabler/icons-react';
import './styles.scss';
import { IShop } from '../../../libraries/shop';

const HelpdeskFilterBar = ({
  onSearch,
  tableLoaded,
  filterByEntid,
  filterBySid,
  filterByEtid,
  filterBySN,
  filterByEid,
  filterByName,
  filterByNIF,
  filterByType,
  XLSReport,
  newEntity
}: {
  onSearch: () => void;
  tableLoaded: () => void;
  filterByEntid: (newEntId: number, callback?: () => void) => void;
  filterBySid?: (newSid: number, callback?: () => void) => void;
  filterByEtid?: (newEtid: number, callback?: () => void) => void;
  filterBySN?: (newSN: string, callback?: () => void) => void;
  filterByEid?: (newEid: number, callback?: () => void) => void;
  filterByName?: (newName: string, callback?: () => void) => void;
  filterByNIF?: (newNIF: number, callback?: () => void) => void;
  filterByType?: (newType: string, callback?: () => void) => void;
  XLSReport?: () => void;
  newEntity?: () => void;
}) => {
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const navigate = useNavigate();
  const shops = useSelector<AppState, IShop[]>(state => state.shops.shops);
  const [entid, setEntid] = useState<number>();
  const [sid, setSid] = useState<number>(0);
  const [, setEid] = useState<number>();
  const [, setEtid] = useState<number>();
  const [, setType] = useState<string>('');
  const [, setSn] = useState<string>('');
  const [, setName] = useState<string>('');
  const [, setNIF] = useState<number>();
  const [, setCurrentPage] = useState(1);

  const entityTypes = [
    { value: '', label: lang?.ENTITY_TYPE_NULL },
    { value: '0', label: lang?.ENTITY_TYPE_0 },
    { value: '1', label: lang?.ENTITY_TYPE_1 },
    { value: '2', label: lang?.ENTITY_TYPE_2 },
    { value: '3', label: lang?.ENTITY_TYPE_3 }
  ];

  const handleSearch = () => {
    tableLoaded();
    onSearch();
    setCurrentPage(1);
  };

  /* Entity handle*/
  const handleEntid = (value: number) => {
    if (value !== null) {
      const selectedEntid = Number(value);
      filterByEntid(selectedEntid);
      setEntid(selectedEntid);
    }
  };

  /* Delegation handle */
  const handleNewTableBySid = (value: number | 0) => {
    if (value !== null) {
      const selectedSid = Number(value);
      filterBySid?.(selectedSid);
      setSid(selectedSid);
    }
  };

  /* Etid handle*/
  const handleEtid = (value: number) => {
    if (value !== null) {
      const selectedEtid = Number(value);
      filterByEtid?.(selectedEtid);
      setEtid(selectedEtid);
    }
  };

  /* Type handle*/
  const handleType = (value: string) => {
    if (value !== null) {
      const selectedType = String(value);
      filterByType?.(selectedType);
      setType(selectedType);
    }
  };

  /* SN handle*/
  const handleSN = (value: string) => {
    if (value !== null && filterBySN) {
      const selectedSN = String(value);
      filterBySN?.(selectedSN);
      setSn(selectedSN!);
    }
  };

  /* EID handle*/
  const handleEID = (value: number) => {
    if (value !== null) {
      const selectedEid = Number(value);
      filterByEid?.(selectedEid, () => {});
      setEid(selectedEid);
    }
  };

  /* Name handle*/
  const handlename = (value: string) => {
    if (value !== null) {
      const selectedName = String(value);
      filterByName?.(selectedName, () => {});
      setName(selectedName);
    }
  };

  /* NIF handle*/
  const handleNIF = (value: number) => {
    if (value !== null) {
      const selectedNIF = Number(value);
      filterByNIF?.(selectedNIF, () => {});
      setNIF(selectedNIF);
    }
  };

  return (
    <Group classNames={{ root: 'helpdesk-filterBar' }}>
      {/* Entid */}
      <TextInput label={lang?.GLOBAL_ENTITY} onChange={event => handleEntid(Number(event.target.value))} />

      {filterBySid && (
        /* Delegation */
        <Select
          withCheckIcon={true}
          label={lang?.USER_DELEGATION}
          data={[
            { value: '0', label: lang?.GLOBAL_ALL },
            ...(shops
              ? shops
                  .filter((elem: IShop) => elem.entid === entid)
                  .map(shop => ({ value: shop.sid.toString(), label: shop.name }))
              : [])
          ]}
          value={sid.toString()}
          onChange={value => handleNewTableBySid(Number(value))}
        />
      )}

      {/* Etid */}
      {filterByEtid && (
        <TextInput label={lang?.GLOBAL_TYPE} onChange={event => handleEtid(Number(event.target.value))} />
      )}

      {/* Serial Number */}
      {filterBySN && <TextInput label={'SN'} onChange={event => handleSN(String(event.target.value))} />}

      {/* EID */}
      {filterByEid && <TextInput label={'EID'} onChange={event => handleEID(Number(event.target.value))} />}

      {/* Name */}
      {filterByName && (
        <TextInput label={lang?.GLOBAL_NAME} onChange={event => handlename(String(event.target.value))} />
      )}

      {/* NIF */}
      {filterByNIF && <TextInput label={'NIF'} onChange={event => handleNIF(Number(event.target.value))} />}

      {/* Type */}
      {filterByType && (
        <Select
          label={lang?.GLOBAL_TYPE}
          data={entityTypes}
          placeholder={lang?.GLOBAL_ALL}
          onChange={value => handleType(String(value))}
        />
      )}

      {/* Search */}
      <Button color="#016273" radius="md" className="searchButton" onClick={handleSearch}>
        {lang?.GLOBAL_SEARCH}
      </Button>

      {/* Excel */}
      {XLSReport && (
        <Button color="#016273" radius="md" className="XLSButton" onClick={XLSReport}>
          <Icon.IconFileTypeXls stroke={2} />
          <span>XLS</span>
        </Button>
      )}

      {/* Add Entity */}
      {newEntity && (
        <Button
          color="#016273"
          radius="md"
          onClick={() => {
            navigate(`/helpdesk/entities/new/0`);
          }}
        >
          <Icon.IconUsersPlus />
        </Button>
      )}
    </Group>
  );
};

export default HelpdeskFilterBar;
