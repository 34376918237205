import { Modal } from '@mantine/core';
import './styles.scss';

const ModalComponent = (props: any) => {
  return (
    <Modal 
      opened={props.show} 
      onClose={props.handleClick} 
      title={props.title} 
      size={props.size} 
      centered  
      classNames={{ root: props.className }}  
    >
      <Modal.Body>
        {props.content}
      </Modal.Body>
    </Modal>
  );
};

export default ModalComponent;
