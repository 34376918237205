import { ApiResponse, zps_ea_war } from '../zps';
import axios from 'axios';

export type IDenomination = {
  index: number;
  value: number;
  type: number;
  weight: number;
};

export type ICurrency = {
  EURExchangeRate: number;
  USDExchangeRate: number;
  cid: number;
  currencyStr: string;
  denominations: IDenomination[];
  description: string;
  factor: number;
  mc_value: number[];
  mc_valueType: number[];
  mc_valueWeight: number[];
  nBills: number;
  nCoins: number;
};

export interface ICurrencyResponse extends ApiResponse {
  currencies: ICurrency[];
}

export const fetchCurrencies = async () => {
  const response = await axios.get(`${zps_ea_war}/currency`);
  return response.data;
};
