import axios from 'axios';
import { ApiResponse, zps_ms_ea_war } from '../zps';
import { handleDownload } from '../../app/utils/script';
import { IEntityLabel } from '../equipment';

export type IHelpdeskEquipment = {
  agreeId?: number;
  agreeState?: number;
  eid: number;
  entity?: IEntityLabel;
  gid: number;
  initAccepted?: boolean;
  licenceExp?: string;
  maintEntity?: IEntityLabel;
  maintEntity2?: IEntityLabel;
  productCode?: string;
  sid?: number;
  sidDescription?: string;
  sn: string;
  softwareVersion?: string;
  status?: string;
  type: number;
};

export type IHelpdeskEntity = {
  description: string;
  entid: number;
  gid: number;
  lang: number;
  name: string;
  nif: number;
  ownerid: number;
  printType: number;
  region: string;
  reportType: number;
  type: number;
  usePos: number;
  useShifts: number;
};

export interface EntityRequests {
  activity: number;
  address: string;
  billingEntity: number;
  billingVolume: number;
  contact: string;
  description: string;
  email: string;
  entid: number;
  gid?: number;
  lang: number;
  logo: string;
  mobile: string;
  name: string;
  nif: number;
  ownerid: number;
  postalCode: string;
  printType: number;
  region: string;
  reportType: number;
  sibsMerchantCode: string;
  type: number;
  usePos: number;
  useShifts: number;
}

export interface IHelpdeskEquipmentResponse extends ApiResponse {
  list: IHelpdeskEquipment[];
  totalRows: number;
  type: string;
}

export interface IHelpdeskEntityResponse extends ApiResponse {
  entities: IHelpdeskEntity[];
  totalRows: number;
  type: string;
}

export interface IHelpdeskEntityRequestsResponse extends ApiResponse {
  entities: EntityRequests[];
  totalRows: number;
  type: string;
}

export interface EquipmentRegistrationRequest {
  eid: number;
  entid: number;
  maintid?: number;
  maintid2?: number;
  dateTimeExp: string;
shop: {sid: number, name: string}
}

export const fetchEntitiesActivity = async () => {
  const response = await axios.get(`${zps_ms_ea_war}/entity/activity`);
  return response.data;
};


export const fetchHelpdeskEquipmentList = async (
  offset: number,
  limit: number,
  totalRows: boolean,
  controller: AbortController,
  entid: number,
  etid?: number,
  sn?: string,
  eid?: number
) => {
  const { data } = await axios.get(
    `${zps_ms_ea_war}/equipment/?` +
    `${entid ? '&entid=' + entid : ''}` +
      `${etid ? '&etid=' + etid : ''}` +
      `${sn ? '&sn=' + sn : ''}` +
      `${eid ? '&eid=' + eid : ''}` +
      `&offset=${offset}&limit=${limit}&totalRows=${totalRows}`,
    {
      signal: controller.signal
    }
  );
  return data;
};

export const fetchHelpdeskEntitiesList = async (
  offset: number,
  limit: number,
  totalRows: boolean,
  controller: AbortController,
  type?: string,
  entid?: number,
  name?: string,
  nif?: number
) => {
  const {data} = await axios.get(
    `${zps_ms_ea_war}/entity/?` +
    `${type ? '&type=' + type : ''}` +
      `${entid ? '&entid=' + entid : ''}` +
      `${name ? '&name=' + name : ''}` +
      `${nif ? '&nif=' + nif : ''}` +
      `&offset=${offset}&limit=${limit}&totalRows=${totalRows}`,
    {
      signal: controller.signal
    }
  );
  return data;
};

export const fetchHelpdeskEquipInfo = async (eid: number, controller: AbortController) => {
  const { data } = await axios.get(`${zps_ms_ea_war}/equipment/?&eid=${eid}`, {
    signal: controller.signal
  });
  return data;
};

export  const updateHelpdeskEquipment = async (
  request: EquipmentRegistrationRequest,
  controller: AbortController
) => {
  const { data } = await axios.post(`${zps_ms_ea_war}/equipment/registration`, request, {
    signal: controller.signal
  });
  return data

};

export const fetchExistencesMSReport = async (
  controller: AbortController,  
  entid: number,
  etid?: number,
  sn?: string,
  eid?: number
) => {
  try{
    const response = await axios.get(`${zps_ms_ea_war}/equipment/report?format=xls` + 
    `${entid ? '&entid=' + entid : ''}` +
    `${etid ? '&etid=' + etid : ''}` +
    `${sn ? '&sn=' + sn : ''}` +
    `${eid ? '&eid=' + eid : ''}` ,
     {
      signal: controller.signal
    });
    handleDownload(response.data.report, response.data.name, response.data.mime);
    return response;
} catch (error) {
  console.error('Failed to fetch Report file:', error);
}
};;

export const fetchHelpdeskEntityInfo = async (entid: number, controller: AbortController) => {
  const { data } = await axios.get(`${zps_ms_ea_war}/entity/?&entid=${entid}`, {
    signal: controller.signal
  });
  return data;
};

 export  const createHelpdeskEntity = async (request: EntityRequests, controller: AbortController) => {
  const { data } = await axios.post(`${zps_ms_ea_war}/entity/`, request, {
    signal: controller.signal
  });
  return data
};

export const deleteHelpdeskEntity = async (entid: number, controller: AbortController) => {
  const { data } = await axios.delete(`${zps_ms_ea_war}/entity/?entid=${entid}`, {
    signal: controller.signal
  });
  return data;
};

export const updateHelpdeskEntity = async (request: EntityRequests, controller: AbortController) => {
  try {
    const { data } = await axios.put(`${zps_ms_ea_war}/entity/`, request, {
      signal: controller.signal,
    });
    return data;
  } catch (error) {
    throw error; 
  }
};
