import { useSelector } from 'react-redux';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import { Table, Loader, TextInput, Select } from '@mantine/core';
import { getCurrencyName, formatCurrAmount } from '../../utils/script';
import { ICurrency } from '../../../libraries/currencies';
import { useState, useEffect } from 'react';
import { IUser } from '../../../libraries/users';
import { useParams } from 'react-router-dom';
import { IEquipmentListResponse } from '../../../libraries/equipment';
import * as Icon from '@tabler/icons-react';

interface CurrencyTableProps {
  selectedCurrencies: {
    gid: number;
    eid: number;
    cid: number;
    minAmount: number;
    maxAmount: number;
  }[];
  currencyList: ICurrency[];
  loading: boolean;
  editable: boolean;
  onCellClick: (rowIndex: number, column: string, currentValue: number, matchingCurrency: ICurrency) => void;
  onCellChange: (e: React.ChangeEvent<HTMLInputElement>, rowIndex: number, column: string) => void;
  onCellBlur: () => void;
  filteredCid?: ICurrency[];
  onRemoveCurrency: (cid: number) => void;
  loginInfo: { gid: number | null };
  eid: string;
  form: any;
  editingCell: { rowIndex: number; column: string } | null;
  cellValue: number | string;
  message?: IEquipmentListResponse;
}

const CurrencyTable = ({
  selectedCurrencies,
  currencyList,
  loading,
  editable,
  onCellClick,
  onCellChange,
  onCellBlur,
  onRemoveCurrency,
  filteredCid,
  form,
  editingCell,
  cellValue,
}: CurrencyTableProps) => {
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const loginInfo = useSelector<AppState, IUser>(state => state.login.user);
  const { eid } = useParams<string>();

  const [updatedCurrencies, setUpdatedCurrencies] = useState<
    { gid: number; eid: number; cid: number; minAmount: number; maxAmount: number }[]
  >(selectedCurrencies);

  useEffect(() => {
    setUpdatedCurrencies(selectedCurrencies); 
  }, [selectedCurrencies]);

  /* Currencies array handler */
  const handleCurrencySelect = (currencyCid: string | null) => {
    if (currencyCid) {
      const selectedCurrency = filteredCid?.find(c => c.cid.toString() === currencyCid);
      if (selectedCurrency) {
        const newCurr = {
          gid: loginInfo.gid ?? 0,
          eid: Number(eid),
          cid: selectedCurrency.cid,
          minAmount: 100,
          maxAmount: 0
        };

        setUpdatedCurrencies(prevSelectedCurrencies => {
          if (prevSelectedCurrencies.some(curr => curr.cid === newCurr.cid)) {
            return prevSelectedCurrencies; 
          }
          const updatedCurrencies = [...prevSelectedCurrencies, newCurr];
          form.setFieldValue('currencies', updatedCurrencies);
          return updatedCurrencies;
        });
      }
    }
  };

  // Filter the filteredCid to exclude selected currencies
  const availableCurrencies = filteredCid?.filter(currency => 
    !updatedCurrencies.some(selected => selected.cid === currency.cid)
  ) || [];

  return (
    <>
      {/* Currencies Select */}
      <Select
        label={lang?.GLOBAL_CURRENCY}
        data={
          availableCurrencies.map(currency => ({
            value: currency.cid.toString(),
            label: getCurrencyName(currency.cid)
          })) || []
        }
        onChange={value => {
          handleCurrencySelect(value);
        }}
        disabled={!editable}
        value={''}
      />

      <Table.ScrollContainer minWidth={100}>
        <Table striped highlightOnHover verticalSpacing="md">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>{lang?.GLOBAL_NAME}</Table.Th>
              <Table.Th>{lang?.EQUIPMENT_STATE_LABEL_MIN_AMOUNT}</Table.Th>
              <Table.Th>{lang?.EQUIPMENT_STATE_LABEL_MAX_AMOUNT}</Table.Th>
              {editable && <Table.Th></Table.Th>}
            </Table.Tr>
          </Table.Thead>

          <Table.Tbody>
            {loading ? (
              <Table.Tr>
                <Table.Td colSpan={4}>
                  <Loader color="#016273" />
                </Table.Td>
              </Table.Tr>
            ) : updatedCurrencies.length === 0 ? (
              <Table.Tr>
                <Table.Td colSpan={4} style={{ textAlign: 'center' }}>
                  {lang?.GLOBAL_NO_RESULTS}
                </Table.Td>
              </Table.Tr>
            ) : (
              updatedCurrencies.map((currency, rowIndex) => {
                const matchingCurrency = currencyList.find(c => c.cid === currency.cid);
                return (
                  <Table.Tr key={currency.cid}>
                    <Table.Td>{getCurrencyName(currency.cid)}</Table.Td>

                    {/* Min Amount */}
                    <Table.Td
                      onClick={() => onCellClick(rowIndex, 'minAmount', currency.minAmount, matchingCurrency!)}
                      style={{ cursor: 'pointer' }}
                    >
                      {editingCell?.rowIndex === rowIndex && editingCell.column === 'minAmount' ? (
                        <TextInput
                          value={cellValue}
                          onChange={e => onCellChange(e, rowIndex, 'minAmount')}
                          onBlur={onCellBlur}
                          autoFocus
                        />
                      ) : matchingCurrency ? (
                        formatCurrAmount(currency.minAmount, matchingCurrency)
                      ) : (
                        '-'
                      )}
                    </Table.Td>

                    {/* Max Amount */}
                    <Table.Td
                      onClick={() => onCellClick(rowIndex, 'maxAmount', currency.maxAmount, matchingCurrency!)}
                      style={{ cursor: 'pointer' }}
                    >
                      {editingCell?.rowIndex === rowIndex && editingCell.column === 'maxAmount' ? (
                        <TextInput
                          value={cellValue}
                          onChange={e => onCellChange(e, rowIndex, 'maxAmount')}
                          onBlur={onCellBlur}
                          autoFocus
                        />
                      ) : matchingCurrency ? (
                        formatCurrAmount(currency.maxAmount, matchingCurrency)
                      ) : (
                        '-'
                      )}
                    </Table.Td>

                    {editable && (
                      <Table.Td>
                        <Icon.IconX stroke={2} onClick={() => onRemoveCurrency(currency.cid)} />
                      </Table.Td>
                    )}
                  </Table.Tr>
                );
              })
            )}
          </Table.Tbody>
        </Table>
      </Table.ScrollContainer>
    </>
  );
};

export default CurrencyTable;
