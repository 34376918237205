import { useEffect, useState } from 'react';
import { MeansCash } from '../../libraries/transactions';
import { ICurrency } from '../../libraries/currencies';
import { Language } from '../languages/languageHandler';
import { IEntity } from '../../libraries/entity';
import store from '../client-redux/store';

 export const formatCurrAmount = (amount: number  , currency: ICurrency, showDecimal: boolean = true, showSymbol: boolean = true) => {
  //format "0.00 SIMBOL"  
let decimalAmount = amount * currency.factor;
let formattedAmount = showDecimal ? decimalAmount.toFixed(2) : decimalAmount.toFixed(0);
//let formattedAmount = decimalAmount.toFixed(2);
//TODO: replace currencyStr for the currency symbol when on the database
return showSymbol ? `${formattedAmount} ${currency.currencyStr}` : formattedAmount;
}; 

 export const getCurrencyName = (cid?: number): string => {
  let currencySettings = store.getState().currencies.currencies;
  let found;
  if (currencySettings) {
    found = currencySettings.find(element => element.cid === cid);
  }
  return found ? found.currencyStr :  `currencies_${cid}`;
}; 

export const getEntityName = (entid?: number): string => {
  let entitySettings = store.getState().entities.entities;
  let found;
  if (entitySettings && entid !== undefined) {
    found = entitySettings.find(element => element.entid === entid);
  }
  return found ? found.name : '';
};

export const getShopName = (sid?: number): string => {
  const shopSettings = store.getState().shops.shops;
  let found;
  if (shopSettings && sid !== undefined) {
    found = shopSettings.find(element => element.sid === sid);
  }
  return found ? found.name : '';
};

interface WindowSize {
  width: number;
  height: number;
}

export function useWindowSize(): WindowSize {
  const [size, setSize] = useState<WindowSize>({
    width: 0,
    height: 0
  });

  useEffect(() => {
    function updateSize() {
      setSize({
        width: window.screen.width,
        height: window.screen.height
      });
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
}

export const prepareQuantities = (cash: MeansCash[], currency: ICurrency) => {
  if (!currency) return;
  if (!cash) return;

  let quantities: {
    quantity: number;
    amount: any;
  }[] = [];

  for (let i = 0; i < currency.nCoins + currency.nBills; i++) {
    quantities.push({
      quantity: 0,
      amount: 0
    });
  }

  if (cash) {
    cash.forEach(cash => {
      quantities[cash.index].quantity = cash.quantity;
      quantities[cash.index].amount = cash.quantity * currency.denominations[cash.index].value * currency.factor;
      if (quantities[cash.index].amount % 1 !== 0) {
        quantities[cash.index].amount = quantities[cash.index].amount.toFixed(2);
      }
    });
  }

  return quantities;
};

type iTransactionType = {
  [key: string]: { label: string; label_min: string };
  [key: number]: { label: string; label_min: string };
};

const _ztransactionType: iTransactionType = {
  '01010101': {
    label: 'OP_01010101_LABEL',
    label_min: 'OP_01010101_LABEL_MIN'
  },
  '01010122': {
    label: 'OP_01010122_LABEL',
    label_min: 'OP_01010122_LABEL_MIN'
  },
  '01010123': {
    label: 'OP_01010123_LABEL',
    label_min: 'OP_01010123_LABEL_MIN'
  },
  '01010125': {
    label: 'OP_01010125_LABEL',
    label_min: 'OP_01010125_LABEL_MIN'
  },
  '01010128': {
    label: 'OP_01010128_LABEL',
    label_min: 'OP_01010128_LABEL_MIN'
  },
  '01010301': {
    label: 'OP_01010301_LABEL',
    label_min: 'OP_01010301_LABEL_MIN'
  },
  '01010322': {
    label: 'OP_01010322_LABEL',
    label_min: 'OP_01010322_LABEL_MIN'
  },
  '01010328': {
    label: 'OP_01010328_LABEL',
    label_min: 'OP_01010328_LABEL_MIN'
  },
  '01010334': {
    label: 'OP_01010334_LABEL',
    label_min: 'OP_01010334_LABEL_MIN'
  },
  '01010401': {
    label: 'OP_01010401_LABEL',
    label_min: 'OP_01010401_LABEL_MIN'
  },
  '01011101': {
    label: 'OP_01011101_LABEL',
    label_min: 'OP_01011101_LABEL_MIN'
  },
  '01011122': {
    label: 'OP_01011122_LABEL',
    label_min: 'OP_01011122_LABEL_MIN'
  },
  '01011123': {
    label: 'OP_01011123_LABEL',
    label_min: 'OP_01011123_LABEL_MIN'
  },
  '01011128': {
    label: 'OP_01011128_LABEL',
    label_min: 'OP_01011128_LABEL_MIN'
  },
  '01011201': {
    label: 'OP_01011201_LABEL',
    label_min: 'OP_01011201_LABEL_MIN'
  },
  '01011222': {
    label: 'OP_01011222_LABEL',
    label_min: 'OP_01011222_LABEL_MIN'
  },
  '01011223': {
    label: 'OP_01011223_LABEL',
    label_min: 'OP_01011223_LABEL_MIN'
  },
  '01011228': {
    label: 'OP_01011228_LABEL',
    label_min: 'OP_01011228_LABEL_MIN'
  },
  '01010201': {
    label: 'OP_01010201_LABEL',
    label_min: 'OP_01010201_LABEL_MIN'
  },
  '01010202': {
    label: 'OP_01010202_LABEL',
    label_min: 'OP_01010202_LABEL_MIN'
  },
  '01010222': {
    label: 'OP_01010222_LABEL',
    label_min: 'OP_01010222_LABEL_MIN'
  },
  '01010224': {
    label: 'OP_01010224_LABEL',
    label_min: 'OP_01010224_LABEL_MIN'
  },
  '01010225': {
    label: 'OP_01010225_LABEL',
    label_min: 'OP_01010225_LABEL_MIN'
  },
  '01010226': {
    label: 'OP_01010226_LABEL',
    label_min: 'OP_01010226_LABEL_MIN'
  },
  '01010227': {
    label: 'OP_01010227_LABEL',
    label_min: 'OP_01010227_LABEL_MIN'
  },
  '01010228': {
    label: 'OP_01010228_LABEL',
    label_min: 'OP_01010228_LABEL_MIN'
  },
  '01010229': {
    label: 'OP_01010229_LABEL',
    label_min: 'OP_01010229_LABEL_MIN'
  },
  '01010230': {
    label: 'OP_01010230_LABEL',
    label_min: 'OP_01010230_LABEL_MIN'
  },
  '01010231': {
    label: 'OP_01010231_LABEL',
    label_min: 'OP_01010231_LABEL_MIN'
  },
  '01010232': {
    label: 'OP_01010232_LABEL',
    label_min: 'OP_01010232_LABEL_MIN'
  },
  '01010233': {
    label: 'OP_01010233_LABEL',
    label_min: 'OP_01010233_LABEL_MIN'
  },
  '01010901': {
    label: 'OP_01010901_LABEL',
    label_min: 'OP_01010901_LABEL_MIN'
  },
  '01011001': {
    label: 'OP_01011001_LABEL',
    label_min: 'OP_01011001_LABEL_MIN'
  },
  '01011301': {
    label: 'OP_01011301_LABEL',
    label_min: 'OP_01011301_LABEL_MIN'
  },
  '01011322': {
    //canceled change
    label: 'OP_01011325_LABEL',
    label_min: 'OP_01011325_LABEL_MIN'
  },
  '01011328': {
    //canceled change
    label: 'OP_01011325_LABEL',
    label_min: 'OP_01011325_LABEL_MIN'
  },
  '01011325': {
    //canceled change
    label: 'OP_01011325_LABEL',
    label_min: 'OP_01011325_LABEL_MIN'
  },
  '01011334': {
    label: 'OP_01011334_LABEL',
    label_min: 'OP_01011334_LABEL_MIN'
  },
  '02120503': {
    label: 'OP_02120503_LABEL',
    label_min: 'OP_02120503_LABEL_MIN'
  },
  '02120506': {
    label: 'OP_02120506_LABEL',
    label_min: 'OP_02120506_LABEL_MIN'
  },
  '01010223': {
    label: 'OP_01010223_LABEL',
    label_min: 'OP_01010223_LABEL_MIN'
  },
  1: {
    label: 'OP_1_LABEL',
    label_min: 'OP_1_LABEL_MIN'
  },
  2: {
    label: 'OP_2_LABEL',
    label_min: 'OP_2_LABEL_MIN'
  },
  3: {
    label: 'OP_3_LABEL',
    label_min: 'OP_3_LABEL_MIN'
  },
  4: {
    label: 'OP_4_LABEL',
    label_min: 'OP_4_LABEL_MIN'
  },
  5: {
    label: 'OP_5_LABEL',
    label_min: 'OP_5_LABEL_MIN'
  },
  6: {
    label: 'OP_6_LABEL',
    label_min: 'OP_6_LABEL_MIN'
  },
  7: {
    label: 'OP_7_LABEL',
    label_min: 'OP_7_LABEL_MIN'
  },
  8: {
    label: 'OP_8_LABEL',
    label_min: 'OP_8_LABEL_MIN'
  },
  9: {
    label: 'OP_9_LABEL',
    label_min: 'OP_9_LABEL_MIN'
  },
  10: {
    label: 'OP_10_LABEL',
    label_min: 'OP_10_LABEL_MIN'
  }
};

export const handleTransactionType = (lang: Language, type: string | undefined): string => {
  if (!type) return '';
  if (_ztransactionType[type] === undefined) return type;

  return lang[_ztransactionType[type].label];
};

export const handleShortTransactionType = (lang: Language, type: string | undefined): string => {
  if (!type) return '';
  if (_ztransactionType[type] === undefined) return type;

  return lang[_ztransactionType[type].label_min];
};

export const handleTransactionMethod = (lang: Language, valueType: string | undefined): string => {
  if (!valueType) return '';
  return lang[valueType];
};

export const formatTransactionStatus = (lang: Language, status: string | undefined): string => {
  if (!status) return '';
  switch (status) {
    case 'WAITING': //normal deposit to be send
      return lang.TRANSACTIONS_STATUS_WAITING;
    case 'WILL_NOT_BE_SENT': //cancelled deposit/deposit with error and without values
      return lang.TRANSACTIONS_STATUS_WILL_NOT_BE_SENT;
    case 'SET_AS_NOT_TO_SEND': //Deposit ok without value, decided not to sent
      return lang.TRANSACTIONS_STATUS_SET_AS_NOT_TO_SEND;
    case 'SENDED_TO_COREBANKING':
      return lang.TRANSACTIONS_STATUS_SENDED_TO_COREBANKING;
    case 'CARD_CHECKED':
      return lang.TRANSACTIONS_STATUS_CARD_CHECKED;
    case 'DEPOSIT_PARCIAL_RECEIVED':
      return lang.TRANSACTIONS_STATUS_DEPOSIT_PARCIAL_RECEIVED;
    case 'WAITING_DECLARATION':
      return lang.TRANSACTIONS_STATUS_WAITING_DECLARATION;
    default:
      return status;
  }
};

export const handleEntid = (entities: IEntity[], entid: number): string | undefined => {
  if (!entities) return '';
  if (!entid) return '';

  let filteredEntity = entities.find(entity => entity.entid === entid);

  if (!filteredEntity) return '';

  return filteredEntity.name;
};

export const handleDownload = (data: string, filename: string, mimetype: string) => {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:' + mimetype + ';base64,' + data);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};
