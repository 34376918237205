export const viLang_en = {
  GLOBAL_LANGUAGE_NAME: 'English',
  GLOBAL_LANGUAGE_EN: 'en-GB',
  GLOBAL_LANGUAGE_PT: 'pt-PT',
  //Common Wording
  GLOBAL_ADD: 'Add',
  GLOBAL_DELETE: 'Delete',
  GLOBAL_UPDATE: 'Update',
  GLOBAL_CONTENT: 'Content',
  GLOBAL_SAVE_BUTTON: 'Save',
  GLOBAL_CANCEL: 'Cancel',
  GLOBAL_RETURN_BUTTON: 'Return',
  GLOBAL_EQUIPMENT: 'Equipment',
  GLOBAL_EQUIPMENTS: 'Equipments',
  GLOBAL_GLASSES: 'Glasses',
  GLOBAL_DONATION: 'Donation Entities',
  GLOBAL_SECTION_TABLE_TITLE: 'Manage ',
  GLOBAL_ADD_NEW: 'Add New ',
  GLOBAL_NAME: 'Name',
  GLOBAL_SEARCH: 'Search',
  GLOBAL_PROFILE: 'Profile',
  GLOBAL_USERS: 'Users',
  GLOBAL_PASSWORD: 'Password',
  GLOBAL_EVENT: 'Event',
  GLOBAL_LOGOUT: 'Logout',
  GLOBAL_ALL: 'All',
  GLOBAL_SHOWING: 'Showing',
  GLOBAL_RESULTS: 'results',
  GLOBAL_LANGUAGE: 'Language',
  GLOBAL_PERIOD: 'Period',
  GLOBAL_TYPE: 'Type',
  GLOBAL_AMOUNT: 'Amount',
  GLOBAL_QUANTITY: 'Quantity',
  GLOBAL_NO_RESULTS: 'No Results',
  GLOBAL_CODE: 'Code',
  GLOBAL_ACCOUNT: 'Account',
  GLOBAL_DATE_TIME: 'Date Time',
  GLOBAL_DATE_TIME_CLOSE: 'Date Time Close',
  GLOBAL_RECEIPT: 'Receipt',
  GLOBAL_COMISSIONS: 'Comissions (%)',
  GLOBAL_CONTRACT: 'Contract',
  GLOBAL_ENTITY: 'Entity',
  GLOBAL_HOURS: 'Hours',
  GLOBAL_MINUTES: 'Minutes',
  GLOBAL_STORE: 'Store',
  GLOBAL_VAULT: 'Vault',
  GLOBAL_BILLS: 'Bills',
  GLOBAL_COINS: 'Coins',
  GLOBAL_CURRENCY: 'Currency',
  GLOBAL_USER_EQUIPMENTS: 'Your equipments',
  GLOBAL_PICK_A_DATE: 'Pick a date',
  GLOBAL_REASON: 'Reason',
  GLOBAL_MAINTENANCE: 'Maintenance',
  GLOBAL_AGENT: 'Agent',
  GLOBAL_LICENSE: 'License',
  GLOBAL_STATISTICS: 'Statistics',
  GLOBAL_AUDITLOG: 'AuditLog',
  GLOBAL_IP: 'IP',
  GLOBAL_IP_LOCAL: 'Local IP',
  GLOBAL_GROUP: 'Group',
  GLOBAL_ENTITIES: 'Entities',
  GLOBAL_ADDRESS: 'Address',
  GLOBAL_LANGUAGES: 'Languages',
  GLOBAL_METHOD: 'Method',

  //Product
  GLOBAL_PRODUCTS: 'Products',
  PRODUCT_PLURAL: 'Produtos',  
  PRODUCT_DETAILS: 'Product details',
  PRODUCT_ID: 'Product ID',
  PRODUCT_PRICE: 'Price',

  //Events Section
  EVENTS: 'Events',
  EVENTS_NEW: 'Add New Event',
  EVENT_ADDRESS: 'Address',
  EVENT_ZIP_CODE: 'Zip Code',
  EVENT_BEGIN_DATE: 'Begin Date',
  EVENT_BEGIN_DATE_PLACEHOLDER: 'From',
  EVENT_END_DATE: 'End Date',
  EVENT_END_DATE_PLACEHOLDER: 'To',
  EVENT_LAT: 'Lat',
  EVENT_LONG: 'Long',
  EVENT_ID: 'Event Id',
  EVENT_DATE_INFORMATION: 'Date Information',
  EVENT_INSTALLATION: 'Installation',
  EVENT_UNINSTALLATION: 'Uninstallation',
  EVENT_DETAILS_GENERAL: 'General Information',
  EVENT_DETAILS_GENERAL_EVENT_DATES: 'Event Dates',
  EVENT_DETAILS_GENERAL_EVENT_DATES_FROM: 'From',
  EVENT_DETAILS_GENERAL_EVENT_DATES_TO: 'to',
  EVENT_DETAILS_GENERAL_DIRECTIONS: 'Directions',
  EVENT_GLASS_RECEPTACLE: 'Receptacle',
  EVENT_GLASS_DELIVERY_ANIMATION: 'Delivery Animation',
  EVENT_AD_ORDER: 'Order',
  EVENT_ID_PLACEHOLDER: 'Enter Event Id',
  EVENT_NAME_PLACEHOLDER: 'Enter Event Name',
  EVENT_ZIP_CODE_PLACEHOLDER: '0000-000 City',
  EVENT_LAT_PLACEHOLDER: '0.000',
  EVENT_LONG_PLACEHOLDER: '0.000',
  EVENT_EQUIPMENT_FORM_TITLE: 'Add Equipment to Event',
  EVENT_EQUIPMENT_FORM_SELECT: 'Select the Equipment',
  EVENT_GLASS_FORM_TITLE: 'Add Glass to Event',
  EVENT_GLASS_FORM_GLASS: 'Select the Glass',
  EVENT_GLASS_FORM_SELECT_RECEPTACLE: 'Select the receptacle',
  EVENT_GLASS_FORM_RECEPTACLE_1: 'Receptacle 1',
  EVENT_GLASS_FORM_RECEPTACLE_2: 'Receptacle 2',
  EVENT_GLASS_FORM_DELIVERY: 'Select the delivery animation',
  EVENT_GLASS_FORM_RIGHT: 'Direct to the right side',
  EVENT_GLASS_FORM_LEFT: 'Direct to the left side',
  EVENT_AD_FORM_TITLE: 'Add Advertisement to Event',
  EVENT_AD_FORM_SELECT: 'Select the Advertisement',
  EVENT_AD_FORM_ORDER: 'Pick the order',
  EVENT_DON_FORM_TITLE: 'Add Donation Entity to Event',
  EVENT_DON_SELECT: 'Select the Donation Entity',

  //Equipment Section
  EQUIPMENT_ID: 'EID',
  EQUIPMENT_SERIAL_NUMBER: 'Serial Number',
  EQUIPMENT_DESCRIPTION: 'Description',
  EQUIPMENT_IDENTIFICATION: 'Identification',
  EQUIPMENT_SOFTWARE_VERSION: 'Software Version',
  EQUIPMENT_SOFTWARE_VERSION_MIN: 'Soft. Version',
  EQUIPMENT_CYCLE: 'Cycle',
  EQUIPMENT_OCCUPATION: 'Occupancy',
  EQUIPMENT_COM_STATE: 'Status',
  EQUIPMENT_STATE: 'Equipment Status',
  EQUIPMENT_STATE_MIN: 'Equip. Status',
  EQUIPMENT_COM_DATE: 'Last Communication',
  EQUIPMENT_COM_DATE_MIN: 'Last Comm.',
  EQUIPMENT_LAST_OPER: 'Last Operation',
  EQUIPMENT_LAST_OPER_MIN: 'Last Op.',
  EQUIPMENT_LAST_OPER_DT: 'Last Operation Date/Time',
  EQUIPMENT_LAST_COM_DT: 'Last Comunication Date/Time',
  EQUIPMENT_LAST_OPER_DT_MIN: 'Last Op. DT',
  EQUIPMENT_OCCUPATION_PER: '% Occupancy',
  EQUIPMENT_EXISTENCES: 'Total Amount',
  EQUIPMENT_LOCAL: 'Place',
  EQUIPMENT_DETAILS_TITLE: "Equipment's Details",
  EQUIPMENT_DETAILS_SUBTITLE: 'Equipment Information',
  EQUIPMENT_EDIT: 'Update equipment',
  EQUIPMENT_AUTOMATIC_CLOSE: 'Auto-close time',
  EQUIPMENT_TOTAL_AMOUNT: 'Total in Equipment',
  EQUIPMENT_BILLS_RECYCLE: 'Bills reclycler',
  EQUIPMENT_COINS_RECYCLE: 'Coins recycler',
  EQUIPMENT_STATE_OK: 'Ok',
  EQUIPMENT_STATE_INACTIVE: 'Inative',
  EQUIPMENT_STATE_PICKUP: 'Pickup required',
  EQUIPMENT_STATE_CHARGE: 'Charging required',
  EQUIPMENT_STATE_ERROR: 'Some devices in error',
  EQUIPMENT_LIMIT_MAX: 'Maximum limit reached',
  EQUIPMENT_LIMIT_PICKUP: 'Pickup limit reached',
  EQUIPMENT_LIMIT_MIN: 'Minimum limit reached',
  EQUIPMENT_REGISTER: 'Register equipment',
  EQUIPMENT_CONTRACT: 'Consult contract',
  EQUIPMENT_CREATE_TICKET: 'Create ticket',
  EQUIPMENT_CONSULT: 'Consult equipment',
  EQUIPMENT_CONSULT_TICKET: 'Consult ticket',
  EQUIPMENT_AUTHORIZE_INITIATION: 'Authorize Startup',
  EQUIPMENT_REASONS_DECLARATIONS: 'Reasons and Declarations',
  EQUIPMENT_ADD_NEW_REASON: 'Add new reason',
  EQUIPMENT_ADD_NEW_DECLARATION: 'Add new declaration',

  EQUIPMENT_MAX_COINS_SAFE: 'Max coins in safe',
  EQUIPMENT_MAX_BILLS_SAFE: 'Max bills in safe',
  EQUIPMENT_ALMOST_FULL_SAFE: '% Almost full',
  EQUIPMENT_FULL_SAFE: '% Full',
  EQUIPMENT_CARD_INSERTION_TIME: 'Card insertion timeout',
  EQUIPMENT_CARD_REMOVAL_TIME: 'Card removal timeout',
  EQUIPMENT_DATA_INSERTION_TIME: 'Data insertion timeout',
  EQUIPMENT_DEPOSIT_INSERTION_TIME: 'Deposit timeout',
  EQUIPMENT_DECLARATION_TIME: 'Choose declaration timeout',
  EQUIPMENT_AMOUNT_CURRENCY: 'Almost/Full amount currency',
  EQUIPMENT_ALMOST_FULL_AMOUNT: 'Almost Full amount',
  EQUIPMENT_FULL_AMOUNT: 'Full amount',
  EQUIPMENT_LOCAL_IP: 'Local IP',

  //Equipment Status Label
  EQUIPMENT_STATUS_LABEL_NOT_DEFINED: 'Not Defined',
  EQUIPMENT_STATUS_LABEL_DEFINED: 'Defined',
  EQUIPMENT_STATUS_LABEL_INICIALIZED: 'Inicialized',
  EQUIPMENT_STATUS_LABEL_ONLINE: 'Online',
  EQUIPMENT_STATUS_LABEL_OFFLINE: 'Offline',
  EQUIPMENT_STATE_BUTTON_OUT_OF_SERVICE: 'Out of service',
  EQUIPMENT_STATE_BUTTON_IN_SERVICE: 'In service',
  EQUIPMENT_STATE_LABEL_OUT_OF_SERVICE_FAKE_NOTE: 'Out of Service with suspect fake notes retained',
  EQUIPMENT_STATE_LABEL_TITLE: 'Equipment State',
  EQUIPMENT_STATE_LABEL_PRINTER: 'Printer',
  EQUIPMENT_STATE_LABEL_CARD_READER: 'Card Reader',
  EQUIPMENT_STATE_LABEL_UPS: 'UPS',
  EQUIPMENT_STATE_LABEL_PAY_SYSTEM: 'Pay System',
  EQUIPMENT_STATE_LABEL_MANUAL_STATE: 'Manual State',
  EQUIPMENT_STATE_LABEL_QUANTITY: 'Quantity',
  EQUIPMENT_STATE_LABEL_MAX_AMOUNT: 'Max amount',
  EQUIPMENT_STATE_LABEL_MIN_AMOUNT: 'Min amount',
  EQUIPMENT_STATE_BUTTON_RESTART: 'Restart  ',
  EQUIPMENT_STATE_BUTTON_SHUTDOWN: 'Shutdown',
  EQUIPMENT_STATE_BUTTON_OPEN_ESCROW: 'Open escrow',
  EQUIPMENT_STATE_BUTTON_CLOSE_ESCROW: 'Close escrow',
  EQUIPMENT_NO_INFORMATION: 'No information available',
  EQUIPMENT_NO_DATA: 'No data available',
  EQUIPMENT_NO_STATUS: 'No status available',
  S_LEFT_DOOR_LABEL: 'Left door',
  S_RIGTH_DOOR_LABEL: 'Right door',
  S_MAINT_STRUCTURE_LABEL: 'Maint structure',
  S_SAFE_DOOR_LABEL: 'Safe door',
  S_SAFE_DOOR_LOCK_LABEL: 'Safe door lock',
  S_BAG_LABEL: 'Bag',
  S_ESCROW_LABEL: 'Escrow',
  S_ESCROW_MONEY_INSIDE_LABEL: 'Escrow money inside',
  S_COINS_DOOR_LABEL: 'Coins door',
  S_COINS_DOOR_LOCK_LABEL: 'Coins door lock',
  S_PROCESSOR_DRAWER_LABEL: 'Processor drawer',
  S_BILLS_DOOR_LABEL: 'Bills door',
  S_BILLS_DOOR_LOCK_LABEL: 'Bills door lock',
  S_COINS_DRAWER_LABEL: 'Coins drawer',
  S_COINS_SAFE_LABEL: 'Coins safe',

  //Equipment Status
  OK: 'Ok',
  YES: 'Yes',
  NO: 'No',
  IN_ERROR: 'In error',
  NO_PAPER: 'No paper',
  CARD_INSIDE: 'Card inside',
  LOW_PAPER: 'Low papper',
  OUT_OF_SERVICE: 'Out of service',
  FULL: 'Full',
  NEAR_FULL: 'Near full',
  OPEN: 'Open',
  CLOSE: 'Close',
  LOCKED: 'Locked',
  UNLOCKED: 'Unlocked',
  NOT_INSTALLED: 'Not installed',
  VALIDATOR_IN_ERROR: 'Validator(s) in error',
  UPS_ON_BATTERY: 'UPS on battery',
  EDIT_EQUIPMENTS_ALERT_UPDATED_SUCCESS: 'Equipment updated successfully',
  EDIT_EQUIPMENTS_ALERT_UPDATED_ERROR: 'Error updating equipment',

  //Equipment agreeType
  AGREE_STATE_0: 'n/d',
  AGREE_STATE_1: 'Active',
  AGREE_STATE_2: 'Pendent',
  AGREE_STATE_3: 'Canceled',
  AGREE_STATE_4: 'Non-compliance',

  REMOVE_REASON: 'Do you really want to delete the reason?',
  EQUIPMENT_STATE_LABEL_BILL_VALIDATOR: 'State bill validator',
  EQUIPMENT_STATE_LABEL_COIN_SAFE: 'State coins safe',
  REMOVE_DECLARATION: 'Do you really want to delete the declaration? ',
  ADD_REASON_SUCCESS: 'Reason added successfully',
  ADD_DECLARATION_SUCCESS: 'Declaration added successfully',
  ADD_REASON_ERROR: 'Error adding reason',
  ADD_DECLARATION_ERROR: 'Error adding declaration',
  REMOVE_REASON_SUCCESS: 'Reason removed successfully',
  REMOVE_DECLARATION_SUCCESS: 'Declaration removed successfully',
  REMOVE_REASON_ERROR: 'Error removing reason',
  REMOVE_DECLARATION_ERROR: 'Error removing declaration',

  //Glasses Section
  GLASS: 'Glass',
  GLASS_NEW: 'Add New Glass',
  GLASS_IMAGE: 'Image',
  GLASS_DESCRIPTION: 'Description',
  GLASS_DETAILS_TITLE: 'Glass Details',
  GLASS_ID: 'Glass ID',
  GLASS_ID_PLACEHOLDER: 'Enter Glass ID',
  GLASS_CODE: 'Code',
  GLASS_CODE_PLACEHOLDER: 'Enter the code',
  GLASS_SELECT_IMAGE: 'Select an Image',
  GLASS_DESCRIPTION_PLACEHOLDER: 'Enter Glass description',
  GLASS_SIZE_TYPE: 'Size Type',
  GLASS_SIZE_TYPE_PLACEHOLDER: 'Enter size type',
  GLASS_READ: 'Read Glass',

  //Glass Category
  CATEGORY: 'Category',
  CATEGORIES: 'Categories',
  CATEGORY_DESCRIPTION: 'Description',
  CATEGORY_NEW: 'New Category',

  //Advertising Section
  ADVERTISEMENTS: 'Advertisements',
  ADVERTISING_NEW: 'Add New Advertisement',
  ADVERTISING_FILE: 'File',
  ADVERTISING_DURATION: 'Duration',
  ADVERTISING_SECONDS: 'Seconds',
  ADVERTISING_SECS: 'secs',
  ADVERTISING_DETAILS_TITLE: "Advertisement's Details",
  ADVERTISING: 'Ad',
  ADVERTISING_ID: 'Advertisemente Id',
  ADVERTISING_ID_PLACEHOLDER: 'Enter Advertisement Id',

  //Donation Entities Section
  DONATION_ENTITY: 'Entity',
  DONATION_ENTITIES: 'Entities',
  DONATION_ENTITIES_LOGO: 'Logo',
  DONATION_ENTITY_ID: 'Donation Entity ID',
  DONATION_ENTITY_ID_PLACEHOLDER: "Please enter entity's ID number",
  DONATION_ENTITY_NAME_PLACEHOLDER: "Please enter entity's name",
  DONATION_ENTITY_SELECT_LOGO: 'Select the logo',
  DONATION_ENTITY_FORM_TITLE: 'Add New Entity',

  //Login
  LOGIN_ENTITY: 'Entity',
  LOGIN_USER: 'User',
  LOGIN_BUTTON: 'Login',
  LOGIN_EMAIL: 'Login with e-mail',
  LOGIN_USERID: 'Login with UserID',

  //Alert
  ALERT_ERRORCODE: 'Code',
  ALERT_ERRORMESSAGE: 'Message',

  //Users
  USER_UID: 'User ID',
  USER_EMAIL: 'Email',
  USER_EMAIL_PLACEHOLDER: 'example@email.com',
  USER_CARD: 'Card',
  USER_CARD_PLACEHOLDER: "Please enter User's card",
  USER_REGION: 'Region',
  USER_LANG: 'Language',
  USER_LANG_EN: 'English',
  USER_LANG_PT: 'Portuguese',
  USER_NEW: 'Add New User',
  USER_INFO: 'Personal Details',
  USER_ENTITY: 'Entity',
  USER_ENTITY_PLACEHOLDER: 'Select the Entity',
  USER_ID: 'User ID',
  USER_ID_PLACEHOLDER: 'Select the User ID',
  USER_NAME_PLACEHOLDER: "Please enter the User's name",
  USER_MOBILE: 'Cell Phone',
  USER_PHONE: 'Phone',
  USER_MOBILE_PLACEHOLDER: "Please enter the User's phone number",
  USER_PERMISSIONS: 'Permissions',
  USER_CONFIRM_PASSWORD: 'Confirm Password',
  USER_CONFIRM_CURRENT_PASSWORD: 'Please confirm your current password',
  USER_CHANGE_PASSWORD: 'Change Password',
  USER_NEW_PASSWORD: 'New Password',
  USER_PIN: 'PIN Code',
  USER_CHANGE_PIN: 'Change PIN',
  USER_NEW_PIN: 'New PIN Code',
  USER_CONFIRM_PIN: 'Confirm PIN Code',
  USER_DELETE_CONFIRM: 'Are you certain you wish to delete this user? This action is irreversible',
  USER_CONTACT_INFO: 'Contact Information',
  EDIT_USERS_LABEL_SYSTEM_MANAGEMENT_PERMISSION_1: 'User management and permissions',
  EDIT_USERS_LABEL_SYSTEM_MANAGEMENT_PERMISSION_2: 'System Configurations',
  EDIT_USERS_LABEL_SYSTEM_MANAGEMENT_PERMISSION_3: 'Eq. Non Financial Remote Oper.',
  EDIT_USERS_LABEL_SYSTEM_MANAGEMENT_PERMISSION_4: 'Technician',
  EDIT_USERS_LABEL_BUSINESS_DATA_MANAGEMENT_PERMISSION_1: 'Financial data',
  EDIT_USERS_LABEL_BUSINESS_DATA_MANAGEMENT_PERMISSION_2: 'CIT',
  EDIT_USERS_LABEL_BUSINESS_DATA_MANAGEMENT_PERMISSION_3: 'Eq. Financial Remote Oper.',
  EDIT_USERS_LABEL_BUSINESS_DATA_MANAGEMENT_PERMISSION_4: 'Operator',
  EDIT_USERS_LABEL_BUSINESS_DATA_MANAGEMENT_PERMISSION_5: 'APM',

  //app4
  APP4_OPT0: 'Agreement management',
  APP4_OPT1: 'Costumers management',
  APP4_OPT3: 'Helpdesk',
  APP4_OPT5: 'Event & Counter management',
  //app5
  APP5_OPT0: 'Products management',
  APP5_OPT1: 'Productions management',
  APP5_OPT2: 'Product reception',
  //app100
  APP100_OPT0: 'Financial data',
  APP100_OPT1: 'Stock',
  APP100_OPT2: 'Documents',

  USER_DELEGATIONS: 'Delegations',
  USER_DELEGATION: 'Delegation',
  USER_MY_PROFILE: 'My Profile',
  USER_PROFILE: 'Profile',
  EDIT_USER_ALERT_UPDATE_SUCCESS: 'User updated successfully',
  EDIT_USER_ALERT_UPDATE_ERROR: 'Error updating user',
  EDIT_USER_ALERT_UPDATE__PASSWORD_ERROR: 'Invalid password',

  //Movements
  MOVEMENTS: 'Transactions',
  PROCESSED_GLASS_PERIOD: 'Period',
  PROCESSED_GLASS_ID: 'ID',
  PROCESSED_GLASS_STATUS: 'Status',
  SUCCESS: 'Collected',
  CANCELLED: 'Cancelled',
  ERROR: 'Error',
  PROCESSED_GLASS_ERROR: 'Error',
  NONE: ' ',
  INTERNAL: 'Internal Error',
  NO_GLASS_ID: 'Unrecognized glass',
  PROCESSED_GLASS_REFUND_TYPE: 'Refund Type',
  CASH: 'Cash',
  DONATION: 'Donation',
  PROCESSED_GLASS_TUBE: 'Tube',
  PROCESSED_GLASS_INITIAL_DATE: 'Intitial Date',
  PROCESSED_GLASS_END_DATE: 'End Date',
  PROCESSED_GLASS_SEARCH_PLACEHOLER: 'Select an option',

  //Transactions
  TRANSACTIONS: 'Transactions',
  TRANSACTION_DETAILS: 'Transaction Details',
  TRANSACTION_RECEIVED: 'Received',
  TRANSACTION_DISPENSED: 'Dispensed',
  TRANSACTION_PAYMENT_METHOD: 'Payment Method',
  TRANSACTION_USER_CASHIER: 'User/Cashier',
  TRANSACTION_DEPOSITOR: 'Depositor',
  TRANSACTION_EXTERNAL_ID: 'External ID',
  TRANSACTION_NUMBER: 'Transaction Number',
  TRANSACTION_NUMBERS_FULL: 'Number of transactions',

  //Currency Table
  CURRENCY_TABLE_CASH_IN: 'Incoming Cash',
  CURRENCY_TABLE_CASH_OUT: 'Outgoing Cash',

  //Enterprise Equipment Periods
  ENTERPRISE_EQUIP_PERIODS: 'Enterprise Equipment Periods',
  ENTERPRISE_EQUIP_PERIODS_SHORT: 'Ent. Equip. Periods',
  ENTERPRISE_EQUIP_PERIODS_DT_OPEN: 'Date/Time open',
  ENTERPRISE_EQUIP_PERIODS_DT_CLOSE: 'Date/Time close',
  ENTERPRISE_EQUIP_PERIODS_PAYMENT_AMOUNTS: 'Payment Amount',
  ENTERPRISE_EQUIP_PERIODS_PAYMENT_AMOUNTS_MIN: 'Paymnt. Amnt.',

  //Bank Transactions
  BANK_TRANSACTIONS: 'Bank Transactions',

  //Dashboard
  DASHBOARD: 'Dashboard',

  // Transactions type
  OP_01010101_LABEL: 'Payment',
  OP_01010101_LABEL_MIN: 'Payment',
  OP_01010122_LABEL: 'Payment Canceled',
  OP_01010122_LABEL_MIN: 'P. Canceled',
  OP_01010123_LABEL: 'Payment Recovered',
  OP_01010123_LABEL_MIN: 'P. Recovered',
  OP_01010125_LABEL: 'Payment Rejected',
  OP_01010125_LABEL_MIN: 'P. Rejected',
  OP_01010128_LABEL: 'Payment Rejected by error',
  OP_01010128_LABEL_MIN: 'P.Rej. by error',
  OP_01010201_LABEL: 'Deposit',
  OP_01010201_LABEL_MIN: 'Deposit',
  OP_01010202_LABEL: 'Deposit by timeout',
  OP_01010202_LABEL_MIN: 'Deposit',
  OP_01010222_LABEL: 'Deposit canceled',
  OP_01010222_LABEL_MIN: 'D. Canceled',
  OP_01010224_LABEL: 'Deposit canceled by timeout',
  OP_01010224_LABEL_MIN: 'D. Canceled',
  OP_01010225_LABEL: 'Deposit rejected',
  OP_01010225_LABEL_MIN: 'D. Rejected',
  OP_01010226_LABEL: 'Deposit rejected w/ money remove timeout',
  OP_01010226_LABEL_MIN: 'D. Rejected',
  OP_01010227_LABEL: 'Deposit with error rejected by timeout',
  OP_01010227_LABEL_MIN: 'D. Rejected',
  OP_01010228_LABEL: 'Deposit with error rejected',
  OP_01010228_LABEL_MIN: 'D. Rejected',
  OP_01010229_LABEL: 'Deposit with error rejected w/ money remove timeout',
  OP_01010229_LABEL_MIN: 'D. Rejected',
  OP_01010230_LABEL: 'Transaction canceled due to lack of response, needs manual resolution',
  OP_01010230_LABEL_MIN: 'Transaction canceled due to lack of response, needs manual resolution',
  OP_01010231_LABEL: 'Deposit with suspect fake banknotes rejected by timeout',
  OP_01010231_LABEL_MIN: 'Deposit with suspect fake banknotes rejected by timeout',
  OP_01010232_LABEL: 'Deposit with suspect fake banknotes rejected',
  OP_01010232_LABEL_MIN: 'Deposit with suspect fake banknotes rejected',
  OP_01010233_LABEL: 'Deposit with suspect fake banknotes w/ money remove timeout',
  OP_01010233_LABEL_MIN: 'Deposit with suspect fake banknotes w/ money remove timeout',
  OP_01010301_LABEL: 'Withdrawal',
  OP_01010301_LABEL_MIN: 'Withdrawal',
  OP_01010322_LABEL: 'Withdrawal Canceled',
  OP_01010322_LABEL_MIN: 'W. Canceled',
  OP_01010328_LABEL: 'Withdrawal rejected by error',
  OP_01010328_LABEL_MIN: 'W. Canceled',
  OP_01010334_LABEL: 'Withdrawal Incomplete',
  OP_01010334_LABEL_MIN: 'W. Incomplete',
  OP_01010401_LABEL: 'Return',
  OP_01010401_LABEL_MIN: 'Return',
  OP_01011101_LABEL: 'Load',
  OP_01011101_LABEL_MIN: 'Load',
  OP_01011122_LABEL: 'Load Canceled',
  OP_01011122_LABEL_MIN: 'Load Canceled',
  OP_01011123_LABEL: 'Load Recovered',
  OP_01011123_LABEL_MIN: 'Load Recovered',
  OP_01011128_LABEL: 'Load canceled by error',
  OP_01011128_LABEL_MIN: 'Load C. by erro',
  OP_01011201_LABEL: 'Send to Safe',
  OP_01011201_LABEL_MIN: 'Send to Safe',
  OP_01011222_LABEL: 'Send to Safe Canceled',
  OP_01011222_LABEL_MIN: 'StS Canceled',
  OP_01011223_LABEL: 'Send to Safe Recovered',
  OP_01011223_LABEL_MIN: 'StS Recovered',
  OP_01011228_LABEL: 'Send to Safe Rejected by error',
  OP_01011228_LABEL_MIN: 'StS Rejected',
  OP_01010901_LABEL: 'Manual deposit',
  OP_01010901_LABEL_MIN: 'M. Deposit',
  OP_01011001_LABEL: 'Deposit reversal',
  OP_01011001_LABEL_MIN: 'Deposit R.',
  OP_02120503_LABEL: 'Open Period',
  OP_02120503_LABEL_MIN: 'Open Period',
  OP_02120506_LABEL: 'Close Period',
  OP_02120506_LABEL_MIN: 'Close Period',
  OP_01010223_LABEL: 'MOV. TO RECOVER',
  OP_01010223_LABEL_MIN: 'MOV. TO RECOVER',
  OP_01011301_LABEL: 'Change',
  OP_01011301_LABEL_MIN: 'Change',
  OP_01011322_LABEL: 'Change Canceled',
  OP_01011322_LABEL_MIN: 'Change Canceled',
  OP_01011323_LABEL: 'Change recovered',
  OP_01011323_LABEL_MIN: 'Change recovered',
  OP_01011325_LABEL: 'Change Incomplete',
  OP_01011325_LABEL_MIN: 'Change Incomplete',
  OP_01011334_LABEL: 'Change Incomplete',
  OP_01011334_LABEL_MIN: 'Change Incomplete',
  OP_1_LABEL: 'Payment',
  OP_1_LABEL_MIN: 'Payment',
  OP_2_LABEL: 'Authorization and Purchase',
  OP_2_LABEL_MIN: 'Auth and Purchase',
  OP_3_LABEL: 'Authorization',
  OP_3_LABEL_MIN: 'Authorization',
  OP_4_LABEL: 'Refund',
  OP_4_LABEL_MIN: 'Refund',
  OP_5_LABEL: 'Hopper Load',
  OP_5_LABEL_MIN: 'Hopper Load',
  OP_6_LABEL: 'Hopper Audit',
  OP_6_LABEL_MIN: 'Hopper Audit',
  OP_7_LABEL: 'Open Safe',
  OP_7_LABEL_MIN: 'Open Safe',
  OP_8_LABEL: 'Deposit',
  OP_8_LABEL_MIN: 'Deposit',
  OP_9_LABEL: 'Period Open',
  OP_9_LABEL_MIN: 'Period Open',
  OP_10_LABEL: 'Period Close',
  OP_10_LABEL_MIN: 'Period Close',

  //Transactions status
  TRANSACTIONS_STATUS_WAITING: 'Waiting',
  TRANSACTIONS_STATUS_WILL_NOT_BE_SENT: 'Done',
  TRANSACTIONS_STATUS_SET_AS_NOT_TO_SEND: 'Done',
  TRANSACTIONS_STATUS_SENDED_TO_COREBANKING: 'Communicated',
  TRANSACTIONS_STATUS_CARD_CHECKED: 'Card checked',
  TRANSACTIONS_STATUS_DEPOSIT_PARCIAL_RECEIVED: 'Parcial received',
  TRANSACTIONS_STATUS_WAITING_DECLARATION: 'Waiting declaration',

  //APMs
  APM_TRANSACTIONS: 'APM Transactions',
  APM_PERIODS: 'APM Periods',
  APM_CLOSE_PERIODS: 'Closed Periods',
  APM_POS: 'APM POS',

  // HelpDesk
  HELPDESK_EQUIPMENTS: 'Ms Equipments',
  HELPDESK_ENTITIES: 'MS Entities',
  HELPDESK_MAINTENANCE_COMPANY: 'Maintenance company',
  HELPDESK_MAINTENANCE_AGENCY: 'Maintenance agency',
  HELPDESK_CHOOSE_ENTITY: 'Choose entity',

  //entity types
  HELPDESK_ENTITY_REMOVE: 'Are you sure you want to delete this entity?',
  ENTITY_TYPE_0: 'Company',
  ENTITY_TYPE_1: 'Agent',
  ENTITY_TYPE_2: 'Supplier',
  ENTITY_TYPE_3: 'Maintenance Service Provider',
  ENTITY_TYPE_NULL: 'All',

  //edit Entity
  EDIT_ENTITY_FORM_LABEL_GID: 'Group (GID)',
  EDIT_ENTITY_FORM_LABEL_LOGO: 'Logo',
  EDIT_ENTITY_FORM_LABEL_USESHIFTS: 'Shifts',
  EDIT_ENTITY_FORM_LABEL_PRINTTYPE: 'Print type',
  EDIT_ENTITY_FORM_LABEL_REPORTTYPE: 'Report type',
  EDIT_ENTITY_FORM_LABEL_SIBSMERCHANTCODE: 'Sibs Mech. Code',
  EDIT_ENTITY_FORM_LABEL_BILLINGENTITY: 'Billing entity',
  EDIT_ENTITY_FORM_LABEL_OWNERID: 'Owner ID',
  EDIT_ENTITY_FORM_LABEL_ACTIVITY: 'Activity',
  EDIT_ENTITY_FORM_LABEL_BILLVOLUME: 'Bill volume',
  EDIT_ENTITY_LABEL_READ: 'Visualize entity',
  EDIT_ENTITY_LABEL_EDIT: 'Edit entity',
  EDIT_ENTITY_CONTROLLER_ALERT_DELETE_CONFIRM: 'Do you really want to delete the entity',
  EDIT_ENTITY_CONTROLLER_ALERT_DELETE_SUCCESS: 'Entity deleted with success'
};
