import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Container, Group, Select, TextInput } from '@mantine/core';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import ModalComponent from '../../components/modal';
import CustomModal from '../../components/custom-modal';
import {
  addEquipmentDeclaration,
  addEquipmentReason,
  updateEquipmentReason,
  updateEquipmentDeclaration,
  Reason,
  Declaration
} from '../../../libraries/equipment';
import { fetchLanguages, ILanguage } from '../../../libraries/language';
import { LoginState } from '../../client-redux/login/reducer';
import './styles.scss';

interface ReasonDeclarationProps {
  show: boolean;
  onClose: () => void;
  codeReasonDeclaration: number;
  language: number;
  text: string;
  type?: 'reason' | 'declaration';
  isEditing: boolean;
  title: string;
  onReload: () => void;
}

const EquipmentReasonDeclaration: React.FC<ReasonDeclarationProps> = ({
  show,
  onClose,
  codeReasonDeclaration,
  language,
  title,
  text,
  isEditing,
  type,
  onReload
}) => {
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const loginInfo = useSelector<AppState, LoginState>(state => state.login);

  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [code, setCode] = useState<number>(codeReasonDeclaration);
  const [description, setDescription] = useState<string>(text);
  const [languages, setLanguages] = useState<ILanguage[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<number>();

  const controllerAddReason = new AbortController();
  const controllerAddDeclaration = new AbortController();

  useEffect(() => {
    const fetchLanguageList = async () => {
      try {
        const response = await fetchLanguages();
        setLanguages(response.languages);
      } catch (error) {
        console.error('Error fetching languages:', error);
      }
    };
    fetchLanguageList();
  }, []);

  useEffect(() => {
    setSelectedLanguage(language);
    setCode(codeReasonDeclaration);
    setDescription(text);
  }, [codeReasonDeclaration, text, language]);

  const handleAddUpdate = async () => {
    let payload: Reason | Declaration | undefined;

    if (isEditing) {
      if (type === 'reason') {
        payload = {
          gid: loginInfo.user.gid,
          entid: loginInfo.user.entid,
          langid: Number(selectedLanguage),
          code: Number(code),
          reason: description
        } as Reason;
      } else if (type === 'declaration') {
        payload = {
          gid: loginInfo.user.gid,
          entid: loginInfo.user.entid,
          langid: Number(selectedLanguage),
          code: Number(code),
          declaration: description
        } as Declaration;
      }

      try {
        let response;
        if (type === 'reason') {
          response = await updateEquipmentReason(payload as Reason, controllerAddReason);
        } else if (type === 'declaration') {
          response = await updateEquipmentDeclaration(payload as Declaration, controllerAddDeclaration);
        }

        if (response.statusOper.code !== 0) {
          throw new Error(response.statusOper.message || 'Unknown error occurred.');
        }

        setSuccessMessage(
          type === 'reason' ? lang?.UPDATE_REASON_SUCCESS || '' : lang?.UPDATE_DECLARATION_SUCCESS || ''
        );
        setShowSuccessDialog(true);
        onReload();
      } catch (error: any) {
        // Set error message based on the response's statusOper.message
        setErrorMessage(error.message || 'Error occurred while updating.');
        setShowErrorDialog(true);
        onReload();
      }
    } else {
      let payload: Reason | Declaration | undefined;

      if (type === 'reason') {
        payload = {
          gid: loginInfo.user.gid,
          entid: loginInfo.user.entid,
          langid: Number(selectedLanguage),
          code: Number(code),
          reason: description
        } as Reason;
      } else if (type === 'declaration') {
        payload = {
          gid: loginInfo.user.gid,
          entid: loginInfo.user.entid,
          langid: Number(selectedLanguage),
          code: Number(code),
          declaration: description
        } as Declaration;
      }

      if (payload) {
        try {
          let response;
          if (type === 'reason') {
            response = await addEquipmentReason(payload as Reason, controllerAddReason);
          } else if (type === 'declaration') {
            response = await addEquipmentDeclaration(payload as Declaration, controllerAddDeclaration);
          }

          if (response.statusOper.code !== 0) {
            throw new Error(response.statusOper.message || 'Unknown error occurred.');
          }

          setSuccessMessage(type === 'reason' ? lang?.ADD_REASON_SUCCESS || '' : lang?.ADD_DECLARATION_SUCCESS || '');
          setShowSuccessDialog(true);
          onReload();
        } catch (error: any) {
          setErrorMessage(error.message || 'Error occurred while adding.');
          setShowErrorDialog(true);
          onReload();
        }
      }
    }
  };

  return (
    <>
      <ModalComponent
        show={show}
        handleClick={onClose}
        title={title}
        size="auto"
        content={
          <Container className="add-reason-declaration">
            <Group>
              <Select
                label={lang?.GLOBAL_LANGUAGE}
                value={selectedLanguage?.toString()}
                onChange={value => setSelectedLanguage(Number(value))}
                data={
                  languages?.map(language => ({
                    value: language.langid.toString(),
                    label: language.nativeName
                  })) || []
                }
                disabled={isEditing}
              />
              <TextInput
                label={lang?.GLOBAL_CODE}
                value={code}
                onChange={e => setCode(Number(e.target.value))}
                disabled={isEditing}
              />
            </Group>

            <Group className="add-reason-declaration-description">
              <TextInput
                label={lang?.EQUIPMENT_DESCRIPTION}
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
            </Group>

            <Group>
              <Button color="green" radius="md" onClick={handleAddUpdate}>
                {isEditing ? lang?.GLOBAL_UPDATE : lang?.GLOBAL_ADD}
              </Button>
              <Button color="red" radius="md" onClick={onClose}>
                {lang?.GLOBAL_CANCEL}
              </Button>
            </Group>
          </Container>
        }
      />

      <CustomModal
        opened={showSuccessDialog}
        onClose={() => setShowSuccessDialog(false)}
        message={successMessage}
        backgroundColor="#3BDF70"
        textColor="black"
      />

      <CustomModal
        opened={showErrorDialog}
        onClose={() => setShowErrorDialog(false)}
        message={errorMessage}
        backgroundColor="#E3353F"
        textColor="white"
      />
    </>
  );
};

export default EquipmentReasonDeclaration;
