import axios from 'axios';
import { ApiResponse, zps_ea_war } from '../zps';
import { handleDownload } from '../../app/utils/script';

export type IEquipment = {
  almostFullPerc: number;
  almostFullValue: number;
  bag: boolean;
  bankid: number;
  cardInsertionTimeout: number;
  cardRemovalTimeout: number;
  comuid: number;
  currencies: IEquipmentCurrency[];
  dataInsertionTimeout: number;
  declarationTimeout: number;
  depositTimeout: number;
  description: string;
  eid: number;
  entid: number;
  equiId: number;
  etid: number;
  etvid: number;
  fullPerc: number;
  fullValue: number;
  fullValueCid: number;
  gid: number;
  idTerminal: number;
  inuse: number;
  lang: number;
  languages: IEquipmentLanguage[];
  lid: number;
  maintid: number;
  maintid2: number;
  maxBills: number;
  maxCoins: number;
  p43sf8: number;
  region: string;
  sa1: number;
  sa2: number;
  sa3: number;
  sa4: number;
  sa5: number;
  sa6: number;
  sa7: number;
  sa8: number;
  secur: number;
  serialnumber: string;
  sid: number;
  status: number;
  suppid: number;
  token: string;
  zkey: string;
  amount: string;
};

export type IEquipmentState = {
  cardreaderState: number;
  depositToRecover: boolean;
  diskUsage: number;
  equipmentState: number;
  fakeNoteDetected: boolean;
  moosState: number;
  paySystemState: number;
  printerState: number;
  quantityState: number;
  switchs: number;
  upsState: number;
  valueState: number;
};

export type IEquipmentLanguage = {
  eid: number;
  gid: number;
  langid: number;
  langidx: number;
};

export type IEquipmentCurrency = {
  cid: number;
  eid: number;
  gid: number;
  maxAmount: number;
  minAmount: number;
};

export type IPeriodAmount = {
  amount?: number;
  amountBilled?: number;
  cid?: 1;
  envTotals?: number;
};

export type IEntityLabel = {
  entid?: number;
  name?: string;
  label?: string;
};

export type IEquipmentDetails = {
  equipment: IEquipment;
  equipmentState: IEquipmentState;
  lastComDt: Date;
  lastLocalIp: string;
  lastOperation: string;
  lastOperationDt: Date;
  period: number;
  periodAmounts?: IPeriodAmount[];
  softwareVersion: string;
};

export type IEquipmentOption = {
  eid: number;
  label: string;
};

export type IEquipmentDeviceStatus = {
  id: string;
  idLangKey: string;
  level: string;
  statusLangKey: string;
};

export interface IEquipmentStatusResponse extends ApiResponse {
  status: {
    inService: boolean;
    lastComDt: Date;
    lastLocalIp: string;
    lastOperation: string;
    lastOperationDt: Date;
    softwareVersion: string;
    status: number;
    statusList?: IEquipmentDeviceStatus[];
  };
}

export interface IEquipmentListResponse extends ApiResponse {
  equipments: IEquipmentDetails[];
  totalRows: number;
  type: string;
}

export interface IEquipmentOptionsResponse extends ApiResponse {
  list: IEquipmentOption[];
  type: string;
}

export interface IHelpdeskEquipmentResponse extends ApiResponse {
  list: IEquipmentDetails[];
  totalRows: number;
  type: string;
}

export type Reason = {
  gid:number;
  entid: number;
  langid: number;
  code: number;
  reason: string
};

export type Declaration = {
  gid:number;
  entid: number;
  langid: number;
  code: number;
  declaration: string 
};

export interface EquipmentReasonsResponse extends ApiResponse {
  reasons: Reason[];
  type: string;
}

export interface EquipmentDeclarationResponse extends ApiResponse {
declarations: Declaration[];
type: string;
}

export const fetchEquipmentList = async (
  entid: number,
  sid: number,
  offset: number,
  limit: number,
  totalRows: boolean,
  controller: AbortController
) => {
  const { data } = await axios.get(
    `${zps_ea_war}/equipment/details/?entid=${entid}&sid=${sid}&offset=${offset}&limit=${limit}&totalRows=${totalRows}`,
    {
      signal: controller.signal
    }
  );
  return data;
};

export interface EquipmentUpdateRequest {
  almostFullPerc?: number
  almostFullValue?: number
  almostFullValueToShow?: number
  amounts?: []
  bankid?: number
  cardInsertionTimeout?: number
  cardRemovalTimeout?: number
  currencies?: []
  dataInsertionTimeout?: number
  declarationTimeout?: number
  depositTimeout?: number
  description: string
  eid?: number
  entid?: number
  fullPerc?: number
  fullValue?: number
  fullValueCid?: number
  fullValueCidObj?: number
  fullValueToShow?: number
  lang?: number
  languages?: []
  lastComDt?: string
  lastOperationDt?: string
  maxBills?: number
  maxCoins?: number
  period?: string
  region?:string
  sid?: number
  status?: string
  type?: number
  _status?: number 
}

 export  const updateEquipment = async (
  request: EquipmentUpdateRequest,
  controller: AbortController
) => {
  const { data } = await axios.put(`${zps_ea_war}/equipment`, request, {
    signal: controller.signal
  });
  return data;
};

export const fetchEquipmentByEid = async (eid: number) => {
  const { data } = await axios.get(`${zps_ea_war}/equipment/status?eid=${eid}`);

  return data;
};

export const fetchEquipmentOptions = async (entid: number, sid: number, controller: AbortController) => {
  const { data } = await axios.get(`${zps_ea_war}/equipment/options/?entid=${entid}&sid=${sid}`, {
    signal: controller.signal
  });

  return data;
};

export const fetchExistencesReport = async (eid: number, period: number, controller: AbortController) => {
  try {
    const response = await axios.get(`${zps_ea_war}/equipment/details/report/existences/?eid=${eid}&period=${period}`, {
      signal: controller.signal
    });

    handleDownload(response.data.report, response.data.name, response.data.mime);
  } catch (error) {
    console.error('Failed to fetch Report file:', error);
  }
};

export const fetchEquipmentReasons = async (entid: number, controller: AbortController) => {

  const { data }= await axios.get(`${zps_ea_war}/reason/?entid=${entid}`, {
      signal: controller.signal
    });

    return data;
};

export const fetchEquipmentDeclarations = async (entid: number, controller: AbortController) => {
  const { data } = await axios.get(`${zps_ea_war}/declaration/?entid=${entid}`, {
      signal: controller.signal
    });

    return data;
};

export const fetchEquipmentDetails = async (eid: number, controller: AbortController) => {
  const { data } = await axios.get(`${zps_ea_war}/equipment/details/?eid=${eid}` , {
    signal: controller.signal
  });

  return data
 };

export const addEquipmentReason = async (payload: Reason, controller: AbortController) => {
  const {data} = await axios.post(`${zps_ea_war}/reason/`, payload, 
  {
    signal: controller.signal
  });

  return data;
};

export const addEquipmentDeclaration = async (payload: Declaration, controller: AbortController) => {
  const {data} = await axios.post(
    `${zps_ea_war}/declaration/`, payload, 
  {
    signal: controller.signal
  });
  return data;
};

export const updateEquipmentReason = async (payload: Reason, controller: AbortController) => {
  const {data} = await axios.put(`${zps_ea_war}/reason/`, payload, 
  {
    signal: controller.signal
  });

  return data;
};

export const updateEquipmentDeclaration = async (payload: Declaration, controller: AbortController) => {
  const {data} = await axios.put(
    `${zps_ea_war}/declaration/`, payload, 
  {
    signal: controller.signal
  });
  return data;
};

export const deleteEquipmentReason = async (entid: number, code: number, langid: number) => {
 return axios.delete(`${zps_ea_war}/reason/?entid=${entid}&code=${code}&langid=${langid}`).then(response => response.data);
};

export const deleteEquipmentDeclaration = async (entid: number, code: number, langid: number) => {
  return axios.delete(`${zps_ea_war}/declaration/?entid=${entid}&code=${code}&langid=${langid}`).then(response => response.data);
 };

