import axios from 'axios';
import { MeansCash } from '../transactions';
import { ApiResponse, zps_ea_war } from '../zps';

export type IRejects = {
  denominationType: string;
  position: number;
  type: string;
};

export type IRefId = {
  code: number;
  eid: number;
  period: number;
};

export type IBankTransaction = {
  account: string;
  bankid: number;
  billsInDoubt: number;
  cashIn: MeansCash[];
  cid: number;
  code: number;
  coinsInDoubt: number;
  dateTime: string;
  dateTimeSent: string;
  depositor: string;
  eid: number;
  entid: number;
  envTotals: number;
  etvid: number;
  gid: number;
  hostStatusCode: number;
  hostTransactionID: string;
  msid: number;
  nseq: number;
  period: number;
  reason: string;
  refID?: IRefId;
  rejects: IRejects[];
  shift: number;
  sid: number;
  status: string;
  type: string;
  value: number;
  valueDis: number;
  valueRec: number;
};

export interface IBankTransactionResponse extends ApiResponse {
  transactions: IBankTransaction[];
  payment: boolean;
  totalRows: number;
}

export const fetchBankTransactionList = async (
  begin: string,
  end: string,
  entid: number,
  sid: number,
  offset: number,
  limit: number,
  totalRows: boolean,
  controller: AbortController
) => {
  const { data } = await axios.get(
    `${zps_ea_war}/tb420/transaction/?begin=${begin}&end=${end}&entid=${entid}&sid=${sid}&offset=${offset}&limit=${limit}&totalRows=${totalRows}`,
    {
      signal: controller.signal
    }
  );

  return data;
};

export const fetchBankTransactionReport = async (
  format: string,
  eid: number,
  period: number,
  code: number,
  controller: AbortController
) => {
  const { data } = await axios.get(
    `${zps_ea_war}/tb420/transaction/report/?format=${format}&eid=${eid}&period=${period}&code=${code}`,
    {
      signal: controller.signal
    }
  );

  return data;
};
