import { useEffect, useState } from 'react';
import { Container, Table, Grid, GridCol, Group, Loader } from '@mantine/core';
import Header from '../../components/util/header';
import { useSelector } from 'react-redux';
import { fetchDashboardInfo, IDashboardResponse } from '../../../libraries/dashboard';
import { AppState } from '../../client-redux/reducers';
import { ShopState } from '../../client-redux/shops/reducer';
import { Language } from '../../languages/languageHandler';
import { LoginState } from '../../client-redux/login/reducer';
import { ICurrency } from '../../../libraries/currencies';
import { ReactComponent as CheckIcon } from '../../../assets/img/check.svg';
import { ReactComponent as XIcon } from '../../../assets/img/x.svg';
import { ReactComponent as TransportIcon } from '../../../assets/img/cart-flatbed-solid.svg';
import { ReactComponent as BateryIcon } from '../../../assets/img/battery-quarter-solid.svg';
import { ReactComponent as ErrorIcon } from '../../../assets/img/triangle-exclamation-solid.svg';
import { ReactComponent as Square } from '../../../assets/img/square-solid.svg';
import { formatCurrAmount } from '../../utils/script';
import FilterBar from '../../components/filterbar';
import PaginationComponent from '../../components/pagination';
import './styles.scss';

const Dashboard = () => {
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const loginInfo = useSelector<AppState, LoginState>(state => state.login);
  const currencies = useSelector<AppState, ICurrency[]>(state => state.currencies.currencies);
  const shops = useSelector<AppState, ShopState>(state => state.shops);
  const controller = new AbortController();
  const [entid, setEntid] = useState<number>(loginInfo.user?.entid);
  const [sid, setSid] = useState<number>(0);
  const [message, setMessage] = useState<IDashboardResponse>();
  const [loading, setLoading] = useState(false);
  const [searchTrigger, setSearchTrigger] = useState<boolean>(true);
  const [statusOperCode, setStatusOperCode] = useState<number | undefined>();
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [noResults, setNoResults] = useState<boolean>(true);

  useEffect(() => {
    if (searchTrigger) {
      setSearchTrigger(true);
      setLoading(true);
      getDashboardInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTrigger]);

  const onSearch = () => {
    setSearchTrigger(true);
  };

  useEffect(() => {
    getDashboardInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entid, sid]);

  const getDashboardInfo = () => {
    if (!loginInfo.token) {
      return;
    }
    if (loading) {
      setNoResults(false);
      controller.abort();
    }
    setLoading(true);
    fetchDashboardInfo(entid, sid)
      .then(response => {
        if (response.statusOper.code === 0) {
          setMessage(response);
          setNoResults(false);
        } else {
          setStatusOperCode(response.statusOper.code);
          setLoading(false);
          setNoResults(true);
        }
      })
      .catch(error => {
        if (error.name === 'AbortError') {
          // console.log('API request aborted');
        } else {
          console.error('API request error:', error);
        }
      })
      .finally(() => setLoading(false));
  };

  const getValPosition = (array: number[] | undefined | null, index: number) => {
    if (!array || array.length <= index || array[index] === undefined) {
      return '-';
    }
    return array[index] === 0 ? '-' : array[index];
  };

  const updateEntid = (newEntid: number, callback?: () => void) => {
    setEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  const updateTableEntid = (newEntid: number, callback?: () => void) => {
    updateEntid(newEntid);
    if (callback) {
      callback();
    }
  };

  const updateSid = (newSid: number, callback?: () => void) => {
    setSid(newSid);
    if (callback) {
      callback();
    }
  };

  const updateTableSid = (newSid: number, callback?: () => void) => {
    updateSid(newSid);
    if (callback) {
      callback();
    }
  };

  const changePage = (n: number) => {
    const newOffset = (n - 1) * limit;
    setCurrentPage(n);
    setOffset(newOffset);
  };

  const resultsSelect = (newValue: number) => {
    const newLimit = Number(newValue);
    setLimit(newLimit);
    setCurrentPage(0);
    setOffset(0);
  };

  useEffect(() => {
    getDashboardInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset, limit]);

  return (
    <Container fluid classNames={{ root: 'dashboard' }}>
      <Header pageTitle={lang?.DASHBOARD} />

      <FilterBar
        onSearch={onSearch}
        tableLoaded={getDashboardInfo}
        filterByEntid={updateTableEntid}
        filterBySid={updateTableSid}
      />

      <Table.ScrollContainer minWidth={500}>
        <Table striped verticalSpacing="sm">
          <Table.Thead style={{ width: '100%' }}>
            <Table.Tr>
              <Table.Th rowSpan={2}>{lang?.USER_DELEGATION}</Table.Th>
              <Table.Th rowSpan={2}>{lang?.GLOBAL_EQUIPMENT}</Table.Th>
              <Table.Th rowSpan={2}>{lang?.EQUIPMENT_COM_STATE}</Table.Th>
              <Table.Th rowSpan={2}>{lang?.EQUIPMENT_TOTAL_AMOUNT}</Table.Th>
              <Table.Th colSpan={2}>{lang?.GLOBAL_VAULT}</Table.Th>
              <Table.Th colSpan={2}>{lang?.EQUIPMENT_BILLS_RECYCLE}</Table.Th>
              <Table.Th colSpan={8}>{lang?.EQUIPMENT_COINS_RECYCLE}</Table.Th>
            </Table.Tr>

            <Table.Tr>
              <Table.Th>{lang?.GLOBAL_BILLS}</Table.Th>
              <Table.Th>{lang?.GLOBAL_COINS}</Table.Th>
              <Table.Th classNames={{ th: 'values' }}>5</Table.Th>
              <Table.Th classNames={{ th: 'values' }}>10</Table.Th>
              <Table.Th classNames={{ th: 'values' }}>0,01</Table.Th>
              <Table.Th classNames={{ th: 'values' }}>0,02</Table.Th>
              <Table.Th classNames={{ th: 'values' }}>0,05</Table.Th>
              <Table.Th classNames={{ th: 'values' }}>0,10</Table.Th>
              <Table.Th classNames={{ th: 'values' }}>0,20</Table.Th>
              <Table.Th classNames={{ th: 'values' }}>0,50</Table.Th>
              <Table.Th classNames={{ th: 'values' }}>1</Table.Th>
              <Table.Th classNames={{ th: 'values' }}>2</Table.Th>
            </Table.Tr>
          </Table.Thead>

          <Table.Tbody>
            {loading && (!message || searchTrigger) ? (
              <Table.Tr>
                <Table.Td colSpan={16}>
                  <Loader color="#016273" />
                </Table.Td>
              </Table.Tr>
            ) : (
              <>
                {noResults ? (
                  <Table.Tr>
                    <Table.Td colSpan={16} style={{ textAlign: 'center' }}>
                      {lang?.GLOBAL_NO_RESULTS}
                    </Table.Td>
                  </Table.Tr>
                ) : (
                  <>
                    {message?.equipments.map((equipment, index) => {
                      const matchingCurrency = currencies?.find(c => c.cid === equipment.cid);
                      const shopByEntid = shops?.shops.find(
                        s => s.entid === equipment.entid && s.sid === equipment.sid
                      );
                      if (!shopByEntid) {
                        return null;
                      }
                      return (
                        <Table.Tr key={index}>
                          <Table.Td>{shopByEntid.name}</Table.Td>
                          <Table.Td>
                            {equipment.eid + (equipment.description ? ` - ${equipment.description}` : '')}
                          </Table.Td>
                          <Table.Td>
                            {equipment.state === 1 ? <CheckIcon className="check" /> : <XIcon className="cross" />}
                          </Table.Td>
                          <Table.Td>
                            {' '}
                            {matchingCurrency ? formatCurrAmount(equipment.totalAmount || 0, matchingCurrency) : '-'}
                          </Table.Td>
                          <Table.Td>{equipment?.safe?.bills ?? 0}</Table.Td>
                          <Table.Td>{equipment?.safe?.coins ?? 0}</Table.Td>
                          <Table.Td
                            className={getValPosition(equipment?.recyclers?.bills, 0) > '0' ? 'cellWithValue' : ''}
                          >
                            {getValPosition(equipment?.recyclers?.bills, 0) ?? 0}
                          </Table.Td>
                          <Table.Td
                            className={getValPosition(equipment?.recyclers?.bills, 1) > '0' ? 'cellWithValue' : ''}
                          >
                            {getValPosition(equipment?.recyclers?.bills, 1) ?? 0}
                          </Table.Td>
                          {equipment?.recyclers?.coins
                            ? equipment?.recyclers?.coins.map((value, i) => (
                                <Table.Td key={i} className={value > 0 ? 'cellWithValue' : ''}>
                                  {getValPosition(equipment?.recyclers?.coins, i) ?? '-'}
                                </Table.Td>
                              ))
                            : Array.from({ length: 8 }).map((_, i) => <Table.Td key={i}>-</Table.Td>)}
                        </Table.Tr>
                      );
                    })}
                  </>
                )}
              </>
            )}
          </Table.Tbody>
        </Table>
      </Table.ScrollContainer>

      <Grid gutter="sm" classNames={{ root: 'iconography' }}>
        <GridCol>
          <CheckIcon className="CheckIcon" />
          <span>{lang?.EQUIPMENT_STATE_OK}</span>
        </GridCol>

        <GridCol>
          <XIcon className="XIcon" />
          <span>{lang?.EQUIPMENT_STATE_INACTIVE}</span>
        </GridCol>

        <GridCol>
          <TransportIcon className="transportIcon" />
          <span>{lang?.EQUIPMENT_STATE_PICKUP}</span>
        </GridCol>

        <GridCol>
          <BateryIcon className="plusIcon" />
          <span>{lang?.EQUIPMENT_STATE_CHARGE}</span>
        </GridCol>

        <GridCol>
          <ErrorIcon className="errorIcon" />
          <span>{lang?.EQUIPMENT_STATE_ERROR}</span>
        </GridCol>

        <GridCol>
          <Square className="squareMax" />
          <span>{lang?.EQUIPMENT_LIMIT_MAX}</span>
        </GridCol>

        <GridCol>
          <Square className="squareMed" />
          <span>{lang?.EQUIPMENT_LIMIT_PICKUP}</span>
        </GridCol>

        <GridCol>
          <Square className="squareMin" />
          <span>{lang?.EQUIPMENT_LIMIT_MIN}</span>
        </GridCol>
      </Grid>

      <Group>
        <PaginationComponent
          total={message?.equipments ? Math.ceil(message.equipments.length / limit) : 0}
          statusOperCode={statusOperCode}
          itemsPerPage={limit}
          onPageChange={changePage}
          onResultsChange={resultsSelect}
          initialPage={currentPage}
        />
      </Group>
    </Container>
  );
};

export default Dashboard;
