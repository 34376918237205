import moment from 'moment';
import { Select, Group, Button } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { useSelector } from 'react-redux';
import { IShop } from '../../../libraries/shop';
import { IEntity } from '../../../libraries/entity';
import { fetchApmPOSEquipmentList, fetchApmPOSEquipmentListResponse } from '../../../libraries/apm/index';
import { IEquipmentOptionsResponse, fetchEquipmentOptions } from '../../../libraries/equipment';
import { AppState } from '../../client-redux/reducers';
import { useEffect, useState } from 'react';
import { LoginState } from '../../client-redux/login/reducer';
import { Language } from '../../languages/languageHandler';
import * as Icon from '@tabler/icons-react';
import './styles.scss';

const HelpdeskFilterBar = ({
  onSearch,
  tableLoaded,
  filterByEntid,
  filterBySid,
  filterByEid,
  filterByPosId,
  updateBeginDate,
  updateEndDate,
  XLSReport,
  PDFReport
}: {
  onSearch: () => void;
  tableLoaded: () => void;
  filterByEntid?: (newEntId: number, callback?: () => void) => void;
  filterBySid?: (newSid: number, callback?: () => void) => void;
  filterByEid?: (newEid: number, callback?: () => void) => void;
  filterByPosId?: (newPosid: number, callback?: () => void) => void;
  updateBeginDate?: (newBeginDate: string) => void;
  updateEndDate?: (newEndDate: string) => void;
  XLSReport?: () => void;
  PDFReport?: () => void;
}) => {
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const user = useSelector<AppState, LoginState>(state => state.login);
  const shops = useSelector<AppState, IShop[]>(state => state.shops.shops);
  const entities = useSelector<AppState, IEntity[]>(state => state.entities.entities);
  const [, setLoading] = useState<boolean>(false);
  const [, setNoResults] = useState<boolean>(false);
  const [entid, setEntid] = useState<number>(user.user?.entid);
  const [sid, setSid] = useState<number>(0);
  const [posid, setPosid] = useState<number>(0);
  const [eid, setEid] = useState<number>(0);
  const [, setBeginDate] = useState<string>(moment().startOf('day').format('YYYY-MM-DDT00:00:00.000')) ?? null;
  const [, setEndDate] = useState<string>(moment().format('YYYY-MM-DDT23:59:59.000')) ?? null;
  const [posIdList, setPosIdList] = useState<fetchApmPOSEquipmentListResponse>();
  const [equipmentList, setEquipmentList] = useState<IEquipmentOptionsResponse>();
  const handleSearch = () => {
    tableLoaded();
    onSearch();
  };

  const controllerApmPOSEquipmentList = new AbortController();
  useEffect(() => {
    fetchApmPOSEquipmentList(entid, sid, controllerApmPOSEquipmentList)
      .then(response => {
        if (response.statusOper.code === 0 && response.poss) {
          setNoResults(false);
          setPosIdList(response);
          setLoading(false);
        } else {
          setNoResults(true);
          setLoading(false);
        }
      })
      .catch(error => {
        if (error.name === 'AbortError') {
        } else {
        }
      });
    return () => {
      controllerApmPOSEquipmentList.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posid]);

  const controllerEquipmentOptions = new AbortController();
  useEffect(() => {
    fetchEquipmentOptions(entid, sid, controllerEquipmentOptions)
      .then(response => {
        if (response.statusOper.code === 0 && response.list) {
          setNoResults(false);
          setEquipmentList(response);
          setLoading(false);
        } else {
          setNoResults(true);
          setLoading(false);
        }
      })
      .catch(error => {
        console.error('Error fetching equipment options:', error);
        if (error.name === 'AbortError') {
        } else {
        }
      });
    return () => {
      controllerEquipmentOptions.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eid]);

  /* Entity handle*/
  const handleEntitySelect = (value: number | 0) => {
    if (value !== null) {
      const selectedEntid = Number(value);
      filterByEntid?.(selectedEntid, () => {});
      setEntid(selectedEntid);
    }
  };

  /* Delegation handle */
  const handleNewTableBySid = (value: number | 0) => {
    if (value !== null) {
      const selectedSid = Number(value);
      filterBySid?.(selectedSid);
      setSid(selectedSid);
    }
  };

  /* Equipment handle*/
  const handleNewTableByEid = (value: number | 0) => {
    if (value !== null) {
      const selectedEid = Number(value);
      filterByEid?.(selectedEid!);
      setEid(selectedEid!);
    }
  };

  /* Pos handle*/
  const handleNewTableByPos = (value: number | 0) => {
    if (value !== null) {
      const selectedPosid = Number(value);
      filterByPosId?.(selectedPosid!);
      setPosid(selectedPosid!);
    }
  };

  /* Begin handle*/
  const handleBeginDateChange = (date: Date | null) => {
    if (date) {
      const newBeginDate = moment(date).toISOString();
      updateBeginDate?.(newBeginDate!);
      setBeginDate(newBeginDate!);
    }
  };

  /* End handle*/
  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      const newEndDate = moment(date).toISOString();
      updateEndDate?.(newEndDate!);
      setEndDate(newEndDate!);
    }
  };

  return (
    <Group classNames={{ root: 'filterbar' }}>
      {/* Entity */}
      <Select
        size="sm"
        withCheckIcon={true}
        label={lang?.GLOBAL_ENTITY}
        data={[
          { value: '0', label: lang?.GLOBAL_ALL },
          ...(entities ? entities.map(entity => ({ value: entity.entid.toString(), label: entity.name })) : [])
        ]}
        value={entid.toString()}
        onChange={value => handleEntitySelect(Number(value))}
      />

      {/* Delegation */}
        <Select
          withCheckIcon={true}
          label={lang?.USER_DELEGATION}
          data={[
            { value: '0', label: lang?.GLOBAL_ALL },
            ...(shops
              ? shops
                  .filter((elem: IShop) => elem.entid === entid)
                  .map(shop => ({ value: shop.sid.toString(), label: shop.name }))
              : [])
          ]}
          value={sid.toString()}
          onChange={value => handleNewTableBySid(Number(value))}
        />
   
      {/* EID */}
      {filterByEid && (
        <Select
          withCheckIcon={true}
          label={lang?.GLOBAL_EQUIPMENT}
          data={
            equipmentList
              ? [
                  { value: '0', label: lang?.GLOBAL_ALL },
                  ...equipmentList.list.map(item => ({
                    value: item.eid.toString(),
                    label: `${item.eid} ${item.label.replace(`${item.eid}`, '').trim()}`
                  }))
                ]
              : [{ value: '0', label: lang?.GLOBAL_ALL }]
          }
          value={eid === 0 ? '0' : eid.toString()}
          onChange={value => handleNewTableByEid(Number(value))}
        />
      )}

      {/* POS */}
      {filterByPosId && (
        <Select
          withCheckIcon={true}
          label="POS"
          data={[
            { value: '0', label: lang?.GLOBAL_ALL },
            ...(posIdList
              ? posIdList.poss.map(pos => ({
                  value: pos.posid.toString(),
                  label: `${pos.posid} - ${pos.description}`
                }))
              : [])
          ]}
          value={posid.toString()}
          onChange={value => handleNewTableByPos(Number(value))}
        />
      )}

      {/* Begin */}
      {updateBeginDate && (
        <DateTimePicker
          clearable
          withSeconds
          label={lang?.EVENT_BEGIN_DATE_PLACEHOLDER}
          defaultValue={moment().startOf('day').toDate()}
          onChange={date => handleBeginDateChange(date)}
          placeholder={lang?.GLOBAL_PICK_A_DATE}
        />
      )}

      {/* End */}
      {updateEndDate && (
        <DateTimePicker
          clearable
          withSeconds
          label={lang?.EVENT_END_DATE_PLACEHOLDER}
          defaultValue={moment().endOf('day').toDate()}
          onChange={date => handleEndDateChange(date)}
          placeholder={lang?.GLOBAL_PICK_A_DATE}
        />
      )}

      {/* Search */}
      <Button radius="md" className="searchButton" onClick={handleSearch}>
        {lang?.GLOBAL_SEARCH}
      </Button>

      {/* Excel */}
      {XLSReport && (
        <Button radius="md" className="XLSButton" onClick={XLSReport}>
          <Icon.IconFileTypeXls stroke={2} />
          <span>XLS</span>
        </Button>
      )}

      {/* PDF */}
      {PDFReport && (
        <Button radius="md" className="PDFButton" onClick={PDFReport}>
          <Icon.IconFileTypePdf stroke={2} />
          <span>PDF</span>
        </Button>
      )}
    </Group>
  );
};

export default HelpdeskFilterBar;
