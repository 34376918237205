import { ActionTypes } from './actionTypes';
import { AppDispatch } from '../store';
import { createAction } from '../actions';
import { LoginResponse } from '../../../libraries/login';

export const login = (body: LoginResponse) => (dispatch: AppDispatch) => {
  // dispatch(createAction(ActionTypes.LOGGING_IN));
  dispatch(createAction(ActionTypes.LOGGED_IN, body));
  localStorage.setItem('authState', JSON.stringify(body));
};

export const clearLogginIn = () => (dispatch: AppDispatch) => {
  dispatch(createAction(ActionTypes.LOGGED_IN_CLEAR));
};

export const logout = () => (dispatch: AppDispatch) => {
  dispatch(createAction(ActionTypes.LOGOUT));
};
