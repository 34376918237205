export const zps_ea_war = process.env.REACT_APP_ZPS_EA_WAR;
console.log(`zps_ea_war: ${zps_ea_war}`);
export const zps_ms_ea_war = process.env.REACT_APP_ZPS_MS_EA_WAR;
console.log(`zps_ms_ea_war: ${zps_ms_ea_war}`);

export interface StatusOper {
  code: number;
  message: string;
}

export interface ApiResponse {
  statusOper: StatusOper;
}

export interface Page<T> {
  content: T[];
  pageable: {
    offset: number;
    pageSize: number;
    pageNumber: number;
  };
  last: boolean;
  totalPages: number;
  totalElements: number;
  first: boolean;
  numberOfElements: number;
}
