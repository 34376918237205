import { ApiResponse, zps_ea_war } from '../zps';
import axios from 'axios';

export interface IRegionResponse extends ApiResponse {
  regions: string[];
}

export const fetchRegions = async () => {
  const response = await axios.get(`${zps_ea_war}/region`);
  return response.data;
};
