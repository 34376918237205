import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DateTimePicker } from '@mantine/dates';
import { Group, Button, Container, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../client-redux/reducers';
import { Language } from '../../../../languages/languageHandler';
import { LoginState } from '../../../../client-redux/login/reducer';
import {
  EquipmentRegistrationRequest,
  IHelpdeskEquipmentResponse,
  fetchHelpdeskEquipInfo,
  updateHelpdeskEquipment
} from '../../../../../libraries/helpdesk';
import ChooseEntity from './choose-entity-modal';
import CustomModal from '../../../../components/custom-modal';
import * as Icon from '@tabler/icons-react';
import './styles.scss';

enum SELECTED_FIELD {
  ENTITY,
  MAINT_1,
  MAINT_2
}

const RegisterEquipmentHelpdesk: React.FC = () => {
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const loginInfo = useSelector<AppState, LoginState>(state => state.login);
  const controller = new AbortController();
  const { eid } = useParams<string>();
  const [message, setMessage] = useState<IHelpdeskEquipmentResponse>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedField, setSelectedField] = useState<SELECTED_FIELD>(SELECTED_FIELD.ENTITY);

  useEffect(() => {
    getEquipmentList();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEquipmentList = () => {
    if (!loginInfo.token) {
      return;
    }
    fetchHelpdeskEquipInfo(Number(eid), controller)
      .then(response => {
        if (response.statusOper.code === 0) {
          setMessage(response);
        } else {
        }
      })
      .catch(error => {
        if (error.name === 'AbortError') {
        } else {
        }
      });
  };

  const defaultExpDate = () => {
    const licenseExp = message?.list[0].licenceExp;
    if (licenseExp) {
      return licenseExp;
    } else {
      const newDate = new Date();
      newDate.setFullYear(newDate.getFullYear() + 10);
      return newDate.toISOString();
    }
  };

  const form = useForm({
    initialValues: {
      eid: message?.list[0].eid,
      entid: message?.list[0].entity?.entid,
      entidName: message?.list[0].entity?.name,
      maintid: message?.list[0].maintEntity?.entid,
      maintidName: message?.list[0].maintEntity?.name,
      maintid2: message?.list[0].maintEntity2?.entid,
      maintid2Name: message?.list[0].maintEntity2?.name,
      shop: {
        sid: message?.list[0].sid || 0,
        name: message?.list[0].sidDescription || ''
      },
      dateTimeExp: message?.list[0].licenceExp ? new Date(message.list[0].licenceExp).toISOString() : ''
      //posid: message?.list[0].posId,
    }
  });

  useEffect(() => {
    if (message) {
      console.log('Received message object:', message);

      form.setValues(prevValues => ({
        ...prevValues,
        entid: prevValues.entid || message?.list[0].entity?.entid || 0,
        entidName: prevValues.entidName || message?.list[0].entity?.name || '',
        maintid: prevValues.maintid || message?.list[0].maintEntity?.entid || 0,
        maintidName: prevValues.maintidName || message?.list[0].maintEntity?.name || '',
        maintid2: prevValues.maintid2 || message?.list[0].maintEntity2?.entid || 0,
        maintid2Name: prevValues.maintid2Name || message?.list[0].maintEntity2?.name || '',
        shop: {
          sid: prevValues.shop?.sid || message?.list[0].sid || 0,
          name: prevValues.shop?.name || message?.list[0].sidDescription || ''
        },
        dateTimeExp: defaultExpDate()
        //posid:prevValues.shop?.posid || message?.list[0].posid,
      }));
      console.log('Updated form values after message:', form.values); // Log updated form values
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const submitForm = async (values: any) => {
    console.log('Form values at submission:', values);

    const request: EquipmentRegistrationRequest = {
      eid: Number(eid),
      entid: values.entid,
      maintid: values.maintid,
      maintid2: values.maintid2,
      shop: {
        sid: values.shop.sid,
        name: values.shop.name
      },
      dateTimeExp: values.dateTimeExp
    };

    console.log('Request payload being sent to API:', request);

    const formController = new AbortController();
    try {
      const response = await updateHelpdeskEquipment(request, formController);

      if (response.statusOper && response.statusOper.code !== 0) {
        const errorMessage = response.statusOper.message || 'An unknown error occurred.';
        setErrorMessage(
          `${lang?.EDIT_EQUIPMENTS_ALERT_UPDATED_ERROR} (Code: ${response.statusOper.code}) ${errorMessage}`
        );
        console.log('Error in API response:', response);
        setShowErrorDialog(true);
      } else {
        console.log('Successful API response:', response);
        setSuccessMessage(lang?.EDIT_EQUIPMENTS_ALERT_UPDATED_SUCCESS || 'Equipment updated successfully');
        setShowSuccessDialog(true);
        setTimeout(() => {
          setShowSuccessDialog(false);
        }, 1000);
      }
    } catch (error) {
      console.error('Error during the API call:', error);
      setErrorMessage(`${lang?.EDIT_EQUIPMENTS_ALERT_UPDATED_ERROR} (Status: ${errorMessage}`);
      setShowErrorDialog(true);
    }
  };

  const chooseEntid = (entid: number, name: string) => {
    switch (selectedField) {
      case SELECTED_FIELD.ENTITY:
        form.setValues({ entid: entid, entidName: name });
        return;
      case SELECTED_FIELD.MAINT_1:
        form.setValues({ maintid: entid, maintidName: name });
        return;
      case SELECTED_FIELD.MAINT_2:
        form.setValues({ maintid2: entid, maintid2Name: name });
        return;
    }
  };

  const toggleChooseEntityModal = () => {
    setOpenModal(!openModal);
    setSelectedField(SELECTED_FIELD.ENTITY);
  };

  const toggleChooseMaintModal = () => {
    setOpenModal(!openModal);
    setSelectedField(SELECTED_FIELD.MAINT_1);
  };

  const toggleChooseAgentModal = () => {
    setOpenModal(!openModal);
    setSelectedField(SELECTED_FIELD.MAINT_2);
  };

  const clearEntid = () => {
    form.setValues({ ...form.values, entid: 0, entidName: '' });
  };

  const clearMaintid = () => {
    form.setValues({ ...form.values, maintid: 0, maintidName: '' });
  };

  const clearMaintid2 = () => {
    form.setValues({ ...form.values, maintid2: 0, maintid2Name: '' });
  };

  const handleDateChange = (date: Date | null) => {
    const dateString = date ? date.toISOString() : '';
    form.setFieldValue('dateTimeExp', dateString);
  };

  return (
    <Container fluid classNames={{ root: 'register-equip-helpdesk' }}>
      {lang && (
        <ChooseEntity
          lang={lang}
          isOpen={openModal}
          hideBtn={() => toggleChooseEntityModal()}
          chooseEntid={chooseEntid}
        />
      )}
        <Group>
      <form
        onSubmit={form.onSubmit(values => {
          submitForm(values);
        })}
      >
        <Group>
          {message && (
            <h3>
              {lang?.EQUIPMENT_ID}: {message.list[0].eid} SN: {message.list[0].sn} {lang?.GLOBAL_TYPE}:{' '}
              {message.list[0].type} ENTID: {message.list[0].entity?.entid}
            </h3>
          )}
        </Group>

        <Group classNames={{ root: 'entities' }}>
          {/* Entity */}
          <Group>
            <TextInput
              label={lang?.USER_ENTITY}
              type="text"
              placeholder={`${lang?.GLOBAL_ENTITY}`}
              {...form.getInputProps('entid')}
              value={form.values.entid ? `${form.values.entid} - ${form.values.entidName}` : ''}
            ></TextInput>
            <Icon.IconSearch stroke={3} onClick={toggleChooseEntityModal} />
            <Icon.IconX stroke={3} onClick={clearEntid} />
          </Group>

          {/* Maintenance company */}
          <Group>
            <TextInput
              label={lang?.HELPDESK_MAINTENANCE_COMPANY}
              type="text"
              placeholder={`${lang?.HELPDESK_MAINTENANCE_COMPANY}`}
              {...form.getInputProps('maintid')}
              value={form.values.maintid ? `${form.values.maintid} - ${form.values.maintidName}` : ''}
            ></TextInput>
            <Icon.IconSearch stroke={3} onClick={toggleChooseMaintModal} />
            <Icon.IconX stroke={3} onClick={clearMaintid} />
          </Group>

          {/* Agent */}
          <Group>
            <TextInput
              label={lang?.HELPDESK_MAINTENANCE_AGENCY}
              type="text"
              placeholder={`${lang?.HELPDESK_MAINTENANCE_AGENCY}`}
              {...form.getInputProps('maintid2')}
              value={form.values.maintid2 ? `${form.values.maintid2} - ${form.values.maintid2Name}` : ''}
            ></TextInput>
            <Icon.IconSearch stroke={3} onClick={toggleChooseAgentModal} />
            <Icon.IconX stroke={3} onClick={clearMaintid2} />
          </Group>
        </Group>

        {/* Delegation */}
        <Group classNames={{ root: 'shop' }}>
          {/* ID */}
          <TextInput label={'ID'} placeholder="ID" {...form.getInputProps('shop.sid')} />

          {/* Name */}
          <TextInput
            label={lang?.GLOBAL_NAME}
            placeholder={`${lang?.GLOBAL_NAME}`}
            {...form.getInputProps('shop.name')}
          />
        </Group>

        {/* Licence */}
        <Group classNames={{ root: 'licence' }}>
          <Group>
            <DateTimePicker
              label={lang?.GLOBAL_LICENSE}
              value={form.values.dateTimeExp ? new Date(form.values.dateTimeExp) : null}
              onChange={handleDateChange}
              valueFormat="DD/MM/YYYY HH:mm"
            />

            {/* Posid */}
            <TextInput label={'POSID'} placeholder={'Posid'} {...form.getInputProps('posid')}></TextInput>
          </Group>
        </Group>

        <Group>
        <Button type="submit">{lang?.GLOBAL_SAVE_BUTTON}</Button>
        </Group>
        
      </form>
      </Group>
      <CustomModal
        opened={showSuccessDialog}
        onClose={() => setShowSuccessDialog(false)}
        message={successMessage}
        backgroundColor="#3BDF70"
        textColor='black'
      />

      <CustomModal
        opened={showErrorDialog}
        onClose={() => setShowErrorDialog(false)}
        message={errorMessage}
        backgroundColor="#E3353F"
        textColor='white'
      />
    </Container>
  );
};

export default RegisterEquipmentHelpdesk;
