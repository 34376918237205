import { useState } from 'react';
import { Button, Grid, GridCol, Group, PasswordInput } from '@mantine/core';
import { IUser, updateUser } from '../../../libraries/users';
import { Language } from '../../languages/languageHandler';
import ModalComponent from '../../components/modal';
import CustomModal from '../../components/custom-modal';
import './styles.scss';
import { useMutation } from 'react-query';

interface ModalPasswordProps {
  toggleModal: () => void;
  userData: IUser;
  openModal: boolean;
  lang: Language;
  onSubmit: (data?: any) => void;
}

const UpdateUserConfirmation: React.FC<ModalPasswordProps> = ({
  userData,
  openModal,
  toggleModal,
  lang,
  onSubmit
}: ModalPasswordProps) => {
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [showSuccessDialog, setShowSuccessDialog] = useState<boolean>(false);
  const [showErrorDialog, setShowErrorDialog] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const updater = useMutation(updateUser, {
    onSuccess: data => {
      const { statusOper } = data;
      if (statusOper.code === 0) {
        setShowSuccessDialog(true);
        setSuccessMessage(lang?.EDIT_USER_ALERT_UPDATE_SUCCESS);
      } else {
        setShowErrorDialog(true);
        setErrorMessage(lang?.EDIT_USER_ALERT_UPDATE_ERROR + '\n' + statusOper.message);
      }
    },
    onError: error => {
      setShowErrorDialog(true);
      setErrorMessage(lang?.EDIT_USER_ALERT_UPDATE_ERROR + error);
    }
  });

  const handleSubmit = async () => {
    try {
      await updater.mutateAsync({
        currentPassword: currentPassword,
        deletePin: false,
        user: userData
      });
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  return (
    <>
      <ModalComponent
        show={openModal}
        handleClick={toggleModal}
        title={lang?.USER_CONFIRM_PASSWORD}
        size={'sm'}
        content={
          <>
            <Group classNames={{ root: 'UpdateConfirmation' }}>
              <Grid>
                <GridCol span={{ base: 12, md: 6, lg: 12 }}>
                  <PasswordInput
                    label={lang?.USER_CONFIRM_CURRENT_PASSWORD}
                    value={currentPassword}
                    onChange={event => setCurrentPassword(event.target.value)}
                    type="password"
                  />
                </GridCol>
              </Grid>

              <Group>
                <Button color="#016273" radius="md" onClick={handleSubmit}>
                  {lang?.GLOBAL_SAVE_BUTTON}
                </Button>

                <Button color="#016273" radius="md" onClick={toggleModal}>
                  {lang?.GLOBAL_CANCEL}
                </Button>
              </Group>
            </Group>
          </>
        }
        onSubmit={onSubmit}
      />

      <CustomModal
        opened={showSuccessDialog}
        onClose={() => setShowSuccessDialog(false)}
        message={successMessage}
        backgroundColor="#3BDF70"
        textColor='black'
      />

      <CustomModal
        opened={showErrorDialog}
        onClose={() => setShowErrorDialog(false)}
        message={errorMessage}
        backgroundColor="#E3353F"
        textColor='white'
      />
    </>
  );
};

export default UpdateUserConfirmation;
