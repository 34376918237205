import { Action } from 'redux';

export interface AppAction<T = string, P = any> extends Action<T> {
  payload?: P;
}

export const createAction = <T = string, P = any>(type: T, payload?: P): AppAction<T, P> => {
  return {
    type,
    payload
  };
};
