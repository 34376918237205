import {
  ActionIcon,
  AppShell,
  AppShellHeader,
  Burger,
  Container,
  Group,
  Image,
  UnstyledButton,
  useMantineColorScheme
} from '@mantine/core';
import { useLocalStorage, useDisclosure } from '@mantine/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../client-redux/reducers';
import { Language } from '../../languages/languageHandler';
import { Link, useNavigate } from 'react-router-dom';
import { logout } from '../../client-redux/login';
import { clearSidebar, updateActiveTab } from '../../client-redux/sidebar';
import { useEffect, useMemo, useState } from 'react';
import logoBg from '../../../assets/img/zarph_white_horiz.png';
import userLogo from '../../../assets/img/user-profile.svg';
import exitLogo from '../../../assets/img/exit.svg';
import { ViewMapProps } from '../../utils/view-map-props';
import * as Icon from '@tabler/icons-react';
import './styles.scss';

export const Sidebar = () => {
  const views = useSelector<AppState, ViewMapProps[]>(state => state.sidebar.views);
  const lang = useSelector<AppState, Language>(state => state.lang.lang);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure(false);
  const [isNavbarCollapsed] = useLocalStorage<boolean>({
    key: 'collapsed-navbar',
    defaultValue: true
  });

  const [desktopOpened, setDesktopOpened] = useState(false);
  const { colorScheme, setColorScheme } = useMantineColorScheme({
    keepTransitions: true
  });

  const toggleColorScheme = () => {
    setColorScheme(colorScheme === 'dark' ? 'light' : 'dark');
  };
  const dark = colorScheme === 'dark';

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', colorScheme);
  }, [colorScheme]);

  const logoutOper = () => {
    dispatch(logout());
    dispatch(clearSidebar());
    navigate('/login');
  };

  const handleOptionClick = () => {
    setDesktopOpened(false);
  };

  const renderProfileSection = () => (
    <Group classNames={{ root: 'profile-options' }}>
      <UnstyledButton
        component={Link}
        to="/profile"
        id="profile-tab"
        onClick={() => {
          dispatch(updateActiveTab(lang.USER_PROFILE));
          handleOptionClick();
        }}
      >
        <Group>
          <Image src={userLogo} />
          <span>{lang.USER_PROFILE}</span>
        </Group>
      </UnstyledButton>

      <UnstyledButton onClick={logoutOper} component={Link} to="/login">
        <Group>
          <Image src={exitLogo} />
          <span>{lang.GLOBAL_LOGOUT}</span>
        </Group>
      </UnstyledButton>
      <span className="react-version">{process.env.REACT_APP_VERSION}</span>
    </Group>
  );

  const renderViews = useMemo(() => {
    return views.map((element, index) => (
      <UnstyledButton
        component={Link}
        to={element.link}
        onClick={() => {
          dispatch(updateActiveTab(element.label));
          handleOptionClick();
        }}
        id="sidebar-tab"
        key={index}
      >
        <Group>
          <Image src={element.icon} />
          {element.label && <span>{lang[element.label]}</span>}
        </Group>
      </UnstyledButton>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [views, dispatch, lang, isNavbarCollapsed]);

  const collapsedNavbar = (
    <Container className="collapsed-navbar">
      <Group classNames={{ root: 'options' }}>{renderViews}</Group>
      {renderProfileSection()}
    </Container>
  );

  return (
    <AppShell
      navbar={{
        width: 300,
        breakpoint: 'sm',
        collapsed: { mobile: !mobileOpened, desktop: !desktopOpened }
      }}
      padding="md"
      transitionDuration={200}
      transitionTimingFunction="ease"
    >
      <AppShellHeader>
        <Burger opened={mobileOpened} onClick={toggleMobile} hiddenFrom="sm" />
        <Burger opened={desktopOpened} onClick={() => setDesktopOpened(prevState => !prevState)} visibleFrom="sm" />
        <Group>
          <Image src={logoBg} />
        </Group>

        <ActionIcon
          variant="filled"
          color={dark ? 'var(--mantine-color-zarph-3)' : 'var(--mantine-color-zarph-4)'}
          onClick={toggleColorScheme}
        >
          {dark ? <Icon.IconSun stroke={2} /> : <Icon.IconMoon stroke={2} />}
        </ActionIcon>
      </AppShellHeader>

      <AppShell.Navbar>
        <Group classNames={{ root: 'options' }}>{renderViews}</Group>
        {renderProfileSection()}
      </AppShell.Navbar>

      {!desktopOpened && collapsedNavbar}
    </AppShell>
  );
};
